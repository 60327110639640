var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mytable", {
    staticClass: "debug",
    attrs: {
      title: "Debug",
      columns: _vm.data.columns,
      store: _vm.data.rows,
      error: _vm.data.error,
      loading: _vm.data.loading,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }