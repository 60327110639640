var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("keep-alive", [
    _c(
      "div",
      { staticClass: "pending-projects" },
      [
        _c("mytable", {
          attrs: {
            title: "Pending",
            columns: _vm.data.columns,
            store: _vm.data.rows,
            labels: _vm.data.labels,
            loading: _vm.data.loading,
            performance: _vm.data.performance,
            error: _vm.data.error,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }