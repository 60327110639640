<!-- src/components/Login.vue -->
<template>
  <div class="col-md-6 .col-md-offset-3">
    <div>
      <div v-if="AuthService.authenticated">
        <img class="user-profile-picture" v-bind:src="'data:image/jpeg;base64,' + AuthService.getUser().data.jpegPhoto" style="float:left;margin-right : 10px">
        <div style="display:inline-block;float:left">
          <h3 style="margin-top:0">{{ AuthService.getUser().data.displayName[0] }}</h3>
          <p>{{ AuthService.getUser().data.mail }}</p>
          <button class="rd-small-button user_logout" @click="AuthService.logout()">Sign off</button>
        </div>
      </div>

      <div v-if="!AuthService.authenticated">
        <h2>Please login with your NETID</h2>
        <div v-if="error" class="alert alert-danger">
          <p>{{ error }}</p>
        </div>

        <div v-if="!AuthService.isAuthenticating">
          <div v-if="!AuthService.authenticated">
            <a class="gm-button netid_login" @click="AuthService.login()">Login With Your NetID</a>
          </div>
        </div>
        <div v-if="AuthService.isAuthenticating">
          <p>Authenticating....</p>
          <loading />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '../auth/AuthService.js'
import loading from './viz/loading.vue'

export default {
  components: {
    loading: loading
  },
  data () {
    return {
      AuthService: AuthService
    }
  },
  mounted () {
    AuthService.authCheck()
  }
}

</script>
<style lang="scss">
.user-profile-picture{
  width : 100px;
  height : auto;
  border-radius : 100%;
}
</style>
