import { render, staticRenderFns } from "./Protocols.vue?vue&type=template&id=50372282"
import script from "./Protocols.vue?vue&type=script&lang=js"
export * from "./Protocols.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/ADO-ResearchPortal/ADO-ResearchPortal/files-dash/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50372282')) {
      api.createRecord('50372282', component.options)
    } else {
      api.reload('50372282', component.options)
    }
    module.hot.accept("./Protocols.vue?vue&type=template&id=50372282", function () {
      api.rerender('50372282', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/projects/protocols/Protocols.vue"
export default component.exports