<template>
  <div id="iframe_holder" />
</template>

<script>
import pym from 'pym.js'
export default {
  data () {
    return {
    }
  },
  mounted () {

    var url = 'https://sites.northwestern.edu/researchportalblog/policy?shaka'
    // eslint-disable-next-line no-unused-vars
    var pymParent = new pym.Parent('iframe_holder', url, {})
  }
}
</script>
