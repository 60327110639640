import Investigators from './components/Investigators.vue'
import Help from './components/Help.vue'
import Policy from './components/Policy.vue'
import Skrollrhome from './components/SkrollrHome.vue'
import Login from './components/Login.vue'
import Profile from './components/Profile.vue'
import Debug from './components/Debug.vue'
import Projects from './components/projects/Projects.vue'
import Sponsored from './components/projects/sponsored/'
import NonSponsored from './components/projects/non-sponsored/'
import Pending from './components/projects/pending/'
import Protocols from './components/projects/protocols'
import PIheader from './components/people/PIheader.vue'
import PeopleListing from './components/people/PeopleListing.vue'
import PeopleSalary from './components/people/PeopleSalary.vue'
import Error from './Error.vue'
import AccessDenied from './components/AccessDenied.vue'

const routes = [
  { path: '/home', component: Skrollrhome, name: 'home'},
  { path: '/skrollrhome', component: Skrollrhome, auth: true, name: 'Skrollrhome'},
  { path: '/debug', component: Debug, auth: true, name: 'Debug'},
  { path: '/error', component: Error, name: 'Error'},
  { path: '/access_denied', component: AccessDenied, name: 'AccessDenied'},
  { path: '/profile', component: Profile, auth: true, name: 'Profile'},
  { path: '/investigators', component: Investigators, auth: true, name: 'Investigators'},
  {
    path: '/people/:netid',
    component: PIheader,
    auth: true,
    name: 'PIheader',
    children: [
      {
        path: '/list',
        component: PeopleListing,
        auth: true,
        name: 'PeopleListing',
        children: [
          { path: '/salary/:eid', component: PeopleSalary, auth: true, name: 'PeopleSalary'}
        ]
      }
    ]
  },
  { path: '/protocol/:netid/irb/:pid', component: Protocols.IRBDetail, auth: true, name: 'IRBDetail'},
  { path: '/protocol/:netid/iacuc/:pid', component: Protocols.IACUCDetail, auth: true, name: 'IACUCDetail'},
  {
    path: '/projects/:netid',
    component: Projects,
    auth: true,
    name: 'Projects',
    children: [
      { path: 'protocols', component: Protocols.Protocols, auth: true, name: 'Protocols_Protocols'},
      {
        path: 'sponsored',
        component: Sponsored.Projects,
        auth: true,
        name: 'Sponsored_Projects',
        children: [
          { path: 'inception-to-date', component: Sponsored.InceptionToDate, auth: true, name: 'Sponsored_InceptionToDate'}
        ]
      },
      { path: 'non-sponsored', component: NonSponsored.Projects, auth: true, name: 'NonSponsored_Projects'},
      { path: 'pending', component: Pending.Projects, auth: true, name: 'Pending_Projects'}
    ]
  },
  {
    path: '/non-sponsored/:cs/:netid',
    component: NonSponsored.NonSponsored,
    auth: true,
    name: 'NonSponsored_NonSponsored',
    children: [
      {
        path: 'overview',
        component: NonSponsored.Overview,
        auth: true,
        children: [
          { path: 'fiscal-year-to-date', component: NonSponsored.FiscalYearToDate, auth: true, name: 'NonSponsored_FiscalYearToDate'}
        ]
      },
      { path: 'people', component: Sponsored.People, auth: true, name: 'Sponsored_People'}
    ]
  },
  {
    path: '/project/:id/:netid',
    component: Sponsored.Sponsored,
    auth: true,
    name: 'Sponsored_Sponsored',
    children: [
      {
        path: 'overview',
        component: Sponsored.Overview,
        auth: true,
        name: 'Sponsored_Overview',
        children: [
          { path: 'inception-to-date', component: Sponsored.InceptionToDate, auth: true, name: 'Project_Sponsored_InceptionToDate'},
          { path: 'expenses-by-month', component: Sponsored.ExpensesByMonth, auth: true, name: 'Sponsored_ExpensesByMonth'}
        ]
      },
      { path: 'people', component: Sponsored.People, auth: true, name: 'Project_Sponsored_People'},
      { path: 'cost-share', component: Sponsored.CostShare, auth: true, name: 'Sponsored_CostShare'},
      { path: 'payments', component: Sponsored.Payments, auth: true, name: 'Sponsored_Payments'},
      {
        path: 'subcontracts-actual',
        component: Sponsored.Subcontractor,
        auth: true,
        name: 'Sponsored_Subcontractor',
        children: [
          {
            path: ':vendorid',
            component: Sponsored.SubcontractorDetail,
            auth: true,
            name: 'Sponsored_SubcontractorDetail',
            children: [
              { path: ':po', component: Sponsored.SubcontractorPO, auth: true, name: 'Sponsored_SubcontractorPO'}
            ]
          }
        ]
      },
      { path: 'subcontracts-proposal', component: Sponsored.SubcontractsProposal, auth: true, name: 'Sponsored_SubcontractsProposal'}
    ]
  },
  { path: '/login', component: Login, name: 'Login'},
  { path: '/help', component: Help, auth: false, name: 'Help'},
  { path: '/policy', component: Policy, auth: false, name: 'Policy'},
  { path: '*', redirect: '/home'}
]

export default routes
