var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          ref: "backBtn",
          staticClass: "dir-btn prev back-to-portfolio",
          on: {
            click: function ($event) {
              return _vm.backToPortfolio()
            },
          },
        },
        [_vm._v("Back to " + _vm._s(_vm.$route.params.netid) + "'s Portfolio")]
      ),
      _c("div", { staticClass: "metadata-block" }, [
        _c("h1", [_vm._v(_vm._s(_vm.data.rows["ProjectDescription"]))]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.data.loading,
                expression: "data.loading",
              },
            ],
          },
          [
            _c(
              "div",
              {
                staticClass: "loader",
                staticStyle: {
                  position: "relative",
                  width: "100%",
                  height: "40px",
                },
              },
              [_c("loading")],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "metadata-flex-wrap" }, [
          _c("div", { staticClass: "non-sponsored-project-metadata" }, [
            _c("ul", [
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "key",
                    attrs: { title: _vm.formatTitle("ProjectDescription") },
                  },
                  [_vm._v(_vm._s(_vm.formatLabel("ProjectDescription")))]
                ),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.data.rows["ProjectDescription"])),
                ]),
              ]),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "key",
                    attrs: { title: _vm.formatTitle("Chartstring") },
                  },
                  [_vm._v("Chart String")]
                ),
                _c("div", { staticClass: "value" }, [_vm._v(_vm._s(_vm.cs))]),
              ]),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "key",
                    attrs: { title: _vm.formatTitle("FYStartDate") },
                  },
                  [_vm._v(_vm._s(_vm.formatLabel("FYStartDate")))]
                ),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(_vm.formatValue("FYStartDate", _vm.data.rows)) +
                      " to " +
                      _vm._s(_vm.formatValue("FYEndDate", _vm.data.rows))
                  ),
                ]),
              ]),
              _vm._m(0),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "key",
                    attrs: { title: _vm.formatTitle("FYTDActualRevenue") },
                  },
                  [_vm._v(_vm._s(_vm.formatLabel("FYTDActualRevenue")))]
                ),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    "$ " +
                      _vm._s(
                        _vm.formatValue("FYTDActualRevenue", _vm.data.rows)
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "key",
                    attrs: { title: _vm.formatTitle("CurrentRevenueBudget") },
                  },
                  [_vm._v(_vm._s(_vm.formatLabel("CurrentRevenueBudget")))]
                ),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    "$ " +
                      _vm._s(
                        _vm.formatValue("CurrentRevenueBudget", _vm.data.rows)
                      )
                  ),
                ]),
              ]),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "key",
                    attrs: { title: _vm.formatTitle("BudgetCarryforward") },
                  },
                  [_vm._v(_vm._s(_vm.formatLabel("BudgetCarryforward")))]
                ),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    "$ " +
                      _vm._s(
                        _vm.formatValue("BudgetCarryforward", _vm.data.rows)
                      )
                  ),
                ]),
              ]),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "key",
                    attrs: { title: _vm.formatTitle("TotalExpenseBudget") },
                  },
                  [_vm._v(_vm._s(_vm.formatLabel("TotalExpenseBudget")))]
                ),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    "$ " +
                      _vm._s(
                        _vm.formatValue("TotalExpenseBudget", _vm.data.rows)
                      )
                  ),
                ]),
              ]),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "key",
                    attrs: {
                      title: _vm.formatTitle("FYTDActualsAndEncExpense"),
                    },
                  },
                  [_vm._v(_vm._s(_vm.formatLabel("FYTDActualsAndEncExpense")))]
                ),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    "$ " +
                      _vm._s(
                        _vm.formatValue(
                          "FYTDActualsAndEncExpense",
                          _vm.data.rows
                        )
                      )
                  ),
                ]),
              ]),
              _vm._m(1),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "key",
                    attrs: { title: _vm.formatTitle("LastUpdateTime") },
                  },
                  [_vm._v("Last Update Time")]
                ),
                _c("div", { staticClass: "value" }, [_vm._v(_vm._s(_vm.ts))]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "download-actions" }, [
            _c(
              "button",
              {
                staticClass: "rd-download excel",
                class: { working: _vm.downloading.xlsx },
                on: {
                  click: function ($event) {
                    return _vm.exportNonSponsoredProject("xlsx")
                  },
                },
              },
              [_vm._v("DOWNLOAD PROJECT DATA")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "tabs" }, [
          _c(
            "ul",
            { staticClass: "nav nav-pills" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "NonSponsored_FiscalYearToDate",
                      params: {
                        netid: this.$route.params.netid,
                        cs: this.$route.params.cs,
                      },
                    },
                    tag: "li",
                    role: "presentation",
                  },
                },
                [
                  _c("a", { staticClass: "finoverview tab" }, [
                    _vm._v("Financial Overview"),
                  ]),
                ]
              ),
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "Sponsored_People",
                      params: {
                        netid: this.$route.params.netid,
                        cs: this.$route.params.cs,
                      },
                    },
                    tag: "li",
                    role: "presentation",
                  },
                },
                [
                  _c("a", { staticClass: "people tab" }, [
                    _vm._v("Project Team"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("br")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("br")])
  },
]
render._withStripped = true

export { render, staticRenderFns }