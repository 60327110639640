import AuthService from '../auth/AuthService.js'
import {QueryBuilder} from '../utils/'

export default {
  users: [],
  setUsers (_users) {
    this.users = _users
    localStorage.setItem('users', JSON.stringify(_users))
    this.userCount = (_users || []).length
  },
  getUsers () {
    if (this.users.length === 0) {
      var users_json = localStorage.getItem('users') || '[]'
      var users = JSON.parse(users_json)
      if (users.length > 0) {
        this.users = users
      }
    }
    return this.users || []
  },
  clear () {
    this.users = []
  },
  getDemoUser () {
    return {
      EmployeeFirstName: 'Willie',
      EmployeeLastName: 'Wildcat',
      EmployeeJobTitle: 'Mascot',
      EmployeeHRDeptName: 'Athletics',
      EmployeeID: '1234567',
      PrimaryFirstName: 'Willie',
      PrimaryLastName: 'Wildcat',
      CampusEmail: 'williewildcat@northwestern.edu',
      ResearcherNetID: 'wildcat',
      DeptartmentDescription: 'Athletics',
      profile_style: 'url(https://researchportal-photos-prod.s3.us-east-2.amazonaws.com/photos/source/wildcat.jpg) 100% 100%'
    }
  },
  getDemoProfile (netid) {
    return new Promise(function (resolve, reject) {
      var userdata = {
        // picture: "http://www.fnal.gov/pub/today/universities/images/northwestern-university-mascot-s.jpg",
        picture: 'https://researchportal-photos-prod.s3.us-east-2.amazonaws.com/photos/source/wildcat.jpg',
        displayName: ['Willie Wildcat'],
        mail: 'wildcat@northwestern.edu',
        position: 'The Wildcat'
      }
      resolve(userdata)
    })
  },
  getProfile (netid) {
    // BEGIN - demo user
    if (netid === 'wildcat') {
      return this.getDemoProfile()
    }
    // END - demo user
    var user = AuthService.getUser()
    if (!user) { return null }

    var BASE_URL = process.env.VUE_APP_API_URL + '/profile'
    var data = {'pi_netid': netid}
    var params = QueryBuilder.build(data)
    var url = BASE_URL + '?' + params
    var signed_url = url + '&sso_token=' + AuthService.getSSOToken()

    return fetch(signed_url).then((response) => {
      return response.json()
    }).then((response) => {
      if ('results' in response) {
        return response['results'].pop()
      } else {
        return response
      }
    })
  }
}
