<template>
  <span />
</template>

<script>
export default {
  props: {
    start: {
      type: Number,
      default: 0
    },
    decimal: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 2.5
    },
    options: {
      type: Object,
      default: null
    },
    end: {
      type: Number,
      default: null,
      required: !0
    }
  },
  data () {
    return {
      oCountUp: null
    }
  },
  computed: {
    startValue: function () {
      return this.start
    },
    endValue: function () {
      return this.end
    },
    decimalValue: function () {
      return this.decimal
    },
    durationValue: function () {
      return this.duration
    }
  },
  watch: {
    end: function (newValue) {
      if (this.oCountUp) {
        this.oCountUp.update(newValue)
      }
    }
  },
  mounted () {

    // instantiate the countUp for the element
    // eslint-disable-next-line no-undef
    this.oCountUp = new CountUp(this.$el, this.startValue,
      this.endValue,
      this.decimalValue,
      this.durationValue,
      this.options
    )
    this.oCountUp.start()
  }
}
</script>
<style />
