<template>
  <keep-alive>
    <div>
      <div v-show="!hideTable">
        <mytable
          title="People paid from my active projects"
          class="investigators"
          v-bind:toggledata="selectperiod"
          v-bind:columns="data.columns"
          v-bind:double_header="true"
          v-bind:sortkey="'EmployeeLastName'"
          v-bind:sortdir="1"
          v-bind:sortorders="{'EmployeeLastName':1}"
          v-bind:store="data.rows"
          v-bind:error="data.error"
          v-bind:performance="data.performance"
          v-bind:loading="data.loading"
          v-bind:selection="'PeoplePaidFromProjects'"
          @update-selectdata="selectOpt"
        />
      </div>

      <div v-show="hideTable">
        <peoplesalary v-bind:employee-i-d="employeeID" />
      </div>
    </div>
  </keep-alive>
</template>

<script>
import mytable from '../_mytable.vue'
import {Dictionary, Timer, DateUtils} from '../../utils/'
import {ReportService, UserService} from '../../services/'
import peoplesalary from './PeopleSalary.vue'
import Wildcat from '../projects/Wildcat.json'

export default {
  components: {
    mytable: mytable,
    peoplesalary: peoplesalary
  },
  data () {
    return {
      table_mode: 'VERTICAL',
      hideTable: false,
      employeeID: null,
      cacheservice: {},
      netid: '',
      last_loading_time: '',
      NO_ACCESS: false,
      store: { rows: [] },
      data: {
        columns: ['profile_style', 'EmployeeFirstName', 'EmployeeLastName', 'EmployeeJobTitle', 'EmployeeHRDeptName', 'YYYYMM'],
        rows: [],
        performance: '',
        error: null,
        loading: true
      },
      selectperiod: {
        options: [
          { text: 'Past 6 Months Only', value: 'six-months'},
          { text: 'All', value: 'all-people'}
        ],
        idx: 0
      },
      details: {
        selectdata: {
          options: [ {text: '', value: ''} ],
          idx: 0
        }
      }
    }
  },

  watch: {
    'employeeID': {
      handler: function (newVal, oldVal) {
        this.$parent.$data.employeeID = newVal
      },
      deep: true
    }
  },
  mounted () {
    if (this.$route.params.netid === 'wildcat') {
      this.netid = Wildcat.effort.sponsored.NETID
    } else {
      this.netid = this.$route.params.netid
    }
    this.loadData()
  },
  methods: {
    formatTitle (key) {
      var selection = 'PeoplePaidFromProjects'
      return Dictionary.formatTitle(key, selection)
    },
    formatLabel (key) {
      return Dictionary.formatLabel(key, 'PeoplePaidFromProjects')
    },
    goBack () {
      console.debug('PARENT GO BACK')
      this.employeeID = null
      this.hideTable = false
    },
    selectRow (row) {
      var options = this.data.rows.map(row => {
        return {
          text: row['EmployeeFirstName'] + ' ' + row['EmployeeLastName'],
          sortidx: row['EmployeeLastName'],
          value: row['EmployeeID']
        }
      })
      this.hideTable = true
      options.sort((a, b) => {
        return a.sortidx > b.sortidx ? 1 : -1
      })
      var list_codes = options.map(_row => _row.value)
      var idx = list_codes.indexOf(row['EmployeeID'])
      this.details.selectdata.options = options
      this.details.selectdata.idx = idx
    },
    loadData () {
      this.data.loading = true
      var timer = new Timer()
      var that = this
      var report_opts = {
        report_name: 'era-rp-effort',
        p_Service: 'RD027',
        p_NetID: this.netid
      }
      this.data.error = ''
      ReportService.get(report_opts, this.$store).then(data => {
        timer.tick('Loaded from network in ')
        that.data.loading = false
        if ('error' in data) {
          that.data.error = data.error
        } else {
          that.data.rows = data.rows.map((row) => {
            row.profile_style = 'none'
            return row
          })

          // Inject Wildcat
          if (that.$route.params.netid === 'wildcat') {
            var wildcatUser = UserService.getDemoUser()
            that.data.rows.push(wildcatUser)
          }
        }
        timer.tick('parsed in ')
        that.data.performance = timer.toString()

        // load thumb from s3
        var URL_TO_JSON_WITH_VALID_IMAGES = process.env.PHOTO_VALID_NETID_URL
        fetch(URL_TO_JSON_WITH_VALID_IMAGES).then(function (response) {
          return response.json()
        }).catch(function (err) {
          console.log(err)
        }).then(function (valid_netids) {
          that.data.rows = that.data.rows.map(row => {
            var netid = row.NetID.toLowerCase()
            var my_url = process.env.PHOTO_BASE_URL + netid + '.jpg'
            if ((netid in valid_netids) && valid_netids[netid]['HasPhoto'] === 'Y') {
              row.profile_style = 'url(' + my_url + ')'
            } else if (netid === 'wildcat') {
              row.profile_style = 'url(' + process.env.PHOTO_BASE_URL + netid + '.jpg)'
            } else {
              row.profile_style = ''
            }
            return row
          })
        })
        that.data.loading = false
        this.store.rows = this.data.rows
        that.selectOpt('six-months')
      }, (err) => {
        that.data.error = err
        console.log(err)
      }).catch(err => {
        that.data.error = err
        that.data.loading = false
        console.error('Unable to parse the results', err)
      })
    },
    pad (value, length) {
      var res = (value.toString().length < length) ? this.pad('0' + value, length) : value
      return res
    },
    selectOpt (value) {
      if (value === 'all-people') {
        this.selectperiod.idx = 1
        this.data.rows = this.store.rows
      } else {
        this.selectperiod.idx = 0

        var now = new Date()
        var toYear = now.getFullYear()
        var toMonth = now.getMonth() + 1
        var out_d = DateUtils.addMonths(toYear, toMonth, 0)
        toYear = out_d[0]
        toMonth = out_d[1]
        var d = DateUtils.addMonths(toYear, toMonth, -6) // 6 months
        var fromYYYYMM = '' + d[0] + this.pad(d[1], 2)

        var rows = []

        this.store.rows.forEach(row => {
          if (row['YYYYMM'] >= fromYYYYMM) { rows.push(row) }
        })
        this.data.rows = rows
      }
    }
  }
}
</script>

<style lang="scss">
    .investigators {
      table {
        td, th {
        text-align: left;
        }
      }
    }
</style>
