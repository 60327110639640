var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "actions" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.selectMode(191)
              },
            },
          },
          [_vm._v("Over the Cap")]
        ),
        _c(
          "button",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.selectMode(192)
              },
            },
          },
          [_vm._v("Required")]
        ),
        _c(
          "button",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.selectMode(193)
              },
            },
          },
          [_vm._v("Voluntary")]
        ),
      ]),
      _c("mytable", {
        attrs: {
          title: "",
          columns: _vm.data.columns,
          store: _vm.data.rows,
          labels: _vm.data.labels,
          loading: _vm.data.loading,
          performance: _vm.data.performance,
          error: _vm.data.error,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }