import Vue from 'vue'
import AuthService from '../auth/AuthService.js'
import {QueryBuilder} from '../utils/'
import VueResource from 'vue-resource'
Vue.use(VueResource)

export default {
  get (data, store) {
    var user = AuthService.getUser()
    if (!user) {
      console.warn('User is not logged in. Bailing')
      return null
    }

    var BASE_URL = process.env.VUE_APP_API_URL + '/investigators'

    var params = QueryBuilder.build(data)
    var url = BASE_URL + '?' + params

    var cache = store.state.reports[url]
    cache = cache ? JSON.parse(cache) : cache
    if (cache) {
      // GOT Cache!
      var promise = new Promise((resolve, reject) => {
        resolve(cache)
      })
      return promise
    }

    // add sso token
    var signed_url = url + '&sso_token=' + AuthService.getSSOToken()
    // var that = this
    return fetch(signed_url).then(response => {
      return response.json()
    }).then(data => {
      store.commit('set', url, data)
      if (data.errorMessage === 'UNAUTHORIZED') {
        AuthService.logout()
      }
      return data
    }).catch(err => {
      console.error(err)
    })
  }
}
