import Vue from 'vue'
import AuthService from '../auth/AuthService.js'
import {QueryBuilder} from '../utils/'
import VueResource from 'vue-resource'
Vue.use(VueResource)

export default {
  set (params) {
    var user = AuthService.getUser()
    if (!user) {
      console.warn('User is not logged in. Bailing')
      return null
    }

    var url = process.env.VUE_APP_API_URL + '/projection' + '?sso_token=' + AuthService.getSSOToken()

    var payload = {
      project_id: params.project_id,
      projection: params.projection
    }
    var body = JSON.stringify(payload)

    var opts = {
      method: 'POST',
      body: body,
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
    return fetch(url, opts).then(response => {
      var data = response.json()
      if (data.errorMessage === 'UNAUTHORIZED') {
        AuthService.logout()
      }
      return data
    }, function (err) {
      console.error(err)
    })
  },
  delete (params) {
    var user = AuthService.getUser()
    if (!user) {
      console.warn('User is not logged in. Bailing')
      return null
    }

    var url = process.env.VUE_APP_API_URL + '/projection' + '?sso_token=' + AuthService.getSSOToken()

    var payload = {
      sso_token: AuthService.getSSOToken(),
      project_id: params.project_id,
      projection_id: params.projection_id
    }
    var body = JSON.stringify(payload)

    var opts = {
      method: 'DELETE',
      body: body,
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
    return fetch(url, opts).then(response => {
      var data = response.json()
      if (data.errorMessage === 'UNAUTHORIZED') {
        AuthService.logout()
      }
      return data
    }, function (err) {
      console.error(err)
    })
  },
  get (data, store) {
    var user = AuthService.getUser()
    if (!user) {
      console.warn('User is not logged in. Bailing')
      return null
    }

    var BASE_URL = process.env.VUE_APP_API_URL + '/projection'

    var params = QueryBuilder.build(data)
    var url = BASE_URL + '?' + params

    // check URL cache:
    var cache = store.state.reports[url]
    cache = cache ? JSON.parse(cache) : cache
    if (cache) {
      // GOT Cache!
      var promise = new Promise((resolve, reject) => {
        resolve(cache)
      })
      // console.debug("CACHED: ", url);
      return promise
    }
    // console.debug("NETWORK:", url);

    // add sso token
    var signed_url = url + '&sso_token=' + AuthService.getSSOToken()

    return fetch(signed_url).then((response) => {
      var data = response.json()
      store.commit('set', url, data)
      if (data.errorMessage === 'UNAUTHORIZED') {
        AuthService.logout()
      }
      // console.debug("CACHING:", url);
      return data
    }, function (err) {
      console.error(err)
    })
  }
}
