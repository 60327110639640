var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("keep-alive", [
    _c("div", [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hideTable,
              expression: "!hideTable",
            },
          ],
        },
        [
          _c("mytable", {
            staticClass: "investigators",
            attrs: {
              title: "People paid from my active projects",
              toggledata: _vm.selectperiod,
              columns: _vm.data.columns,
              double_header: true,
              sortkey: "EmployeeLastName",
              sortdir: 1,
              sortorders: { EmployeeLastName: 1 },
              store: _vm.data.rows,
              error: _vm.data.error,
              performance: _vm.data.performance,
              loading: _vm.data.loading,
              selection: "PeoplePaidFromProjects",
            },
            on: { "update-selectdata": _vm.selectOpt },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hideTable,
              expression: "hideTable",
            },
          ],
        },
        [_c("peoplesalary", { attrs: { "employee-i-d": _vm.employeeID } })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }