<template>
  <router-view />
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss">
.dropdown {display:inline-block}
.tabs {
  .router-link-active {font-weight : bold}
}
</style>
