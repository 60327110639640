var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "button_bar" }, [
        _c(
          "button",
          {
            staticClass: "rd-small-button tips",
            class: { active: _vm.option === "tips" },
            on: {
              click: function ($event) {
                return _vm.showPage("tips")
              },
            },
          },
          [_vm._v("TIPS & SUPPORT")]
        ),
        _c(
          "button",
          {
            staticClass: "rd-small-button data_guide",
            class: { active: _vm.option === "data_guide" },
            on: {
              click: function ($event) {
                return _vm.showPage("data_guide")
              },
            },
          },
          [_vm._v("DATA GUIDE")]
        ),
        _c(
          "button",
          {
            staticClass: "rd-small-button enhancement_log",
            class: { active: _vm.option === "enhancement_log" },
            on: {
              click: function ($event) {
                return _vm.showPage("enhancement_log")
              },
            },
          },
          [_vm._v("ENHANCEMENT LOG")]
        ),
      ]),
      _c("tips", { attrs: { option: _vm.option } }),
      _c("dataguide", { attrs: { option: _vm.option } }),
      _c("enhancement", { attrs: { option: _vm.option } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }