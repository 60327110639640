<template>
  <mytable
    title=""
    v-bind:back="{text:'Overview'}"
    class="expense-details"
    v-bind:columns="data.columns"
    v-bind:dropdowndata="selectdata"
    v-bind:store="data.rows"
    v-bind:buttons="data.buttons"
    v-bind:labels="data.labels"
    v-bind:sortkey="'CheckDate'"
    v-bind:sortdir="-1"
    v-bind:sortorders="{'CheckDate':-1}"
    v-bind:loading="data.loading"
    v-bind:performance="data.performance"
    v-bind:error="data.error"
    @update-selectdata="selectOpt"
  />
</template>

<script>
import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import Wildcat from '../Wildcat.json'
import async from 'async'

export default {
  components: {
    mytable: mytable
  },
  props: {
    back: {type: Object, default: null}
    // class: {type: String, default: null}
  },
  data () {
    return {
      showSalary: false,
      salaryRole: false,
      accountlist: ['60010', '78001', '60100', '60210', '60200'],
      accountcode: '',
      selectdata: {
        options: [{
          text: '',
          value: ''
        }],
        idx: 0
      },
      store: {
        rows: [],
        columns: ['Date', 'AccountDescription', 'Description', 'TransactionSource', 'Amount', 'TransactionID'],
        columnsAggregate: ['Date', 'Description', '_TRANSACTION_SOURCE_WITH_METADATA', '_SUM', 'TransactionID'],
        columnsSalary: ['CheckDate', 'AccountDescription', 'Name', 'EmplID', 'EntryType', 'Amount'],
        columnsPending: []
      },
      data: {
        // columns: ["Transaction Type", "Account Description", "Account Code", "Description", "Amount", "Date", "Transaction ID"],
        columns: ['Date', 'AccountDescription', 'Description', 'TransactionSource', 'Amount', 'TransactionID'],
        rows: [],
        error: '',
        loading: true,
        buttons: [{
          'label': 'Transactions',
          'value': 'individual-transactions',
          'active': true
        },
        {
          'label': 'Expense Reports',
          'value': 'aggregate-expense-reports',
          'active': false
        }

        ]
      }
    }
  },
  watch: {
    '$parent.$data.details.selectdata': {
      handler: function (newVal, oldVal) {
        this.selectdata = newVal
        console.debug('GOT SELECTION', newVal)
        this.load()
      },
      deep: true
    }
  },
  mounted () {
    this.netid = this.$route.params.netid
    if (this.netid === 'wildcat') {
      this.netid = Wildcat.sponsored.NETID
      this.project_id = Wildcat.sponsored.PROJECT_ID
    } else {
      this.project_id = this.$route.params.id
    }
  },
  methods: {
    buttonClick (button_value) {
      // update the button state
      // eslint-disable-next-line no-return-assign
      this.data.buttons.forEach(button => button.active = button.value === button_value)

      switch (button_value) {
        case 'aggregate-expense-reports':
          this.aggregateExpenseReports()
          break
        case 'individual-transactions':
          this.individualTransactions()
          break
        case 'salary-details':
          this.salaryDetails()
          break
        default:
          console.log('Unknown button value', button_value)
          break
      }
    },
    salaryDetails () {
      var opts = {
        report_name: 'era-rp-salary',
        p_Service: 'RD021',
        p_PrjID: this.project_id,
        p_Account: this.accountcode,
        p_DrillType: 'SPON',
        rowLimit: 1000
      }
      var that = this
      this.data.loading = true
      this.data.error = ''
      this.data.rows = []
      ReportService.get(opts, this.$store).then(data => {
        that.data.columns = that.store.columnsSalary.slice(0)
        if ('error' in data) {
          that.data.error = data.error
          that.data.loading = false
        } else {
          that.data.rows = data.rows
          if (that.$route.params.netid === 'wildcat') {
            that.data.rows.forEach(item => {
              item['Amount'] = 500.42 + Math.round(Math.random() * 150000)
            })
          }
          if (this.data.rows.length === 0) {
            this.data.error = 'No data available'
          }
          that.data.loading = false
        }
      })

    },
    aggregateExpenseReports () {

      console.log('Aggregating....')
      this.data.error = ''
      if (this.accountlist.indexOf(this.accountcode) >= 0 && this.salaryRole && this.selectdata.filterBy === 'Actual') {
        if (this.data.buttons.length < 3) {
          var b = {
            'label': 'Salary Details',
            'value': 'salary-details',
            'active': false
          }
          this.data.buttons.push(b)
        }

      } else {
        this.data.buttons = this.data.buttons.slice(0, 2)
      }
      // AGGREGATE by reference [only for expense reports]

      // tally up reference totals for rows with same ref.
      var expense_reports_aggregate = {}

      this.store.rows
        .filter(row => row['TransactionSource'] === 'Expense Report')
        .forEach(row => {
          // reference
          var reference = '' + row['TransactionID']
          // initailze key
          if (!(reference in expense_reports_aggregate)) {
            expense_reports_aggregate[reference] = row
            expense_reports_aggregate[reference]['_SUM'] = 0
            expense_reports_aggregate[reference]['_COUNT'] = 0
          }
          expense_reports_aggregate[reference]['_SUM'] += parseFloat(row['Amount'])
          expense_reports_aggregate[reference]['_COUNT']++
          var count = expense_reports_aggregate[reference]['_COUNT']
          expense_reports_aggregate[reference]['_TRANSACTION_SOURCE_WITH_METADATA'] = row['TransactionSource'] + ' (' + count + ')'
        })
      var output_rows = Object.keys(expense_reports_aggregate).map(ref => expense_reports_aggregate[ref])
      // console.log(output_rows);

      // swap headers
      this.showAggregateColumns()
      // set rows
      this.data.rows = output_rows
      if (this.data.rows.length === 0) {
        this.data.error = 'No data available'
      }
      this.data.loading = false
    },
    individualTransactions () {
      this.data.error = ''
      if (this.accountlist.indexOf(this.accountcode) >= 0 && this.salaryRole && this.selectdata.filterBy === 'Actual') {
        if (this.data.buttons.length < 3) {
          var b = {
            'label': 'Salary Details',
            'value': 'salary-details',
            'active': false
          }
          this.data.buttons.push(b)
        }

      } else {
        this.data.buttons = this.data.buttons.slice(0, 2)
      }
      // set original headers
      this.showOriginalColumns()
      // show original individual transactions.
      this.data.rows = this.store.rows
      if (this.data.rows.length === 0) {
        this.data.error = 'No data available'
      }
      this.data.loading = false
    },
    formatLabel (key) {
      var selection = 'SponsoredITDTransactionDetail'
      if (this.data.buttons.length > 2) {
        selection = this.data.buttons[2].active ? 'SalaryDetail' : 'SponsoredITDTransactionDetail'
      }
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'SponsoredITDTransactionDetail'
      if (this.data.buttons.length > 2) {
        selection = this.data.buttons[2].active ? 'SalaryDetail' : 'SponsoredITDTransactionDetail'
      }
      return Dictionary.formatTitle(key, selection)
    },
    goBack () {
      console.log('detail go back')
      this.$parent.goBack()
    },
    selectOpt (value) {
      var searchedopt = this.selectdata.options.filter(opt => opt.value === value).pop()
      this.selectdata.idx = this.selectdata.options.indexOf(searchedopt)
      this.load(value)
    },
    selectRow (row) {
      console.log('nothing to fetch yet')
      // check if we are in aggregate mode for expense reports
      if ('_SUM' in row) {
        // filter by reference
        this.data.rows = this.store.rows.filter(it => it['TransactionID'] === row['TransactionID'])

        var total_row = {}
        this.store.columns.forEach(key => {
          total_row[key] = ''
        })
        total_row['Date'] = 0
        total_row['AccountDescription'] = 'Total'
        var sum = this.data.rows.reduce((total, row) => {
          return total + parseFloat(row['Amount'])
        }, 0)
        total_row['Amount'] = sum
        this.data.rows.push(total_row)
        this.showOriginalColumns()
      }

      if (this.salaryRole) {
        this.showSalary = true
      }
    },
    showAggregateColumns () {
      this.data.columns = this.store.columnsAggregate.slice(0)
    },
    showOriginalColumns () {
      this.data.columns = this.store.columns.slice(0)
    },
    load (account_code) {
      var timer = new Timer()
      this.data.loading = true
      this.data.columns = this.store.columnsPending
      var that = this
      // Check the default account code from call arg, otherwise,
      // get the value from the select object.
      if (account_code === undefined) {
        account_code = this.selectdata.options[this.selectdata.idx].value
      }
      this.accountcode = account_code
      // YYYYMM
      // @TODO: Add the date translation
      var d = new Date()
      var yyyy = d.getFullYear()
      var mm = (d.getMonth() + 1)
      mm = mm < 10 ? '0' + mm : mm
      var end_dt = '' + yyyy + mm
      var start_dt = '' + (yyyy - 5) + mm
      var opts = []

      opts[0] = {
        report_name: 'era-rp-main',
        p_Service: 'RD010',
        p_PrjID: this.project_id,
        p_Account: account_code,
        p_FromYYYYMM: start_dt,
        p_ToYYYYMM: end_dt,
        rowLimit: 1000
      }

      opts[1] = {
        report_name: 'era-rp-support',
        p_Service: 'RD022',
        p_PrjID: this.project_id
      }

      this.data.loading = true

      this.data.error = ''
      that.data.rows = []
      that.store.rows = []
      var count = 0
      async.map(opts, function (item, callback) {
        callback(null, ReportService.get(item, this.param).then(data => {
          if ('error' in data) {
            that.data.error = data.error
            that.data.loading = false
            return
          } else {
            if (data.headers.indexOf('SalaryAccess') >= 0) {
              that.salaryRole = data.rows[0].SalaryAccess === 'Yes'
              if (that.accountlist.indexOf(account_code) >= 0 && that.salaryRole && that.selectdata.filterBy === 'Actual') {
                if (that.data.buttons.length < 3) {
                  var b = {
                    'label': 'Salary Details',
                    'value': 'salary-details',
                    'active': false
                  }
                  that.data.buttons.push(b)

                }
                that.data.buttons[2].active = true
                that.data.buttons[0].active = false
                that.data.buttons[1].active = false
              } else {
                if (that.data.buttons.length > 2) {
                  that.data.buttons = that.data.buttons.slice(0, 2)
                }
              }
            } else {
              if (that.$route.params.netid === 'wildcat') {
                that.data.rows = data.rows.sort((a, b) => {
                  return a.Date > b.Date ? -1 : 1
                }).map(row => {
                  row['Amount'] = 500.42 + Math.round(Math.random() * 150000)
                  row['TransactionID'] = '4200' + Math.round(Math.random() * 10000)
                  return row
                })
              } else {
                that.data.rows = data.rows.filter(row => {
                  if (that.selectdata.filterBy) {
                    // note -- the select data has a different key for encumerences.
                    // resolve the difference here
                    var cat_map = {
                      'Encumbrance': 'Encumbered'
                    }
                    var key = row['TransactionType']
                    var transaction_type = key in cat_map ? cat_map[key] : key

                    return transaction_type === that.selectdata.filterBy
                  } else {
                    return true
                  }
                }).sort((a, b) => {
                  return a.Date > b.Date ? -1 : 1
                })
              }
              // keep original data set for further filtering
              that.store.rows = that.data.rows.slice(0) // copy
              if ((that.selectdata.filterBy === 'Encumbrance' && that.data.buttons.length > 2) || that.accountlist.indexOf(that.accountcode) < 0) {
                that.data.buttons = that.data.buttons.slice(0, 2)
                that.data.buttons[0].active = that.data.buttons[0].active || that.data.buttons[1].active ? that.data.buttons[0].active : true
              }
            }
          }

          if (count > 0) {
            if (that.data.buttons[0].active) {
              that.individualTransactions()
            }
            if (that.data.buttons[1].active) {
              that.aggregateExpenseReports()
            }
            if (that.data.buttons.length > 2 && that.data.buttons[2].active) {
              that.salaryDetails()
            }
          } else {
            count++
          }
          timer.tick('loaded in ')
          that.data.performance = timer.toString()

        }).catch(err => {
          that.data.loading = false
          console.error('Unable to parse the results', err)
        }))
      }.bind({
        param: this.$store
      }), function done (err, data) { console.log(err) })
    }
  }
}
</script>
<style lang="scss">
.expense-details {
  th.Amount,
  td.Amount {
    //@include current;
    text-align: right;
  }
}
</style>
