var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "usercard" },
    [
      _vm.loaded
        ? [
            _c("img", {
              staticClass: "user-profile-picture",
              attrs: { src: _vm.user.data.picture },
            }),
            _c(
              "div",
              { staticClass: "usercard-info" },
              [
                _vm.error
                  ? [
                      _c("h1", [_vm._v("NetID: " + _vm._s(_vm.netid))]),
                      _c("p", [_vm._v(_vm._s(_vm.error))]),
                    ]
                  : _vm._e(),
                _vm.user.data.displayName[0]
                  ? [
                      _c("div", { staticClass: "user-name-email" }, [
                        _c("h1", { staticClass: "user-name" }, [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.user.data.displayName[0]),
                            },
                          }),
                        ]),
                        _c("p", { staticClass: "email" }, [
                          _c(
                            "a",
                            { attrs: { href: `mailto:${_vm.user.data.mail}` } },
                            [_vm._v(_vm._s(_vm.user.data.mail))]
                          ),
                          _c("small", [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.togglePosition()
                                  },
                                },
                              },
                              [_vm._v("[more]")]
                            ),
                          ]),
                        ]),
                        _vm.showPosition
                          ? _c(
                              "div",
                              { staticClass: "user-position" },
                              [
                                _c(
                                  "transition",
                                  { attrs: { name: "expando" } },
                                  [
                                    _c("span", {
                                      staticClass: "value",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.user.data.position
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm.fstatus == "true"
                          ? _c("div", [
                              _c("p", [
                                _c("strong", [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeFavorite(_vm.netid)
                                        },
                                      },
                                    },
                                    [_vm._v("Remove as favorite")]
                                  ),
                                ]),
                              ]),
                            ])
                          : _c("div", [
                              _c("p", [
                                _c("strong", [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.addFavorite(_vm.netid)
                                        },
                                      },
                                    },
                                    [_vm._v("Add as favorite")]
                                  ),
                                ]),
                              ]),
                            ]),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }