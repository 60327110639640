var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("keep-alive", [
    _c(
      "div",
      [
        _vm.tryagain
          ? _c(
              "div",
              { staticClass: "modal-mask download-modal" },
              [
                _c("transition", { attrs: { name: "modal" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "on-clickaway",
                          rawName: "v-on-clickaway",
                          value: _vm.hideTryagain(),
                          expression: "hideTryagain()",
                        },
                      ],
                      staticClass: "modal-container",
                    },
                    [
                      _c("div", { staticClass: "modal-header" }, [
                        _c(
                          "div",
                          {
                            staticClass: "modal-close",
                            on: {
                              click: function ($event) {
                                return _vm.hideTryagain()
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                      ]),
                      _c("div", { staticClass: "modal-body transaction" }, [
                        _c("p", [
                          _vm._v("Data load timed out. Please, refresh page."),
                        ]),
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "rd-small-button feed_close",
                            on: {
                              click: function ($event) {
                                return _vm.hideTryagain()
                              },
                            },
                          },
                          [_vm._v("Ok")]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "metadata-block" },
          [
            _vm.AuthService.getUser().data.uid !== _vm.pi_netid
              ? [
                  _c(
                    "div",
                    { staticClass: "usercard-wrapper" },
                    [_c("usercard", { attrs: { netid: _vm.pi_netid } })],
                    1
                  ),
                ]
              : _vm._e(),
            _vm.AuthService.getUser().data.uid === _vm.pi_netid
              ? [_c("h2", [_vm._v("My Projects")])]
              : _vm._e(),
            _c("div", { staticClass: "download-actions" }, [
              _c(
                "button",
                {
                  staticClass: "rd-download excel",
                  class: { working: _vm.downloading.xlsx },
                  on: {
                    click: function ($event) {
                      return _vm.download("xlsx")
                    },
                  },
                },
                [_vm._v("DOWNLOAD PORTFOLIO DATA")]
              ),
            ]),
            _c("div", { staticClass: "tabs" }, [
              _c(
                "ul",
                { staticClass: "nav nav-pills" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Sponsored_Projects",
                          params: { netid: _vm.pi_netid },
                        },
                        tag: "li",
                        role: "presentation",
                      },
                    },
                    [
                      _c("a", { staticClass: "sponsored tab" }, [
                        _vm._v("Sponsored"),
                      ]),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "NonSponsored_Projects",
                          params: { netid: _vm.pi_netid },
                        },
                        tag: "li",
                        role: "presentation",
                      },
                    },
                    [
                      _c("a", { staticClass: "nonsponsored tab" }, [
                        _vm._v("Non-Sponsored"),
                      ]),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Pending_Projects",
                          params: { netid: _vm.pi_netid },
                        },
                        tag: "li",
                        role: "presentation",
                      },
                    },
                    [
                      _c("a", { staticClass: "pending tab" }, [
                        _vm._v("Pending"),
                      ]),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Protocols_Protocols",
                          params: { netid: _vm.pi_netid },
                        },
                        tag: "li",
                        role: "presentation",
                      },
                    },
                    [
                      _c("a", { staticClass: "protocol tab" }, [
                        _vm._v("All Protocols"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
        _c("router-view"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }