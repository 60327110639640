var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mytable", {
    attrs: {
      title: "",
      back: { text: _vm.backtext },
      columns: _vm.data.columns,
      store: _vm.data.rows,
      buttons: _vm.data.buttons,
      labels: _vm.data.labels,
      sortkey: "CheckDate",
      sortdir: -1,
      sortorders: { CheckDate: -1 },
      loading: _vm.data.loading,
      performance: _vm.data.performance,
      error: _vm.data.error,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }