<template :is="currentView">
  <keep-alive>
    <mytable
      title="Investigators"
      class="investigators"
      v-bind:toggledata="selectfavorites"
      v-bind:columns="data.columns"
      v-bind:double_header="true"
      v-bind:sortkey="'PrimaryLastName'"
      v-bind:sortdir="1"
      v-bind:sortorders="{'PrimaryLastName':1}"
      v-bind:store="data.rows"
      v-bind:error="data.error"
      v-bind:performance="data.performance"
      v-bind:loading="data.loading"
      @update-selectdata="selectOpt"
    />
  </keep-alive>
</template>

<script>
// import UserService from '../services/'
import {InvestigatorsService, FavoriteService, PhotoService, UserService } from '../services/'
import mytable from './_mytable.vue'
import {Dictionary, Timer} from '../utils/'

export default {
  components: {
    mytable: mytable
  },
  data () {
    return {
      table_mode: 'VERTICAL',
      cacheservice: {},
      store_all: { rows: [] },
      data: {
        columns: ['Favorites', 'profile_style', 'PrimaryFirstName', 'PrimaryLastName', 'CampusEmail', 'ResearcherNetID', 'DeptartmentDescription'],
        rows: [],
        performance: '',
        error: null,
        loading: true,
        favList: []
      },
      selectfavorites: {
        options: [
          { text: 'Show Favorites', value: 'showfavorites'},
          { text: 'Show All', value: 'showall'}
        ],
        idx: 0
      },
      last_loading_time: '',
      NO_ACCESS: false
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    formatTitle (key) {
      var selection = 'RD000'
      return Dictionary.formatTitle(key, selection)
    },
    formatLabel (key) {
      var selection = 'RD000'
      return Dictionary.formatLabel(key, selection)
    },
    selectRow (row) {
      localStorage.setItem('favstatus', row['Favorites'])
      var netid = row['ResearcherNetID']
      var path = 'projects/' + netid + '/sponsored/'
      // console.log('WANT', path)
      this.$router.push({path: path})
    },
    loadData () {
      this.data.loading = true
      var timer = new Timer()
      var that = this
      var report_opts = {
        report_name: 'era-rp-support',
        p_Service: 'RD014'
      }

      // clear fav status then pull array of fav_ids
      FavoriteService.get().then(response => {
        // eslint-disable-next-line no-prototype-builtins
        if (response.hasOwnProperty('Item') && response.Item.hasOwnProperty('fav_list')) {
          this.data.favList = response.Item.fav_list
        };
        // pass reference to the store.
        InvestigatorsService.get(report_opts, this.$store).then(data => {
          // Add a performance measurement named timestamp and pass to <mytable/>
          timer.tick('Loaded from network in ')

          that.data.rows = data.rows.map((row) => {
            row.profile_style = 'none'
            row.Favorites = false
            if (this.data.favList.length > 0) {
              this.data.favList.forEach(fav => {
                if (fav === row.ResearcherNetID) { row.Favorites = true }
              })
            }
            return row
          })

          var WildcatData = UserService.getDemoUser()
          that.data.rows.push({
            CampusEmail: WildcatData.CampusEmail,
            DeptartmentDescription: WildcatData.DeptartmentDescription,
            Favorites: false,
            PrimaryFirstName: WildcatData.PrimaryFirstName,
            PrimaryLastName: WildcatData.PrimaryLastName,
            ResearcherNetID: WildcatData.ResearcherNetID
          })

          // load thumb from s3 bucket
          PhotoService.getPeople().then(response => response.json(), function (err) { console.log('fetch error: ' + err) })
            .then(function (valid_netids) {
              // console.log(JSON.stringify(valid_netids))
              that.data.rows = that.data.rows.map(row => {
                var netid = row.ResearcherNetID.toLowerCase()
                if ((netid in valid_netids) && valid_netids[netid]['HasPhoto'] === 'Y') {
                  // eslint-disable-next-line quotes
                  row.profile_style = "url(" + PhotoService.getThumbnail(netid) + ")"
                } else if (netid === 'wildcat') {
                  row.profile_style = 'url(https://researchportal-photos-prod.s3.us-east-2.amazonaws.com/photos/source/wildcat.jpg)'
                } else { row.profile_style = '' }
                return row
              })
            })
            .then(() => {
              that.data.loading = false
              this.store_all.rows = this.data.rows
              if (this.data.favList.length > 0) { that.selectOpt('showfavorites') };
            })

        }, (err) => {
          that.data.error = err
          console.log(err)
        }).catch((err) => {
          that.data.error = err
          that.data.loading = false
          console.log(err)
        })
      })
    },
    selectOpt (value) {
      this.data.rows = this.store_all.rows
      this.selectfavorites.idx = 1
      if (value === 'showfavorites') {
        var rows = []
        this.selectfavorites.idx = 0
        this.store_all.rows.forEach(row => {
          if (row['Favorites'] === true) { rows.push(row) }
        })
        this.data.rows = rows
      }
    }
  }
}
</script>
<style lang="scss">
  .investigators {
    table {td, th {text-align: left;} }
  }
</style>
