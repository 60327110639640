import AuthService from '../auth/AuthService.js'

export default {
  messages: [{
    mid: 1,
    from: 'no-reply@researchportal.northwestern.edu',
    title: 'Welcome to the Research Portal',
    datetime: 1458185512075,
    body: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum'
  }],
  getUserReceipts () {
    var netid = AuthService.getUser().data.uid
    var receipts = JSON.parse(localStorage.getItem('receipts-' + netid) || '[]')
    return receipts
  },
  setUserReceipt (mid, state) {
    var netid = AuthService.getUser().data.uid
    var receipts = JSON.parse(localStorage.getItem('receipts-' + netid) || '[]')
    if (state) {
      if (receipts.indexOf(mid) < 0) {
        receipts.push(mid)
      }
    } else {
      var idx = receipts.indexOf(mid)
      if (idx >= 0) {
        receipts = receipts.splice(idx, 1)
      }
    }
    localStorage.setItem('receipts-' + netid, JSON.stringify(receipts))
  },
  getMessages () {
    return this.messages
  },
  get (mid) {
    return this.messages.filter((it) => it.mid === mid).pop()
  }
}
