<template>
  <div>
    <mytable
      title="Financial Overview"
      class="['table-totals']"
      v-bind:hidesearch="true"
      v-bind:hidesort="true"
      v-bind:columns="dataTop.columns"
      v-bind:store="dataTop.rows"
      v-bind:labels="dataTop.labels"
      v-bind:loading="dataTop.loading"
      v-bind:performance="dataTop.performance"
      v-bind:error="dataTop.error"
    />

    <div v-show="!hideTable">
      <mytable
        title="Expense Categories"
        class="fin-overview"
        v-bind:toggledata="selectdata"
        v-bind:columns="dataBottom.columns"
        v-bind:store="dataBottom.rows"
        v-bind:labels="dataBottom.labels"
        v-bind:sortkey="'AccountCategoryCode'"
        v-bind:sortdir="1"
        v-bind:sortorders="{'AccountCategoryCode':1}"
        v-bind:projectiontooldata="projectiontool"
        v-bind:loading="dataBottom.loading"
        v-bind:performance="dataBottom.performance"
        v-bind:error="dataBottom.error"
        @update-selectdata="selectOpt"
      />
    </div>

    <div v-show="hideTable">
      <inceptiontodatedetails
        v-bind:class="'inception-to-date-details'"
        v-bind:back="{text:'Inception to Date'}"
      />
    </div>
  </div>
</template>

<script>
import mytable from '../../_mytable.vue'
import {ReportService, ProjectionToolService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import InceptionToDateDetails from './InceptionToDateDetails.vue'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    mytable: mytable,
    inceptiontodatedetails: InceptionToDateDetails
  },
  data () {
    return {
      hideTable: false,
      ignored_keys: ['AccountCategoryDescription', 'Budget', '_PROJECTED', 'Balance'],
      data: {
        // ["YYYYMM", "Fiscal Year - Accounting Period", "Fiscal Year - Accounting Period Description", "Project ID", "ACCOUNT CATEGORY CODE", "ACCOUNT CATEGORY DESCR", "ACTUAL EXPENSE"]
        // columns: ['ACCOUNT CATEGORY DESCR','Fiscal Year - Accounting Period','ACTUAL EXPENSE','ACCOUNT CATEGORY CODE'],
        columns: [],
        rows: [],
        labels: {},
        loading: false
      },
      // Store the pivot data source here. Render the contents into the regular data.rows and data.cols
      store: {
        columns: [],
        rows: []
      },
      projectiontool: {
        projections: []
      },
      details: {
        selectdata: {
          options: [{
            text: '',
            value: ''
          }],
          idx: 0
        }
      },
      MAX_ACCOUNT_CATEGORY_CODE: 1000,
      selectedYear: 0,
      selectedMonth: 0,
      range: {
        start: {
          year: 2016,
          month: 1
        },
        end: {
          year: 2016,
          month: 4
        }
      },
      selectdata: {
        options: [
          { text: 'Inception to Date', value: 'inception-to-date', class: 'inception'},
          { text: 'Expenses by Month', value: 'expenses-by-month', class: 'month_to_month'}
        ],
        idx: 0
      },
      id: 0,
      project: {
        title: 'Foo'
      },
      // This Determines what appears in the top and bottom tables
      dataTop: {
        loading: false,
        columns: ['AccountCategoryDescription', 'Budget', 'Actual', 'Encumbered', '_PROJECTED', 'Balance'],
        rows: [],
        labels: {
          PRJ_ID: 'Project #'
        }
      },
      dataBottom: {
        loading: false,
        columns: ['AccountCategoryDescription', 'Budget', 'Actual', 'Encumbered', '_PROJECTED', 'Balance'],
        rows: [],
        labels: {
          PRJ_ID: 'Project #'
        }
      },
      wasDemo: false
    }
  },
  mounted () {
    this.wasDemo = localStorage.getItem('prevWasWildcat')
    if (this.wasDemo === 'true') {
      this.project_id = Wildcat.sponsored.PROJECT_ID
      this.netid = 'wildcat'
    } else {
      this.project_id = this.$route.params.id
      this.netid = this.$route.params.netid
    }
    this.load()
  },
  methods: {
    useCountUp () {
      return true
    },
    formatLabel (key) {
      var selection = 'SponsoredProjectFinancialsITD'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'SponsoredProjectFinancialsITD'
      return Dictionary.formatTitle(key, selection)
    },
    delegateClick (row, key, $event) {
      console.log('CLICK', key)
      this.showDetails(row, key)
    },
    getBottomRows () {
      var ignore_keys = ['Total', 'Direct Cost Total']
      return this.dataBottom.rows
        .filter(row => ignore_keys.indexOf(row['AccountCategoryDescription']) < 0)
    },
    newProjection (data) {
      this.dataBottom.rows.forEach(row => {
        row['_PROJECTED'] = 0
      })
      this.calculateTotals()
      // update balances
      this.calculateBalances()
    },
    applyProjection (projection) {
      var dict = {}
      // eslint-disable-next-line no-redeclare
      var projection = projection.data.forEach(cat => {
        var key = cat.AccountCategoryCode
        var value = cat.value
        dict[key] = value
      })

      this.dataBottom.rows.forEach(row => {
        var key = row.AccountCategoryCode
        row['_PROJECTED'] = key in dict ? dict[key] : 0
      })
      this.calculateTotals()
      // update balances
      this.calculateBalances()
    },
    saveProjection () {
      var ignore_keys = ['Total', 'Direct Cost Total']

      var projection = {}
      projection.data = []

      var projectionPull = this.dataBottom.rows.filter(row => ignore_keys.indexOf(row['AccountCategoryDescription']) < 0)

      projection.data = projectionPull.map(row => {
        return {'AccountCategoryCode': row['AccountCategoryCode'], value: row['_PROJECTED']}
      })

      projection.title = prompt('Enter the projection title:') || 'N/A'

      var params = {
        project_id: this.project_id,
        projection: projection
      }

      console.log('projection: ', params)

      var that = this
      ProjectionToolService.set(params).then(response => {
        console.log('Projection Saved!', response)
        that.projectiontool.projections = response.projections
        // the response will include an updated list of all the projetions
      }).catch(err => {
        console.log('error saving projection', err)
      })

    },
    deleteProjection (projection) {
      console.log('delete', projection)
      projection.disabled = true
      var opts = {
        project_id: this.project_id,
        projection_id: projection.id
      }
      var that = this
      ProjectionToolService.delete(opts).then(response => {
        console.log('deleted!', response)
        that.projectiontool.projections = response.projections
      }).catch(err => {
        console.log('Unable to delete projection', err)
      })
    },
    accept (row, $event) {
      console.log('ACCEPT')
      row['_PROJECTED'] = parseFloat(row['_PROJECTED']) || 0
      // update totals
      this.calculateTotals()
      // update balances
      this.calculateBalances()
    },
    // calculate the balance for a row
    // balance = budget - (actuals+encumbered+projections)
    calculateRowBalance (row) {
      return (
        parseFloat(row['Budget']) - parseFloat(row['Encumbered']) - parseFloat(row['Actual']) - parseFloat(row['_PROJECTED'])
      )
    },
    calculateBalances () {
      // calculate the 'balance' value for all the rows of the table.
      // EXCEPT the rows that are pulled to the 'totals' table.
      var skip_rows_with_categories = ['Total', 'Direct Cost Total']
      var that = this
      this.dataBottom.rows.forEach(row => {
        if (skip_rows_with_categories.indexOf(row['AccountCategoryDescription']) < 0) {
          row['Balance'] = that.calculateRowBalance(row)
        }
      })
    },
    calculateTotals () {
      // Totals include all costs
      var total = this.dataBottom.rows.reduce((total, row) => total + parseFloat(row._PROJECTED), 0)
      // get a reference to the total row
      var row_total = this.dataTop.rows.filter(row => row['AccountCategoryDescription'] === 'Total Direct Cost & F&A').pop()
      row_total._PROJECTED = total

      // Direct Cost Totals include all costs EXCEPT the F&A
      var direct_total = this.dataBottom.rows.filter(row => row['AccountCategoryDescription'] !== 'F&A').reduce((total, row) => total + parseFloat(row._PROJECTED), 0)
      // get a reference ot the direct cost total row
      var row_direct_total = this.dataTop.rows.filter(row => row['AccountCategoryDescription'] === 'Direct Cost Total').pop()
      row_direct_total._PROJECTED = direct_total

      row_total.Balance = this.calculateRowBalance(row_total)
      row_direct_total.Balance = this.calculateRowBalance(row_direct_total)

    },
    selectRow (row) {
      console.log('Ignore row select')
    },
    showDetails (row, key) {
      if (this.ignored_keys.indexOf(key) >= 0) {
        console.log('Ignoring click')
        return
      }
      if (row.AccountCategoryCode >= this.MAX_ACCOUNT_CATEGORY_CODE) {
        this.hideTable = true
        // format the categories into selectdata format.

        var options = this.categories.map(cat => {
          return {
            text: cat['AccountCategoryDescription'],
            value: cat['AccountCategoryCode']
          }
        })
        console.log(options)
        // find the IDX for the current selection.
        var list_codes = options.map(_row => _row.text)
        var idx = list_codes.indexOf(row['AccountCategoryDescription'])
        // Append the suffix (actual|encumbered) to the options
        var lower_case_key = key.toLocaleLowerCase()
        options.forEach(opt => {
          opt.text += ' (' + lower_case_key + ')'
        })

        this.details.selectdata.options = options
        this.details.selectdata.idx = idx
        // key: ACTUAL or ENCUMBERED
        this.details.selectdata.filterBy = key
      } else {
        console.debug('Ignoring top table click')
      }
    },
    selectOpt (value, name) {
      switch (name) {
        case 'projection-tool':
          this.applyProjection()
          break
        default:
          var path = '/project/' + this.$route.params.id + '/' + this.$route.params.netid + '/overview/' + value
          this.$router.push({path: path})
          break
      }
    },
    goBack () {
      console.debug('do nothing')
      this.hideTable = false
    },
    loadProjections () {
      var req = { project_id: this.project_id}
      var that = this
      ProjectionToolService.get(req, this.$store).then(data => {
        // we want to track the 'disabled' computed attribute on each row.
        that.projectiontool.projections = data.projections.map(it => {
          it.disabled = false
          return it
        })
      }).catch(err => {
        that.projectiontool.projections = []
        console.log('unable to load projetions, or projections not set for this project yet.', err)
      })
    },
    load () {
      // Load projections for this project from AWS S3.
      this.loadProjections()

      // ReportService timer.
      var timer = new Timer()
      var that = this

      // report opts
      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD007',
        p_PrjID: this.project_id
      }

      // enable loading spinner
      this.dataBottom.loading = true
      this.dataTop.loading = true

      // Fetch Data
      ReportService.get(report_opts, this.$store).then(data => {
        that.dataTop.loading = false
        that.dataBottom.loading = false
        if ('error' in data) {
          that.dataTop.error = data.error
          that.dataBottom.error = data.error
        } else {
          data.headers = data.headers.filter(key => {
            return ['ProjectID'].indexOf(key) < 0
          })

          // top table
          // that.dataTop.columns = data.headers.filter(key => {
          //   return ['PRJ_SD','ACCOUNT_CATEGORY_CODE'].indexOf(key) < 0;
          // });
          // rows with Account Category Code smaller than 'MAX_REPORT_CODE'
          // should be bundled on the top table.

          // inject the projected key
          var PROJECTED_KEY = '_PROJECTED'

          // override numbers for the wildcat user
          if (this.netid === 'wildcat') {
            data.rows.forEach(row => {
              ['Actual', 'Encumbered', 'Budget'].forEach(key => {
                var fake_number = 500.42 + Math.round(Math.random() * 250000)
                row[key] = fake_number
              })
              row['Balance'] = row['Budget'] - row['Actual'] - row['Encumbered']
            })
          }

          that.dataTop.rows = data.rows.filter(row => {
            return parseInt(row.AccountCategoryCode, 10) < that.MAX_ACCOUNT_CATEGORY_CODE
          }).map(row => {
            var total_keys = ['Total', 'Direct Cost Total', 'Cost Share']
            if (total_keys.indexOf(row['AccountCategoryDescription']) >= 0) {
              row[PROJECTED_KEY] = 0
            } else {
              row[PROJECTED_KEY] = ''
            }
            row.editable = null
            return row
          })
          // bottom table
          // that.dataBottom.columns = that.dataTop.columns.slice()
          that.dataBottom.rows = data.rows.filter(row => {
            return parseInt(row.AccountCategoryCode, 10) >= that.MAX_ACCOUNT_CATEGORY_CODE
          }).map(row => {
            row[PROJECTED_KEY] = 0
            row.editable = false
            row['_clickable'] = true
            return row
          })

          // Load Categories:
          var keys = ['AccountCategoryCode', 'AccountCategoryDescription']
          var seen = []
          var categories = that.dataBottom.rows.map(row => {
            var out = {}
            keys.forEach(key => {
              out[key] = row[key]
            })
            return out
          }).filter(row => {
            var code = row['AccountCategoryCode']
            if (seen.indexOf(code) >= 0) {
              return false
            } else {
              seen.push(code)
              return true
            }
          })
          that.categories = categories
          console.debug('CAT', categories.map(row => row.AccountCategoryCode))

        }
        timer.tick('loaded in ')
        that.dataBottom.performance = timer.toString()
        this.calculateTotals()
      }).catch(err => {
        console.log('Unable to parse the results', err)
      })
    }
  }
}
</script>
<style lang="scss">
  .top-table {
    th {opacity : 0.8}
  }
</style>
