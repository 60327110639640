<template>
  <div v-show="option===&quot;tips&quot;" id="tip_iframe_holder" />
</template>
<script>
import pym from 'pym.js'
export default {
  props: {
    option: {type: String, default: null}
  },
  data () {
    return {

    }
  },
  mounted () {

    var url = 'https://sites.northwestern.edu/researchportalblog/help?shaka'
    // eslint-disable-next-line no-unused-vars
    var pymParent = new pym.Parent('tip_iframe_holder', url, {})
  }
}
</script>
