var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mytable", {
    staticClass: "subcontracts-proposal",
    attrs: {
      title: "Subcontracts - Budget Info",
      columns: _vm.data.columns,
      toggledata: _vm.selectdata,
      periodpickerdata: _vm.periodpickerdata,
      store: _vm.data.rows,
      footer_rows: _vm.data.footer_rows,
      sortkey: "ProposalNumber",
      sortdir: 1,
      sortorders: { ProposalNumber: 1 },
      labels: _vm.data.labels,
      loading: _vm.data.loading,
      performance: _vm.data.performance,
      error: _vm.data.error,
    },
    on: { "update-selectdata": _vm.selectOpt },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }