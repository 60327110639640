<template>
  <div class="page-error">
    <h1>Oops!</h1>
    <div class="image" />
  </div>
</template>

<script>
export default {
  data () { return {} },
  mounted () {}
}
</script>

<style lang="scss">
.page-error {
  .image {
    img {width : 100%;}
  }
}
</style>
