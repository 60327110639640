var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.feed_modal_expanded
        ? _c(
            "div",
            { staticClass: "modal-mask feedback-modal" },
            [
              _c("transition", { attrs: { name: "modal" } }, [
                _c("div", { staticClass: "modal-wrapper" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "on-clickaway",
                          rawName: "v-on-clickaway",
                          value: _vm.myClickAway,
                          expression: "myClickAway",
                        },
                      ],
                      staticClass: "modal-container",
                    },
                    [
                      _c("div", { staticClass: "modal-header" }, [
                        _c("h2", [_vm._v("Provide Feedback")]),
                        _c(
                          "div",
                          {
                            staticClass: "modal-close",
                            on: {
                              click: function ($event) {
                                return _vm.cancel()
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                      ]),
                      _c("div", { staticClass: "modal-body feedback" }, [
                        _c("p", [
                          _vm._v("Please provide your feedback below:"),
                        ]),
                        _c("form", { staticClass: "web-form" }, [
                          _c("div", { staticClass: "field" }, [
                            _c("label", { attrs: { for: "subject" } }, [
                              _c("strong", [_vm._v("Subject")]),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.feedback.subject,
                                  expression: "feedback.subject",
                                },
                              ],
                              staticClass: "feedback-input",
                              attrs: {
                                autofocus: "",
                                name: "subject",
                                placeholder: "Enter a subject here (optional)",
                              },
                              domProps: { value: _vm.feedback.subject },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.feedback,
                                    "subject",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "field" }, [
                            _c("label", { attrs: { for: "comments" } }, [
                              _c("strong", [_vm._v("Comments")]),
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.feedback.body,
                                  expression: "feedback.body",
                                },
                              ],
                              attrs: {
                                id: "comments",
                                name: "comments",
                                placeholder: "Enter your comment here...",
                              },
                              domProps: { value: _vm.feedback.body },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.feedback,
                                    "body",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c("span", { staticClass: "rd-submit-status" }, [
                          _vm._v(_vm._s(_vm.status)),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "rd-submit-button",
                            class: { submitted: _vm.validated == 1 },
                            attrs: {
                              disabled: _vm.validated == 1 ? true : false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.send()
                              },
                            },
                          },
                          [_vm._v("Submit")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "rd-small-button feed_close",
                            on: {
                              click: function ($event) {
                                return _vm.cancel()
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.AuthService.authenticated
        ? [
            _c("div", { staticClass: "feedback-button-wrapper" }, [
              _c(
                "button",
                {
                  staticClass: "rd-small-button feed_feedback",
                  attrs: { id: "show-modal" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.openModal()
                    },
                  },
                },
                [_vm._v("Provide Feedback")]
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }