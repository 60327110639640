import Vue from 'vue'
import AuthService from '../auth/AuthService.js'
import {QueryBuilder} from '../utils/'
import VueResource from 'vue-resource'
Vue.use(VueResource)

export default {

  getStatus (data) {
    var user = AuthService.getUser()
    if (!user) {
      console.warn('User is not logged in. Bailing')
      return null
    }

    var BASE_URL = process.env.VUE_APP_API_URL + '/pendingproposalstatus'

    var params = QueryBuilder.build(data)
    var url = BASE_URL + '?' + params

    // add sso token
    var signed_url = url + '&sso_token=' + AuthService.getSSOToken()

    return fetch(signed_url).then((response) => {
      var data = response.json()
      if (data.errorMessage === 'UNAUTHORIZED') {
        AuthService.logout()
      }
      return data
    }, function (err) {
      console.error(err)
    })
  },

  mark (data) {
    var user = AuthService.getUser()
    if (!user) {
      console.warn('User is not logged in. Bailing')
      return null
    }

    var BASE_URL = process.env.VUE_APP_API_URL + '/pendingproposals'

    var params = QueryBuilder.build(data)
    var url = BASE_URL + '?' + params

    // add sso token
    var signed_url = url + '&sso_token=' + AuthService.getSSOToken()

    return fetch(signed_url).then((response) => {
      var data = response.json()
      if (data.errorMessage === 'UNAUTHORIZED') {
        AuthService.logout()
      }
      return data
    }, function (err) {
      console.error(err)
    })
  }
}
