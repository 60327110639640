<template>
  <div v-show="option===&quot;data_guide&quot;" id="dg_iframe_holder" />
</template>
<script>
import pym from 'pym.js'
export default {
  props: {
    option: {type: String, default: null}
  },
  data () {
    return {
      pymParent: null
    }
  },
  watch: {
    'option': {
      handler: function (newVal, oldVal) {
        if (newVal === 'data_guide' && this.pymParent === null) {

          var url = 'https://sites.northwestern.edu/researchportalblog/northwestern-research-portal-help-data-guide/'
          // eslint-disable-next-line no-undef
          this.pymParent = new pym.Parent('dg_iframe_holder', url, {})
        }
      }
    }

  }
}
</script>
