<template>
  <div>
    <div class="branding">
      <a href="/">Northwestern University</a>
    </div>
    <ul class="my-links">
      <li>
        <a id="renavlink" target="_blank" href="https://riswebprod3.orad.northwestern.edu/secure/clogin?oritQS=https://researchnavigator.northwestern.edu/#/home">RESEARCH NAVIGATOR</a>
      </li>
      <li>
        <a target="_blank" href="https://nufin.northwestern.edu">NUFIN</a>
      </li>
      <li>
        <a target="_blank" href="https://ceres.northwestern.edu">CERES</a>
      </li>
      <li>
        <a target="_blank" href="https://reporting.northwestern.edu">COGNOS BI</a>
      </li>
      <li>
        <a target="_blank" href="https://ers.northwestern.edu">EFFORT REPORTING</a>
      </li>
      <li>
        <a target="_blank" href="https://www-kronos.itcs.northwestern.edu">KRONOS</a>
      </li>
      <li>
        <a target="_blank" href="https://coi.northwestern.edu">COI</a>
      </li>
      <li>
        <a target="_blank" href="https://eirbplus.northwestern.edu">eIRB</a>
      </li>
      <li>
        <a target="_blank" href="https://www.eacuc.northwestern.edu">eIACUC</a>
      </li>
      <li>
        <a target="_blank" href="https://lumen.northwestern.edu/">LUMEN</a>
      </li>
      <li>
        <a target="_blank" href="https://enotis.northwestern.edu">ENOTIS</a>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  data () {
    return {
      links: []
    }
  },
  mounted () {
    var rn_url = process.env.VUE_APP_RESEARCHNAV_URL
    document.getElementById('renavlink').setAttribute('href', rn_url)
  }
}
</script>
<style lang='scss'>
footer {
  margin-top : 20px;
  margin-bottom : 20px;
}
.my-links{
  padding-left : 0;
  li {
    float : left;
    border-right : 1px solid red;
    padding-right : 10px;
    list-style : none;
    padding-left : 10px;
    margin-left : 0;
  }
  li:nth-child(1){
    padding-left : 0;
  }
  li:last-child{
    border-right : none !important;
  }
}
</style>
