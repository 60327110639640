var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "branding" }, [
        _c("a", { attrs: { href: "/" } }, [_vm._v("Northwestern University")]),
      ]),
      _c("ul", { staticClass: "my-links" }, [
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                id: "renavlink",
                target: "_blank",
                href: "https://riswebprod3.orad.northwestern.edu/secure/clogin?oritQS=https://researchnavigator.northwestern.edu/#/home",
              },
            },
            [_vm._v("RESEARCH NAVIGATOR")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://nufin.northwestern.edu",
              },
            },
            [_vm._v("NUFIN")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://ceres.northwestern.edu",
              },
            },
            [_vm._v("CERES")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://reporting.northwestern.edu",
              },
            },
            [_vm._v("COGNOS BI")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: { target: "_blank", href: "https://ers.northwestern.edu" },
            },
            [_vm._v("EFFORT REPORTING")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://www-kronos.itcs.northwestern.edu",
              },
            },
            [_vm._v("KRONOS")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: { target: "_blank", href: "https://coi.northwestern.edu" },
            },
            [_vm._v("COI")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://eirbplus.northwestern.edu",
              },
            },
            [_vm._v("eIRB")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://www.eacuc.northwestern.edu",
              },
            },
            [_vm._v("eIACUC")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://lumen.northwestern.edu/",
              },
            },
            [_vm._v("LUMEN")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://enotis.northwestern.edu",
              },
            },
            [_vm._v("ENOTIS")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }