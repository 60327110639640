<template>
  <keep-alive>
    <div class="pending-projects">
      <mytable
        title="Pending"
        v-bind:columns="data.columns"
        v-bind:store="data.rows"
        v-bind:labels="data.labels"
        v-bind:loading="data.loading"
        v-bind:performance="data.performance"
        v-bind:error="data.error"
      />
    </div>
  </keep-alive>
</template>

<script>

import mytable from '../../_mytable.vue'
import {ReportService, PendingProposalService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    mytable: mytable
  },
  data () {
    return {
      data: {
        columns: ['ProposalTitle', 'MainPI', 'InstitutionNumber', 'Sponsor', 'ProjectRequestStartDate', 'ProjectRequestEndDate', 'DateSubmitted', 'CurrentProposalStatus'],
        labels: {

        },
        netid: '',
        rows: [],
        performance: '',
        loading: true,
        error: null
      }
    }
  },
  mounted () {
    if (this.$route.params.netid === 'wildcat') {
      this.netid = Wildcat.pending.NETID
    } else {
      this.netid = this.$route.params.netid
    }
    var that = this
    var timer = new Timer()
    this.data.loading = true
    var report_opts = {
      report_name: 'era-rp-main',
      p_Invest_ID: (this.netid + '').toUpperCase(),
      p_Service: 'RD001'
    }

    var DEFAULT_STATUS = 'ACTIVE'
    ReportService.get(report_opts, this.$store).then(data => {
      that.data.loading = false
      if ('error' in data) {
        that.data.error = data.error
      } else {
        var institution_ids = []
        that.data.rows = data.rows.map(row => {
          row['Actions'] = 'Mark as not-funded'
          row['funding'] = ''
          row['_STATUS'] = DEFAULT_STATUS
          // fake user
          if (this.$route.params.netid === 'wildcat') {
            row['InstitutionNumber'] = 'SP421234' + Math.round(Math.random() * 1000)
          }
          return row
        })
        that.data.rows.forEach(row => {
          institution_ids.push(row['InstitutionNumber'])
        })
        // Ask DynamoDB for any entries for this user.
        var opts = {
          'institution_ids': institution_ids
        }
        PendingProposalService.getStatus(opts).then(proposal_status => {
          that.data.rows.forEach(row => {
            // if we have any info on this row's SP, then attach it t the row
            var sp = row['InstitutionNumber']
            var status = proposal_status.Items.filter(it => it.institution_id === sp).pop()
            if (status) {
              row['_STATUS'] = status.status
              if (row['_STATUS'] === 'NOT_FUNDED') {
                row['CurrentProposalStatus'] += ' (' + status.status.replace(/_/g, ' ') + ')'
              }
            }
          })
        }).catch(err => { console.log(err) })
      }
      timer.tick('loaded in ')
      that.data.performance = timer.toString()
    })
  },
  methods: {
    formatLabel (key) {
      var selection = 'PendingProposal'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'PendingProposal'
      return Dictionary.formatTitle(key, selection)
    },
    mark (row, state) {
      var opts = {
        action: state,
        institution_number: row['InstitutionNumber'],
        p_Invest_ID: (this.netid + '').toUpperCase()
      }

      console.log('WAITING')
      row.funding = 'waiting'
      PendingProposalService.mark(opts).then(data => {
        if ('errorMessage' in data) {
          console.log(data.errorMessage)
          row.funding = ''
        } else if (opts.action === 'ACTIVE') {
          row.funding = 'active'
          row['_STATUS'] = 'ACTIVE'
          row['CurrentProposalStatus'] = row['CurrentProposalStatus'].substring(0, row['CurrentProposalStatus'].indexOf(' '))
        } else if (opts.action === 'NOT_FUNDED') {
          row.funding = 'not_funded'
          row['_STATUS'] = 'NOT_FUNDED'
          row['CurrentProposalStatus'] += ' (' + row['_STATUS'].replace(/_/g, ' ') + ')'
        } else {
          row.funding = ''
        }

        console.log('OK', data)
      }).catch(err => {
        console.log('ERR', err)
      })
    },
    selectRow (row) {
      console.log('Nothing todo')
    }
  }
}
</script>
<style lang="scss" />
