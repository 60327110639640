var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.download_modal_expanded
        ? _c(
            "div",
            { staticClass: "modal-mask download-modal" },
            [
              _c("transition", { attrs: { name: "modal" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "on-clickaway",
                        rawName: "v-on-clickaway",
                        value: _vm.myClickAway,
                        expression: "myClickAway",
                      },
                    ],
                    staticClass: "modal-container",
                  },
                  [
                    _c("div", { staticClass: "modal-header" }, [
                      _c("h2", [_vm._v(_vm._s(_vm.buttonText))]),
                      _c(
                        "div",
                        {
                          staticClass: "modal-close",
                          on: {
                            click: function ($event) {
                              return _vm.cancel()
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                    ]),
                    _c("div", { staticClass: "modal-body transaction" }, [
                      _c("p", [_vm._v(_vm._s(_vm.messageText))]),
                      _c("p", [
                        _vm._v("This process can take up to five minutes."),
                      ]),
                      _c("p", [
                        _vm._v(
                          "For the appropriate use of salary data, please refer to the "
                        ),
                        _c("b", [
                          _c("u", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "http://www.northwestern.edu/myhr/admin/salary-access-policy.html",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Salary Access Policy")]
                            ),
                          ]),
                        ]),
                        _vm._v("."),
                      ]),
                      _c("p", [
                        _vm._v(
                          "For the appropriate use of student data, please refer to the "
                        ),
                        _c("b", [
                          _c("u", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "http://www.registrar.northwestern.edu/academic_records/FERPA_policy.html",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("FERPA Policy")]
                            ),
                          ]),
                        ]),
                        _vm._v("."),
                      ]),
                    ]),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "rd-submit-button",
                          on: {
                            click: function ($event) {
                              return _vm.send()
                            },
                          },
                        },
                        [_vm._v("Send me the link")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "rd-small-button feed_close",
                          on: {
                            click: function ($event) {
                              return _vm.cancel()
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.AuthService.authenticated
        ? [
            _c(
              "button",
              {
                staticClass: "rd-download email",
                attrs: { id: "show-modal" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.openModal()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.buttonText))]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }