<template>
  <div>
    <div class="actions">
      <button class="btn" @click="selectMode(191)">Over the Cap</button>
      <button class="btn" @click="selectMode(192)">Required</button>
      <button class="btn" @click="selectMode(193)">Voluntary</button>
    </div>
    <mytable
      title=""
      v-bind:columns="data.columns"
      v-bind:store="data.rows"
      v-bind:labels="data.labels"
      v-bind:loading="data.loading"
      v-bind:performance="data.performance"
      v-bind:error="data.error"
    />
  </div>
</template>

<script>

import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    mytable: mytable
  },
  data () {
    return {
      data: {
        columns: ['FundCode', 'FundDescription', 'ProjectID', 'AccountCategoryCode'],
        rows: [],
        labels: {
        }
      }
    }
  },
  mounted () {
    if (this.$route.params.netid === 'wildcat') {
      this.project_id = Wildcat.nonsponsored.PROJECT_ID
    } else {
      this.project_id = this.$route.params.id
    }
    this.load()
  },
  methods: {
    formatLabel (key) {
      var selection = 'SponsoredProjectCostShare'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'SponsoredProjectCostShare'
      return Dictionary.formatTitle(key, selection)
    },
    selectMode (mode) {
      console.log('select mode')
    },
    selectRow (row) { console.warn('nothing to do') },

    load () {
      var timer = new Timer()
      var that = this

      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD009',
        p_PrjID: this.$route.params.id
      }

      ReportService.get(report_opts, this.$store).then(data => {
        that.data.loading = false
        if ('error' in data) {
          that.data.error = data.error
        } else {
          that.data.rows = data.rows
        }
        timer.tick('loaded in ')
        that.data.performance = timer.toString()
      }).catch(err => {
        console.error('Unable to parse the results', err)
      })
    }
  }
}
</script>
<style lang="scss" />
