var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mytable", {
    staticClass: "expense-details",
    attrs: {
      title: "",
      back: { text: "Overview" },
      dropdowndata: _vm.selectdata,
      columns: _vm.data.columns,
      datepickerdata: _vm.datepickerdata,
      store: _vm.data.rows,
      labels: _vm.data.labels,
      loading: _vm.data.loading,
      performance: _vm.data.performance,
      error: _vm.data.error,
    },
    on: { "update-selectdata": _vm.selectOpt },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }