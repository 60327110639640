var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "keep-alive",
    [
      _c("mytable", {
        staticClass: "investigators",
        attrs: {
          title: "Investigators",
          toggledata: _vm.selectfavorites,
          columns: _vm.data.columns,
          double_header: true,
          sortkey: "PrimaryLastName",
          sortdir: 1,
          sortorders: { PrimaryLastName: 1 },
          store: _vm.data.rows,
          error: _vm.data.error,
          performance: _vm.data.performance,
          loading: _vm.data.loading,
        },
        on: { "update-selectdata": _vm.selectOpt },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }