<template>
  <div>
    <feedback />
    <header>
      <nav class="top-nav">
        <div class="container">
          <div class="branding">
            <a href="/">Research Dashboard</a>
          </div>
          <div v-if="!AuthService.authenticated" class="user-menu">
            <a class="user-login-button" @click.stop="login()">Login</a>
          </div>

          <div v-if="AuthService.authenticated" class="user-menu">
            <li>
              <div v-on-clickaway="foo" class="usercard-wrapper-button" @click="user_profile_expanded=!user_profile_expanded">
                <div class="left">
                  <template v-if="AuthService.getUser().data.jpegPhoto">
                    <img class="user-profile-picture x-small" v-bind:src="'data:image/jpeg;base64,' + AuthService.getUser().data.jpegPhoto">
                  </template>
                </div>
                <div class="right">
                  <p class="name">{{ AuthService.getUser().data.displayName[0] }}</p>
                </div>
              </div>

              <div v-if="user_profile_expanded" class="user-dropdown-menu" style="display:block">
                <transition-group name="expand">
                  <div key="up1" class="user-snippet">
                    <template v-if="AuthService.getUser().data.jpegPhoto">
                      <img key="up2" class="user-profile-picture medium" v-bind:src="'data:image/jpeg;base64,' + AuthService.getUser().data.jpegPhoto">
                    </template>
                    <div key="up3" class="user-name-email">
                      <h3 key="up4" class="title">{{ AuthService.getUser().data.displayName[0] }}</h3>
                      <p key="up5" class="email">{{ AuthService.getUser().data.mail }}</p>
                    </div>
                  </div>
                  <ul key="up6" class="user-dropdown-menu-actions">
                    <li key="up7" class="actions">
                      <router-link to="/profile" class="rd-small-button user_profile">My Profile</router-link>
                      <a key="up8" class="rd-small-button user_logout" style="cursor:pointer" @click="AuthService.logout()">Signout</a>
                    </li>
                  </ul>
                </transition-group>
              </div>
            </li>
            <li><router-link to="/help" class="help tab">HELP</router-link></li>
            <li><router-link to="/policy" class="policy tab">POLICY</router-link></li>
          </div>
        </div>
      </nav>

      <nav class="bottom-nav">
        <div class="container">
          <div v-if="AuthService.authenticated" class="main-nav">
            <ul class="nav">
              <li v-if="pi_netid && AuthService.authenticated && AuthService.getUser().data.RD014_ROLE.role === 'STAFF'">
                <router-link to="/investigators" class="pi tab" @click.native="resetWasWildcat()">RETURN TO INVESTIGATORS</router-link>
              </li>
              <li v-if="pi_netid">
                <router-link v-bind:to="{ name: 'Sponsored_Projects', params: { netid: pi_netid, }}" class="project tab">{{ project_label }}</router-link>
              </li>
              <li v-if="pi_netid">
                <router-link v-bind:to="{ name: 'PIheader', params: { netid: pi_netid }}" class="people tab">{{ people_label }}</router-link>
              </li>
              <li v-if="AuthService.getUser().data.RD014_ROLE.role === 'STAFF'">
                <div class="search tab">
                  <button id="show-modal" class="rd-search" @click.stop="openModal()">project quick access</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="container mobile-only">
          <div class="branding">
            <a href="/">Research Dashboard</a>
          </div>
          <button v-on-clickaway="boo" v-bind:class="{'active': mobile_menu_expanded}" class="mobile-menu-icon mobile-only" @click="mobile_menu_expanded=!mobile_menu_expanded" />

          <div v-show="mobile_menu_expanded" class="mobile-menu-dropdown mobile-only">
            <ul v-if="AuthService.authenticated" class="nav">
              <li v-if="AuthService.authenticated && AuthService.getUser().data.RD014_ROLE.role === 'STAFF' && pi_netid">
                <router-link to="/investigators'" class="pi tab" @click.native="resetWasWildcat()">RETURN TO INVESTIGATORS</router-link>
              </li>
              <li v-if="pi_netid">
                <router-link v-bind:to="{ name: 'Sponsored_Projects', params: { netid: pi_netid, }}" class="project tab">{{ project_label }}</router-link>
              </li>
              <li v-if="pi_netid">
                <router-link v-bind:to="{ name: 'PIheader', params: { netid: pi_netid }}" class="people tab">{{ people_label }}</router-link>
              </li>
              <li>
                <router-link to="/help">HELP</router-link>
              </li>
              <li>
                <router-link to="/policy">POLICIES</router-link>
              </li>
              <li>
                <router-link to="/profile">My Profile</router-link>
              </li>
              <li>
                <a @click="AuthService.logout()">Signout</a>
              </li>
              <li v-if="AuthService.getUser().data.RD014_ROLE.role === 'STAFF'">
                <div class="search tab">
                  <button id="show-modal" @click.stop="openModal()">project quick access</button>
                </div>
              </li>
            </ul>
            <ul v-if="!AuthService.authenticated" class="nav navbar-nav">
              <li>
                <a class="user-login-button" @click.stop="login()">Login</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
    <div v-if="search_modal_expanded" class="modal-mask">
      <transition name="modal">
        <div class="modal-wrapper">
          <div v-on-clickaway="myClickAway" class="modal-container">
            <div class="modal-header">
              <h2>Enter Project ID</h2>
              <div class="modal-close" @click="close">Close</div>
            </div>
            <div class="modal-body search">
              <div class="search tab">
                <input v-model="search.query" type="number" class="search-input" placeholder="Search by project ID" @keyup.enter="searchbyprj">
              </div>
              <p class="small purple">For sponsored projects, you will be taken directly to the project within its primary PI's portfolio.</p>
              <p class="small purple">For non-sponsored projects, you will be taken directly to its project manager's portfolio where you can select the project if you have the appropriate security access.</p>
            </div>
            <div class="modal-footer">
              <span class="rd-submit-status">{{ searchtext }}</span>
              <!-- <button class="rd-small-button search" v-bind:class="{'submitted':validated==1}" v-bind="validated==1 ? true: false" @click="searchbyprj">Search</button> -->
              <button class="rd-small-button search" v-bind:class="{'submitted':validated==1}" @click="searchbyprj">Search</button>
              <button class="rd-small-button feed_close" @click="close">Close</button>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div id="iframe_holder_home" class="home-notification" />

    <div v-show="AuthService.authenticated && AuthService.showAnnouncements && showAnnouncements" id="announcements">
      <div class="wp-notification modal-header" style="position:relative">
        <div class="modal-close" @click="toggleAnnouncement()">X</div>
        <h4>Data Usage Policies</h4>
        <p>Research Portal now contains salary data and student data.</p>
        <p>For the appropriate use of salary data, please refer to the <b><u><a href="http://www.northwestern.edu/myhr/admin/salary-access-policy.html" target="_blank">Salary Access Policy</a></u></b>.</p>
        <p>For the appropriate use of student data, please refer to the <b><u><a href="http://www.registrar.northwestern.edu/academic_records/FERPA_policy.html" target="_blank">FERPA Policy</a></u></b>.</p>
      </div>
    </div>

    <div id="content" class="container">
      <div
        class="scrolltotop"
        style="bottom: -50px;"
        data-start="bottom: -50px;"
        data-10-start="bottom: 25px;"
        data-150-end="bottom: 25px;"
        data-end="bottom:105px;"
        data-anchor-target="body"
      >
        <a onclick="scroll(0,0)">Top</a>
      </div>
      <div style="clear:both" />
      <router-view />
    </div>
    <div id="footer">
      <div class="container">
        <my-links />
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'
import AuthService2 from './auth/AuthService.js'
import MyLinks from './components/MyLinks.vue'
import Feedback from './components/Feedback.vue'
import {ReportService} from './services/'
import pym from 'pym.js'

export default {
  directives: {
    onClickaway: onClickaway
  },
  components: {
    MyLinks: MyLinks,
    feedback: Feedback
  },
  data () {
    return {
      search: {
        query: ''
      },
      pi_netid: '',
      project_label: 'MY PROJECTS',
      people_label: 'MY PEOPLE',
      searchtext: '',
      showAnnouncements: true,
      AuthService: AuthService2,
      user_profile_expanded: false,
      searching_in_progress: false,
      mobile_menu_expanded: false,
      search_modal_expanded: false,
      validated: 0,
      breadcrumbs: []
    }
  },
  watch: {
    '$route.params.netid': {
      handler: function (newVal, oldVal) {
        if (this.$route.path !== '/policy' && this.$route.path !== '/help' && this.$route.path !== '/profile') {
          var wasWildcat = localStorage.getItem('prevWasWildcat')
          if (newVal && AuthService2.user.data.RD014_ROLE.role === 'STAFF') {
            if (wasWildcat === 'true') {
              this.pi_netid = 'wildcat'
              this.project_label = "WILDCAT'S PROJECTS"
              this.people_label = "WILDCAT'S PEOPLE"
            } else {
              this.pi_netid = newVal
              this.project_label = newVal.toUpperCase() + "'S PROJECTS"
              this.people_label = newVal.toUpperCase() + "'S PEOPLE"
            }
          }

        }
      }
    }
  },
  mounted () {
    var url = 'https://sites.northwestern.edu/researchportalblog/homepage-announcements?shaka'
    // eslint-disable-next-line no-unused-vars
    var pymParent = new pym.Parent('iframe_holder_home', url, {})
    localStorage.setItem('prevWasWildcat', false)
  },
  methods: {
    hideModal () { this.search_modal_expanded = false },
    openModal () {
      this.search_modal_expanded = true
      var $input = document.querySelector('.search-input')
      if ($input) {
        $input.focus()
      }
    },
    toggleModal () {
      console.log('toggle')
      this.search_modal_expanded = !this.search_modal_expanded
    },
    myClickAway () {
      console.log('click away')
      if (this.search_modal_expanded) {
        this.close()
      }
    },
    resetWasWildcat () {
      localStorage.setItem('prevWasWildcat', false)
      this.pi_netid = ''
    },
    close () {
      this.hideModal()
      this.search.query = ''
      this.validated = 0
      this.searchtext = ''
    },
    toggleAnnouncement () {
      this.showAnnouncements = !this.showAnnouncements
    },
    login () {
      this.user_profile_expanded = false
      AuthService2.login()
    },
    foo () {
      this.user_profile_expanded = false
    },
    boo () {
      this.mobile_menu_expanded = false
    },
    searchbyprj () {
      this.searching_in_progress = true
      this.searchtext = 'Searching project ' + this.search.query + '...'
      this.validated = 1
      var opts = {
        p_Service: 'RD030',
        report_name: 'era-rp-support-nonsso',
        p_PrjID: this.search.query
      }
      var that = this
      ReportService.get(opts, this.$store).then(data => {
        if ('error' in data) {
          that.searchtext = data.error
          this.validated = 0
          window.setTimeout(() => {
            that.searching_in_progress = false
            that.mobile_menu_expanded = false
          }, 3000)
        } else {
          var goto_path = '/profile'
          this.validated = 1
          var query = that.search.query
          if (that.$route.name === 'projects' || that.$route.name === 'project' || that.$route.name === 'non-sponsored') {
            that.$router.push({path: goto_path})
            window.setTimeout(() => {
              if (data.rows[0]['ProjectID'].substring(0, 3) === '600') {
                goto_path = '/project/' + query + '/' + data.rows[0]['NetID'] + '/overview/inception-to-date'
              } else {
                goto_path = '/projects/' + data.rows[0]['NetID'] + '/non-sponsored'
              }
              that.$router.push({path: goto_path})
            }, 300)
          } else {
            if (data.rows[0]['ProjectID'].substring(0, 3) === '600') {
              goto_path = '/project/' + that.search.query + '/' + data.rows[0]['NetID'] + '/overview/inception-to-date'
            } else {
              goto_path = '/projects/' + data.rows[0]['NetID'] + '/non-sponsored'
            }
            that.$router.push({path: goto_path})
          }
          that.searching_in_progress = false
          that.mobile_menu_expanded = false
          that.close()
        }
      })

    }
  }
}
</script>

<style lang="scss">
  @import "./assets/_styles.scss";

  .breadcrumbs {
    ul {
      padding-left : 0px;
      .crumb {
        float : left;
        list-style: none;
        padding-left : 0px;
        padding-right : 20px;
      }
    }
    clear:both
  }
  input{
    width: 100%;
    height: 30px;
    border: 1px solid $rich-black-20;
    &:focus{outline: none;}
    color: $nu-purple;
    font-family: $AkkuratProRegular;
    margin: 15px 0px;
    text-indent: 5px;
    text-overflow: ellipsis;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
</style>
