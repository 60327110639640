var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("keep-alive", [
    _c("div", [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hideTable,
              expression: "!hideTable",
            },
          ],
        },
        [
          _c("mytable", {
            staticClass: "expenses-by-month",
            attrs: {
              title: "Expense Categories",
              toggledata: _vm.selectdata,
              datepickerdata: _vm.datepickerdata,
              columns: _vm.data.columns,
              store: _vm.data.rows,
              footer_rows: _vm.data.footer_rows,
              labels: _vm.data.labels,
              sortkey: "AccountCategoryCode",
              sortdir: 1,
              sortorders: { AccountCategoryCode: 1 },
              loading: _vm.data.loading,
              performance: _vm.data.performance,
              error: _vm.data.error,
            },
            on: { "update-selectdata": _vm.selectOpt },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hideTable,
              expression: "hideTable",
            },
          ],
        },
        [
          _c("myexpensebymonthdetails", {
            staticClass: "expenses-by-month-details",
            attrs: {
              title: "",
              datepickerdata: _vm.expense_by_month_datepickerdata,
              back: { text: "Expenses by month" },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }