<template>
  <div v-on-clickaway="clickAway" v-bind:class="getPickerClass()">
    <a v-bind:class="getPreClass()" @click="addPeriod(-1)">Prev</a>
    <a class="dp-btn pick" @click="togglePeriod()">{{ periodpickerdata.periods[periodpickerdata.selectedPeriod] }}</a>
    <a v-bind:class="getNextClass()" @click="addPeriod(1)">Next</a>

    <template v-if="showPeriod">
      <ul class="dp-drawer">
        <li v-for="(period, index) in periodpickerdata.periods" v-bind:key="index">
          <button v-bind:class="{'active':index===periodpickerdata.selectedPeriod}" class="dp-btn grid" @click="selectPeriod(index)">{{ period }}</button>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {
    onClickaway: onClickaway
  },
  props: {
    periodpickerdata: {type: Object, default: null},
    idx: {type: Number, default: null}
  },
  data () {
    return {
      preClass: 'dir-btn prev disabled',
      nextClass: 'dir-btn next',
      showOptions: false,
      showPeriod: false
    }
  },
  methods: {
    clickAway ($event) {
      this.showPeriod = false
    },
    togglePeriod (state) {
      this.showPeriod = state === undefined ? !this.showPeriod : state
    },
    getPickerClass () {
      if (this.periodpickerdata.periods.length <= 0) {
        return 'periodpicker empty'
      } else {
        return 'periodpicker'
      }
    },
    getPreClass () {
      if (this.periodpickerdata.periods.length <= 5) {
        return 'dir-btn prev disabled'
      }
      if (this.periodpickerdata.selectedPeriod <= 4) {
        return 'dir-btn prev disabled'
      }
      return 'dir-btn prev'
    },
    getNextClass () {
      if (this.periodpickerdata.periods.length <= 5) {
        return 'dir-btn next disabled'
      }
      if (this.periodpickerdata.selectedPeriod === this.periodpickerdata.periods.length - 1) {
        return 'dir-btn next disabled'
      }
      return 'dir-btn next'
    },
    selectPeriod (new_period) {
      if (new_period < 4) {
        new_period = 4
        this.preClass = 'dir-btn prev disabled'
        this.nextClass = 'dir-btn next'

      } else if (new_period >= this.periodpickerdata.periods.length - 1) {
        new_period = this.periodpickerdata.periods.length - 1
        this.preClass = 'dir-btn prev'
        this.nextClass = 'dir-btn next disabled'
      } else {
        this.preClass = 'dir-btn prev'
        this.nextClass = 'dir-btn next'
      }
      this.periodpickerdata.selectedPeriod = new_period
      if (this.$parent.selectPeriod) {
        this.$parent.selectPeriod(new_period)
      }
      this.togglePeriod(false)
    },
    addPeriod (n) {
      var new_period = this.periodpickerdata.selectedPeriod + n
      this.selectPeriod(new_period)
    }
  }
}

</script>
<style lang="scss" />
