<template>
  <mytable
    title=""
    v-bind:back="{text:'Overview'}"
    class="expense-details"
    v-bind:dropdowndata="selectdata"
    v-bind:columns="data.columns"
    v-bind:datepickerdata="datepickerdata"
    v-bind:store="data.rows"
    v-bind:labels="data.labels"
    v-bind:loading="data.loading"
    v-bind:performance="data.performance"
    v-bind:error="data.error"
    @update-selectdata="selectOpt"
  />
</template>

<script>
import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {DateUtils, Timer, Dictionary} from '../../../utils/'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    mytable: mytable
  },
  props: {
    datepickerdata: {type: Object, default: null}
  },
  data () {
    return {
      selectdata: {
        options: [ { text: 'placeholder', value: 'placeholder'}], // keeps error from popping up with parent builds list (watched)
        idx: 0
      },
      data: {
        columns: ['Date', 'AccountDescription', 'Description', 'Amount', 'TransactionID'],
        rows: [],
        labels: {
          'Account Description': 'Account',
          'Date': 'Budget Check Date',
          'Transaction ID': 'Reference'
        },
        loading: false
      },
      foo: {
        years: [2011, 2012, 2013, 2014, 2015, 2019],
        months: DateUtils.MONTHS,
        selectedYear: 0,
        selectedMonth: 0
      }
    }
  },
  watch: {
    '$parent.$data.details.selectdata': {
      handler: function (newVal, oldVal) {
        this.selectdata = newVal
        console.debug('GOT SELECTION', newVal)
        this.load()
      },
      deep: true
    }
  },
  mounted () {
    // Fake User Demo
    if (this.$route.params.netid === 'wildcat') {
      this.netid = Wildcat.sponsored.NETID
      this.project_id = Wildcat.sponsored.PROJECT_ID
    } else {
      this.project_id = this.$route.params.id
    }
  },
  methods: {
    formatLabel (key) {
      var selection = 'SponsoredMonthlyTransactionDetail'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'SponsoredMonthlyTransactionDetail'
      return Dictionary.formatTitle(key, selection)
    },
    selectDate (yyyy, mm) {
      console.log('PICKER', this.datepickerdata)
      this.load()
    },
    goBack () {
      console.debug('GO BACK')
      this.$parent.goBack()
    },
    selectOpt (value) {
      var searchedopt = this.selectdata.options.filter(opt => opt.value === value).pop()
      this.selectdata.idx = this.selectdata.options.indexOf(searchedopt)
      this.load(value)
    },
    selectRow (row) {
      console.log('nothing to fetch yet')
    },
    // value = category account code. Optional
    load (value) {
      var timer = new Timer()
      this.data.loading = true
      var that = this
      if (value === undefined) {
        // eslint-disable-next-line no-redeclare
        var value = this.selectdata.options[this.selectdata.idx].value
      }

      // Check the default account code from call arg, otherwise,
      // get the value from the select object.
      var account_code = value
      var leftPad = function (value) {
        return value < 10 ? '0' + value : '' + value
      }
      // get the year and month from this vue's 'props'
      var year = this.datepickerdata.years[this.datepickerdata.selectedYear] // int;
      // eslint-disable-next-line radix
      var month = parseInt(this.datepickerdata.selectedMonth) // 0..11
      var out_pair = DateUtils.addMonths(year, month, 5)
      var out_yyyy = out_pair[0]
      var out_mm = out_pair[1]

      var start_dt = out_yyyy + leftPad(out_mm, 2)
      // NOTE: Cognos will return results for the 1st day of the next month at 00:00
      var end_dt = start_dt
      console.log('DT', start_dt)

      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD011',
        p_PrjID: this.project_id,
        p_Account: account_code,
        p_FromYYYYMM: start_dt,
        p_ToYYYYMM: end_dt,
        rowLimit: 250
      }

      this.data.error = ''
      this.data.rows = []
      ReportService.get(report_opts, this.$store).then(data => {
        that.data.loading = false
        if ('error' in data || data.rows.length === 0) {
          that.data.error = data.error
        } else {
          // that.data.columns = out.headers;
          that.data.rows = (data.rows || []).sort((a, b) => {
            return a.Date < b.Date ? -1 : 1
          })

          // Fake user overrdie
          if (that.$route.params.netid === 'wildcat') {
            that.data.rows.forEach(row => {
              row['Amount'] = 500.42 + Math.round(Math.random() * 15000)
              row['TransactionID'] = 420000000 + Math.round(Math.random() * 1000)
            })
          }
        }
        timer.tick('loaded in ')
        that.data.performance = timer.toString()
        that.data.loading = false
      }).catch(err => {
        that.data.loading = false
        console.error('Unable to parse the results', err)
      })
    }
  }
}
</script>
<style lang="scss">
.expense-details {
  th.Amount,
  td.Amount {
    text-align: right;
  }
}
</style>
