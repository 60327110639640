import AuthService from '../auth/AuthService.js'
import {QueryBuilder} from '../utils'

export default {
  getPeople () {
    var BASE_URL = process.env.VUE_APP_API_URL + '/thumbpeople'
    var signed_url = BASE_URL + '?sso_token=' + AuthService.getSSOToken()
    return fetch(signed_url)
  },

  getThumbnail (netid) {
    var BASE_URL = process.env.VUE_APP_API_URL + '/thumbnail'
    var data = {'pi_netid': netid}
    var params = QueryBuilder.build(data)
    var url = BASE_URL + '?' + params
    var signed_url = url + '&sso_token=' + AuthService.getSSOToken()
    return signed_url
  }
}
