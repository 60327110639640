export default {
  MONTHS: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  addMonths (year, month, n_months) {
    month += n_months
    while (month > 12) {
      year++
      month -= 12
    }
    while (month < 1) {
      year--
      month += 12
    }
    return [year, month]
  },
  yearRange (first, last_inclusive) {
    var out = []
    for (var year = first; year <= last_inclusive; year++) {
      out.push(year)
    }
    return out
  },
  getLastYears (n_years) {
    var d = new Date()
    var yyyy = d.getFullYear()
    return this.yearRange(yyyy - n_years, yyyy)
  }
}
