<template>
  <div>
    <div class="button_bar">
      <button class="rd-small-button tips" v-bind:class="{'active': option==='tips'}" @click="showPage('tips')">TIPS & SUPPORT</button>
      <button class="rd-small-button data_guide" v-bind:class="{'active': option==='data_guide'}" @click="showPage('data_guide')">DATA GUIDE</button>
      <button class="rd-small-button enhancement_log" v-bind:class="{'active': option==='enhancement_log'}" @click="showPage('enhancement_log')">ENHANCEMENT LOG</button>
    </div>
    <tips v-bind:option="option" />
    <dataguide v-bind:option="option" />
    <enhancement v-bind:option="option" />
  </div>
</template>

<script>
import tips from './Tips.vue'
import dataguide from './DataGuide.vue'
import enhancement from './Enhancement.vue'
export default {
  components: {
    tips: tips,
    dataguide: dataguide,
    enhancement: enhancement
  },
  data () {
    return {
      option: 'tips'
    }
  },
  mounted () {
    // TODO change urls

  },
  methods: {
    showPage (opt) {
      this.option = opt
    }
  }
}
</script>
<style lang="scss">
  @mixin clearfix () {
    &:after {
      content: ".";
      visibility: hidden;
      display: block;
      height: 0;
      clear: both;
    }
  }
  .button_bar{
    position: relative;
    @include clearfix;
    margin: auto;
    width: 60%;
    display: flex;
    button{
      display: block;
      width: 33%;
      margin: 5px 5px;
    }
  }
  @media all and (max-width: 750px){
    .button_bar{
      @include clearfix;
      order: 1;
      clear: both;
      float: none;
      width: 100%;
      display: block;
      button{
        display: block;
        width: 100%;
        margin: 5px 0px;
      }
    }
  }
</style>
