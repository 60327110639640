<template>
  <keep-alive>
    <div>
      <mytable
        title="Salary"
        class="expenses-by-month"
        v-bind:back="{text:'LIST OF PEOPLE'}"
        v-bind:dropdowndata="selectdata"
        v-bind:datepickerdata="datepickerdata"
        v-bind:columns="data.columns"
        v-bind:store="data.rows"
        v-bind:sortkey="'ProjectDescription'"
        v-bind:sortdir="1"
        v-bind:sortorders="{'ProjectDescription':1}"
        v-bind:footer_rows="data.footer_rows"
        v-bind:labels="data.labels"
        v-bind:loading="data.loading"
        v-bind:performance="data.performance"
        v-bind:error="data.error"
        @update-selectdata="selectOpt"
      />
      <small>* Earned monthly payroll amount and percent of base salary. Shows when pay was earned not when paycheck or journal was processed. See Help for more information.</small>
    </div>
  </keep-alive>
</template>

<script>
import mytable from '../_mytable.vue'
import {ReportService, UserService} from '../../services/'
import {DateUtils, Dictionary, Timer} from '../../utils/'
import Wildcat from '../projects/Wildcat.json'

export default {
  components: {mytable: mytable},
  data () {
    return {
      employeeID: '',
      prjs: [],
      data: {
        columns: [],
        rows: [],
        error: '',
        labels: {},
        loading: true
      },
      // Store the pivot data source here. Render the contents into the regular data.rows and data.cols
      store: {
        columns: [],
        rows: [],
        footer_rows: []
      },
      datepickerdata: {
        years: DateUtils.getLastYears(3),
        months: DateUtils.MONTHS,
        selectedYear: 0,
        selectedMonth: 0
      },
      selectdata: {
        options: [{
          text: '',
          value: ''
        }],
        idx: 0
      },
      selectedYear: 0,
      selectedMonth: 0,
      range: {
        start: {
          year: 2016,
          month: 1
        },
        end: {
          year: 2016,
          month: 4
        }
      }
    }
  },
  watch: {
    '$parent.$data.details.selectdata': {
      handler: function (newVal, oldVal) {
        this.selectdata = newVal
        // console.log('GOT SELECTION', newVal)
        this.load()
      },
      deep: true
    },

    'employeeID': {
      handler: function (newVal, oldVal) {
        this.$parent.$data.employeeID = newVal
        var now = new Date()
        var yyyy = now.getFullYear()
        var m = now.getMonth()
        var offset_months = 0 // months
        var d = DateUtils.addMonths(yyyy, m, offset_months)
        var year_idx = this.datepickerdata.years.indexOf(d[0])
        this.datepickerdata.selectedYear = year_idx
        this.datepickerdata.selectedMonth = d[1]
      },
      deep: true
    }
  },
  mounted () {
    if (this.$route.params.netid === 'wildcat') {
      this.netid = Wildcat.effort.sponsored.NETID
      this.employeeID = this.employeeID ? Wildcat.effort.sponsored.EID : null
    } else {
      this.netid = this.$route.params.netid
      this.employeeID = this.$route.params.eid
    }

    // calculate initial date.
    var now = new Date()
    var yyyy = now.getFullYear()
    var m = now.getMonth()
    var offset_months = 0 // months
    var d = DateUtils.addMonths(yyyy, m, offset_months)
    var year_idx = this.datepickerdata.years.indexOf(d[0])
    this.datepickerdata.selectedYear = year_idx
    this.datepickerdata.selectedMonth = d[1]

    // this.load();
  },
  methods: {
    formatLabel (key) {
      var selection = 'PayrollByMonthForEmployee'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'PayrollByMonthForEmployee'
      return Dictionary.formatTitle(key, selection)
    },
    goBack () {
      console.debug('GO BACK')
      this.$parent.goBack()
    },
    selectDate (yyyy, mm) {
      if (yyyy === undefined || mm === undefined) {
        // eslint-disable-next-line no-redeclare
        var yyyy = this.datepickerdata.years[this.datepickerdata.selectedYear]
        // eslint-disable-next-line no-redeclare
        var mm = this.datepickerdata.selectedMonth // 0..11
      }
      this.view(yyyy, mm)
    },
    addMonth (n_months) {
      var new_month = this.selectedMonth + n_months
      if (new_month >= 12) {
        this.selectedMonth = 0
        this.selectedYear++
      } else {
        this.selectedMonth = new_month
      }
      if (new_month < 0) {
        this.selectedMonth = 11
        this.selectedYear--
      }
      this.selectDate()
    },
    pad (value, length) {
      var res = (value.toString().length < length) ? this.pad('0' + value, length) : value
      return res
    },
    view (yyyy, mm) {
      // Get date from user input
      if (this.store.rows.length < 1 || this.data.error) {
        console.debug('No rows found')
        return
      }

      // align to fiscal dates
      var d = DateUtils.addMonths(yyyy, mm, +1)
      // eslint-disable-next-line no-redeclare
      var yyyy = d[0]
      // eslint-disable-next-line no-redeclare
      var mm = d[1]

      // set cols, rows
      var columns = []
      this.data.columns = ['ProjectDescription', 'FundCode', 'Account']
      var n_months = 3
      // generate column keys for the selected date.
      for (var i = 0; i < n_months; i++) {
        var offset = 1 - n_months + i
        // eslint-disable-next-line no-redeclare
        var d = DateUtils.addMonths(yyyy, mm, offset)
        var date_str = d[0] + ' - ' + this.pad(d[1], 2)
        columns.push(date_str)
        this.data.columns.push(date_str)
      }

      var prj_ids = {}
      this.store.rows.forEach(row => {
        var value = row['ProjectID'] + row['FundCode'] + row['AccountCode'] + row['FinancialDeptID']
        if (Object.keys(prj_ids).indexOf(value) < 0) {
          prj_ids[value] = {
            ProjectDescription: row['ProjectDescription'] + ' (' + row['ProjectID'] + ')',
            FundCode: row['FundCode'],
            Account: row['AccountDescription']
          }
          if (row['SalaryRestricted'] === 'Y') {
            prj_ids[value].SalaryRestricted = true
          }
        }
      })
      // generate rows.
      var rows = Object.keys(prj_ids).map(prj => {
        var list = []
        var temp = ''
        this.store.rows.forEach(row => {
          var sel_prj = row['ProjectID'] + row['FundCode'] + row['AccountCode'] + row['FinancialDeptID'] === prj
          var temp = row['YYYYMM']
          temp = temp.substring(0, 4) + ' - ' + temp.substring(4, 6)
          var sel_date = columns.indexOf(temp) >= 0
          if (sel_prj && sel_date) {
            list.push(row)
          }
        })
        var new_row = {}
        columns.forEach(dt => {
          var dkey = dt
          var dval = 0
          var pval = 'N/A'
          list.some(row => {
            temp = row['YYYYMM']
            temp = temp.substring(0, 4) + ' - ' + temp.substring(4, 6)
            if (temp === dt) {
              dval = row['PayrollAmount']
              if (row['PercentOfSalary'] && row['PercentOfSalary'] !== 'null') {
                pval = Math.round(row['PercentOfSalary'] * 100)
              }
              return true
            }
            return false
          })
          if (pval === 'N/A') {
            new_row[dkey] = dval
          } else {
            new_row[dkey] = dval + ' (' + pval + '%)'
          }
        })
        if (prj_ids[prj]['SalaryRestricted']) {
          Object.keys(new_row).forEach(datekeys => {
            new_row[datekeys] = 'SalaryRestricted'
          })
          new_row['SalaryRestricted'] = true
        }
        new_row['ProjectDescription'] = prj_ids[prj]['ProjectDescription']
        new_row['FundCode'] = prj_ids[prj]['FundCode'] === 'null' ? '' : prj_ids[prj]['FundCode']
        new_row['Account'] = prj_ids[prj]['Account']
        return new_row
      })
      this.data.rows = rows
    },
    selectOpt (value) {
      var searchedopt = this.selectdata.options.filter(opt => opt.value === value).pop()
      this.selectdata.idx = this.selectdata.options.indexOf(searchedopt)
    },
    load (value) {
      var timer = new Timer()
      this.data.loading = true
      this.data.rows = []
      var that = this
      // calculate the date range to retrieve
      var now = new Date()
      var toYear = now.getFullYear()
      var toMonth = now.getMonth() + 1
      // add 4 to align with the fiscal period
      var out_d = DateUtils.addMonths(toYear, toMonth, 0)
      toYear = out_d[0]
      toMonth = out_d[1]

      var d = DateUtils.addMonths(toYear, toMonth, -36) // 3 years
      var fromYYYYMM = '' + d[0] + this.pad(d[1], 2)
      var toYYYYMM = '' + toYear + this.pad(toMonth, 2)
      if (value === undefined) {
        if (this.selectdata.options.length === 0) {
          return
        }
        if (this.selectdata.options[this.selectdata.idx].value === UserService.getDemoUser().EmployeeID) {
          value = Wildcat.effort.sponsored.EID
        } else {
          value = this.selectdata.options[this.selectdata.idx].value
        }
      }
      this.employeeID = value
      var report_opts = {
        report_name: 'era-rp-effort',
        p_Service: 'RD026',
        p_EmplID: this.employeeID,
        p_FromYYYYMM: fromYYYYMM,
        p_ToYYYYMM: toYYYYMM
      }
      this.data.error = null
      ReportService.get(report_opts, this.$store).then(data => {
        if ('error' in data) {
          that.data.error = data.error
          if (that.data.error === 'No Data Available') {
            that.data.error = 'No Data In Last 3 Years or No Security Access'
          }
        } else {
          // hide columns
          that.store.columns = data.headers
          that.store.rows = data.rows
          if (that.$route.params.netid === 'wildcat') {
            that.store.rows.forEach(row => {
              var fake_number
              var fake_percent
              if (Math.random() > 0.75) {
                fake_number = 500.42 + (Math.round(Math.random() * 25000))
                fake_percent = 0.42
              } else {
                fake_number = 0
                fake_percent = 0
              }
              row['PayrollAmount'] = fake_number
              row['PercentOfSalary'] = fake_percent
            })
          }

          var seen = []
          var prjs = that.store.rows.filter(row => {
            var code = row['ProjectID']
            if (seen.indexOf(code) >= 0) {
              return false
            } else {
              seen.push(code)
              return true
            }
          })
          that.prjs = prjs
          // @TODO: pre-populate this.
          that.selectDate()
        }
        that.data.loading = false
        timer.tick('loaded in ')
        that.data.performance = timer.toString()
      }).catch(err => {
        that.data.loading = false
        console.log('Unable to parse the results', err)
      })
    }
  }
}
</script>
<style lang="scss" />
