<template>
  <div>
    <div class="actions" />

    <div v-show="!hideTable">
      <mytable
        title="Cost Share Detail"
        class="cost-share"
        v-bind:columns="data.columns"
        v-bind:dropdowndata="selectdata"
        v-bind:store="data.rows"
        v-bind:labels="data.labels"
        v-bind:loading="data.loading"
        v-bind:performance="data.performance"
        v-bind:error="data.error"
        @update-selectdata="selectOpt"
      />
    </div>

    <div v-show="hideTable">
      <costsharedetails
        title=""
        class="cost-share-details"
        v-bind:back="{text:'Cost Share'}"
      />
    </div>
  </div>
</template>

<script>

import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import costsharedetails from './CostShareDetails.vue'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    costsharedetails: costsharedetails,
    mytable: mytable
  },
  data () {
    return {
      hideTable: false,
      store: {
        rows: []
      },
      selectdata: {
        options: [
          { text: 'View all', value: '0'},
          { text: 'Over the Cap', value: '192' },
          { text: 'Mandatory', value: '191'},
          { text: 'Voluntary', value: '193'}
        ],
        idx: 0
      },
      data: {
        loading: false,
        columns: ['FundDescription', 'AccountCategoryDescription', 'Budget', 'Actual', 'Encumbered', 'Balance'],
        rows: [],
        labels: {}
      }
    }
  },
  mounted () {
    if (this.$route.params.netid === 'wildcat') {
      this.project_id = Wildcat.sponsored.PROJECT_ID
    } else {
      this.project_id = this.$route.params.id
    }
    this.load()
  },
  methods: {
    formatLabel (key) {
      var selection = 'SponsoredProjectCostShare'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'SponsoredProjectCostShare'
      return Dictionary.formatTitle(key, selection)
    },
    selectOpt (value) {
      this.selectMode(value)
    },
    selectMode (mode) {
      var valid_modes = ['191', '192', '193']
      if (mode === '0') {
        this.data.rows = this.store.rows.map(it => it)
      } else if (valid_modes.indexOf(mode) >= 0) {
        this.data.rows = this.store.rows.filter(it => {
          return it['FundCode'] === mode
        })
      } else {
        console.log('Unknown mode selected', mode)
      }
    },
    selectRow (row) { console.debug('nothing to do') },

    load () {
      var timer = new Timer()
      var that = this

      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD009',
        p_PrjID: this.project_id
      }

      this.data.loading = true

      ReportService.get(report_opts, this.$store).then(data => {
        that.data.loading = false
        if ('error' in data) {
          that.data.error = data.error
        } else {
          that.data.rows = data.rows
          // Demo user
          if (this.$route.params.netid === 'wildcat') {
            that.data.rows.forEach(row => {
              var budget = 75000.42 + Math.round(Math.random() * 75000)
              var actual = 500.42 + Math.round(Math.random() * 5000)
              var encumbered = 500.42 + Math.round(Math.random() * 5000)
              row['Budget'] = budget
              row['Actual'] = actual
              row['Encumbered'] = encumbered
              row['Balance'] = budget - actual - encumbered
            })
          }
          that.store.rows = that.data.rows.map(it => it)
        }
        timer.tick('loaded in ')
        that.data.performance = timer.toString()
      }).catch(err => {
        console.error('Unable to parse the results', err)
      })
    }
  }
}
</script>
<style lang="scss">
.cost-share {
  table {
    td {
      cursor: default;
    }
  }
}
</style>
