var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("feedback"),
      _c("header", [
        _c("nav", { staticClass: "top-nav" }, [
          _c("div", { staticClass: "container" }, [
            _vm._m(0),
            !_vm.AuthService.authenticated
              ? _c("div", { staticClass: "user-menu" }, [
                  _c(
                    "a",
                    {
                      staticClass: "user-login-button",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.login()
                        },
                      },
                    },
                    [_vm._v("Login")]
                  ),
                ])
              : _vm._e(),
            _vm.AuthService.authenticated
              ? _c("div", { staticClass: "user-menu" }, [
                  _c("li", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "on-clickaway",
                            rawName: "v-on-clickaway",
                            value: _vm.foo,
                            expression: "foo",
                          },
                        ],
                        staticClass: "usercard-wrapper-button",
                        on: {
                          click: function ($event) {
                            _vm.user_profile_expanded =
                              !_vm.user_profile_expanded
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "left" },
                          [
                            _vm.AuthService.getUser().data.jpegPhoto
                              ? [
                                  _c("img", {
                                    staticClass: "user-profile-picture x-small",
                                    attrs: {
                                      src:
                                        "data:image/jpeg;base64," +
                                        _vm.AuthService.getUser().data
                                          .jpegPhoto,
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c("div", { staticClass: "right" }, [
                          _c("p", { staticClass: "name" }, [
                            _vm._v(
                              _vm._s(
                                _vm.AuthService.getUser().data.displayName[0]
                              )
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm.user_profile_expanded
                      ? _c(
                          "div",
                          {
                            staticClass: "user-dropdown-menu",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c(
                              "transition-group",
                              { attrs: { name: "expand" } },
                              [
                                _c(
                                  "div",
                                  { key: "up1", staticClass: "user-snippet" },
                                  [
                                    _vm.AuthService.getUser().data.jpegPhoto
                                      ? [
                                          _c("img", {
                                            key: "up2",
                                            staticClass:
                                              "user-profile-picture medium",
                                            attrs: {
                                              src:
                                                "data:image/jpeg;base64," +
                                                _vm.AuthService.getUser().data
                                                  .jpegPhoto,
                                            },
                                          }),
                                        ]
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        key: "up3",
                                        staticClass: "user-name-email",
                                      },
                                      [
                                        _c(
                                          "h3",
                                          { key: "up4", staticClass: "title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.AuthService.getUser().data
                                                  .displayName[0]
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          { key: "up5", staticClass: "email" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.AuthService.getUser().data
                                                  .mail
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "ul",
                                  {
                                    key: "up6",
                                    staticClass: "user-dropdown-menu-actions",
                                  },
                                  [
                                    _c(
                                      "li",
                                      { key: "up7", staticClass: "actions" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "rd-small-button user_profile",
                                            attrs: { to: "/profile" },
                                          },
                                          [_vm._v("My Profile")]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            key: "up8",
                                            staticClass:
                                              "rd-small-button user_logout",
                                            staticStyle: { cursor: "pointer" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.AuthService.logout()
                                              },
                                            },
                                          },
                                          [_vm._v("Signout")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        { staticClass: "help tab", attrs: { to: "/help" } },
                        [_vm._v("HELP")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        { staticClass: "policy tab", attrs: { to: "/policy" } },
                        [_vm._v("POLICY")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("nav", { staticClass: "bottom-nav" }, [
          _c("div", { staticClass: "container" }, [
            _vm.AuthService.authenticated
              ? _c("div", { staticClass: "main-nav" }, [
                  _c("ul", { staticClass: "nav" }, [
                    _vm.pi_netid &&
                    _vm.AuthService.authenticated &&
                    _vm.AuthService.getUser().data.RD014_ROLE.role === "STAFF"
                      ? _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "pi tab",
                                attrs: { to: "/investigators" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.resetWasWildcat()
                                  },
                                },
                              },
                              [_vm._v("RETURN TO INVESTIGATORS")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.pi_netid
                      ? _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "project tab",
                                attrs: {
                                  to: {
                                    name: "Sponsored_Projects",
                                    params: { netid: _vm.pi_netid },
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.project_label))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.pi_netid
                      ? _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "people tab",
                                attrs: {
                                  to: {
                                    name: "PIheader",
                                    params: { netid: _vm.pi_netid },
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.people_label))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.AuthService.getUser().data.RD014_ROLE.role === "STAFF"
                      ? _c("li", [
                          _c("div", { staticClass: "search tab" }, [
                            _c(
                              "button",
                              {
                                staticClass: "rd-search",
                                attrs: { id: "show-modal" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openModal()
                                  },
                                },
                              },
                              [_vm._v("project quick access")]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "container mobile-only" }, [
            _vm._m(1),
            _c("button", {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: _vm.boo,
                  expression: "boo",
                },
              ],
              staticClass: "mobile-menu-icon mobile-only",
              class: { active: _vm.mobile_menu_expanded },
              on: {
                click: function ($event) {
                  _vm.mobile_menu_expanded = !_vm.mobile_menu_expanded
                },
              },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.mobile_menu_expanded,
                    expression: "mobile_menu_expanded",
                  },
                ],
                staticClass: "mobile-menu-dropdown mobile-only",
              },
              [
                _vm.AuthService.authenticated
                  ? _c("ul", { staticClass: "nav" }, [
                      _vm.AuthService.authenticated &&
                      _vm.AuthService.getUser().data.RD014_ROLE.role ===
                        "STAFF" &&
                      _vm.pi_netid
                        ? _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "pi tab",
                                  attrs: { to: "/investigators'" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.resetWasWildcat()
                                    },
                                  },
                                },
                                [_vm._v("RETURN TO INVESTIGATORS")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.pi_netid
                        ? _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "project tab",
                                  attrs: {
                                    to: {
                                      name: "Sponsored_Projects",
                                      params: { netid: _vm.pi_netid },
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.project_label))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.pi_netid
                        ? _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "people tab",
                                  attrs: {
                                    to: {
                                      name: "PIheader",
                                      params: { netid: _vm.pi_netid },
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.people_label))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "li",
                        [
                          _c("router-link", { attrs: { to: "/help" } }, [
                            _vm._v("HELP"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c("router-link", { attrs: { to: "/policy" } }, [
                            _vm._v("POLICIES"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c("router-link", { attrs: { to: "/profile" } }, [
                            _vm._v("My Profile"),
                          ]),
                        ],
                        1
                      ),
                      _c("li", [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.AuthService.logout()
                              },
                            },
                          },
                          [_vm._v("Signout")]
                        ),
                      ]),
                      _vm.AuthService.getUser().data.RD014_ROLE.role === "STAFF"
                        ? _c("li", [
                            _c("div", { staticClass: "search tab" }, [
                              _c(
                                "button",
                                {
                                  attrs: { id: "show-modal" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.openModal()
                                    },
                                  },
                                },
                                [_vm._v("project quick access")]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                !_vm.AuthService.authenticated
                  ? _c("ul", { staticClass: "nav navbar-nav" }, [
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "user-login-button",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.login()
                              },
                            },
                          },
                          [_vm._v("Login")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
      ]),
      _vm.search_modal_expanded
        ? _c(
            "div",
            { staticClass: "modal-mask" },
            [
              _c("transition", { attrs: { name: "modal" } }, [
                _c("div", { staticClass: "modal-wrapper" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "on-clickaway",
                          rawName: "v-on-clickaway",
                          value: _vm.myClickAway,
                          expression: "myClickAway",
                        },
                      ],
                      staticClass: "modal-container",
                    },
                    [
                      _c("div", { staticClass: "modal-header" }, [
                        _c("h2", [_vm._v("Enter Project ID")]),
                        _c(
                          "div",
                          {
                            staticClass: "modal-close",
                            on: { click: _vm.close },
                          },
                          [_vm._v("Close")]
                        ),
                      ]),
                      _c("div", { staticClass: "modal-body search" }, [
                        _c("div", { staticClass: "search tab" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.search.query,
                                expression: "search.query",
                              },
                            ],
                            staticClass: "search-input",
                            attrs: {
                              type: "number",
                              placeholder: "Search by project ID",
                            },
                            domProps: { value: _vm.search.query },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.searchbyprj.apply(null, arguments)
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.search,
                                  "query",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _c("p", { staticClass: "small purple" }, [
                          _vm._v(
                            "For sponsored projects, you will be taken directly to the project within its primary PI's portfolio."
                          ),
                        ]),
                        _c("p", { staticClass: "small purple" }, [
                          _vm._v(
                            "For non-sponsored projects, you will be taken directly to its project manager's portfolio where you can select the project if you have the appropriate security access."
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c("span", { staticClass: "rd-submit-status" }, [
                          _vm._v(_vm._s(_vm.searchtext)),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "rd-small-button search",
                            class: { submitted: _vm.validated == 1 },
                            on: { click: _vm.searchbyprj },
                          },
                          [_vm._v("Search")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "rd-small-button feed_close",
                            on: { click: _vm.close },
                          },
                          [_vm._v("Close")]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("div", {
        staticClass: "home-notification",
        attrs: { id: "iframe_holder_home" },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.AuthService.authenticated &&
                _vm.AuthService.showAnnouncements &&
                _vm.showAnnouncements,
              expression:
                "AuthService.authenticated && AuthService.showAnnouncements && showAnnouncements",
            },
          ],
          attrs: { id: "announcements" },
        },
        [
          _c(
            "div",
            {
              staticClass: "wp-notification modal-header",
              staticStyle: { position: "relative" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-close",
                  on: {
                    click: function ($event) {
                      return _vm.toggleAnnouncement()
                    },
                  },
                },
                [_vm._v("X")]
              ),
              _c("h4", [_vm._v("Data Usage Policies")]),
              _c("p", [
                _vm._v(
                  "Research Portal now contains salary data and student data."
                ),
              ]),
              _vm._m(2),
              _vm._m(3),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          _vm._m(4),
          _c("div", { staticStyle: { clear: "both" } }),
          _c("router-view"),
        ],
        1
      ),
      _c("div", { attrs: { id: "footer" } }, [
        _c("div", { staticClass: "container" }, [_c("my-links")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "branding" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Research Dashboard")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "branding" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Research Dashboard")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("For the appropriate use of salary data, please refer to the "),
      _c("b", [
        _c("u", [
          _c(
            "a",
            {
              attrs: {
                href: "http://www.northwestern.edu/myhr/admin/salary-access-policy.html",
                target: "_blank",
              },
            },
            [_vm._v("Salary Access Policy")]
          ),
        ]),
      ]),
      _vm._v("."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("For the appropriate use of student data, please refer to the "),
      _c("b", [
        _c("u", [
          _c(
            "a",
            {
              attrs: {
                href: "http://www.registrar.northwestern.edu/academic_records/FERPA_policy.html",
                target: "_blank",
              },
            },
            [_vm._v("FERPA Policy")]
          ),
        ]),
      ]),
      _vm._v("."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "scrolltotop",
        staticStyle: { bottom: "-50px" },
        attrs: {
          "data-start": "bottom: -50px;",
          "data-10-start": "bottom: 25px;",
          "data-150-end": "bottom: 25px;",
          "data-end": "bottom:105px;",
          "data-anchor-target": "body",
        },
      },
      [_c("a", { attrs: { onclick: "scroll(0,0)" } }, [_vm._v("Top")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }