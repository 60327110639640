<template>
  <keep-alive>
    <div>
      <mytable
        v-bind:title="title"
        class="people-salary"
        v-bind:datepickerdata="datepickerdata"
        v-bind:columns="data.columns"
        v-bind:store="data.rows"
        v-bind:sortkey="'EmployeeName'"
        v-bind:sortdir="1"
        v-bind:sortorders="{'EmployeeName':1}"
        v-bind:footer_rows="data.footer_rows"
        v-bind:labels="data.labels"
        v-bind:loading="data.loading"
        v-bind:performance="data.performance"
        v-bind:error="data.error"
      />
      <small>* Earned monthly payroll amount and percent of base salary. Shows when pay was earned not when paycheck or journal was processed. See Help for more information.</small>
    </div>
  </keep-alive>
</template>

<script>
import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {DateUtils, Dictionary, Timer} from '../../../utils/'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    mytable: mytable
  },
  data () {
    return {
      title: 'PEOPLE PAID IN LAST FIVE YEARS',
      project_id: 0,
      prjs: [],
      offset_n: 5,
      data: {
        columns: [],
        rows: [],
        error: '',
        labels: {},
        loading: false
      },
      // Store the pivot data source here. Render the contents into the regular data.rows and data.cols
      store: {
        columns: [],
        rows: [],
        footer_rows: []
      },

      datepickerdata: {
        years: DateUtils.getLastYears(5),
        months: DateUtils.MONTHS,
        selectedYear: 0,
        selectedMonth: 0
      },
      selectedYear: 0,
      selectedMonth: 0,
      range: {
        start: {
          year: 2016,
          month: 1
        },
        end: {
          year: 2016,
          month: 4
        }
      },
      blankresult: null,
      wasDemo: false
    }
  },
  mounted () {
    this.wasDemo = localStorage.getItem('prevWasWildcat')
    // Demo User Wildcat -- override
    // if (this.$route.params.netid === 'wildcat') {
    if (this.wasDemo === 'true') {
      this.netid = Wildcat.effort.sponsored.NETID
      if (this.$route.name === 'Sponsored_People') {
        this.project_id = Wildcat.effort.nonsponsored.PROJECT_ID.split('-')[2]
        this.offset_n = 3
        this.datepickerdata.years = DateUtils.getLastYears(3)
        this.title = 'PEOPLE PAID IN LAST THREE YEARS'
      } else {
        this.project_id = Wildcat.effort.sponsored.PROJECT_ID
      }
    } else {
      this.netid = this.$route.params.netid
      if (this.$route.name === 'Sponsored_People') {
        this.project_id = this.$route.params.cs.split('-')[2]
        this.offset_n = 3
        this.datepickerdata.years = DateUtils.getLastYears(3)
        this.title = 'PEOPLE PAID IN LAST THREE YEARS'
      } else {
        this.project_id = this.$route.params.id
      }
      // this.load()
    }

    // calculate initial date.
    var now = new Date()
    var yyyy = now.getFullYear()
    var m = now.getMonth()
    var offset_months = 0
    var d = DateUtils.addMonths(yyyy, m, offset_months)
    var year_idx = this.datepickerdata.years.indexOf(d[0])
    this.datepickerdata.selectedYear = year_idx
    this.datepickerdata.selectedMonth = d[1]

    this.load()
  },
  methods: {
    formatLabel (key) {
      var selection = 'PayrollByMonthForProject'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'PayrollByMonthForProject'
      return Dictionary.formatTitle(key, selection)
    },
    selectDate (yyyy, mm) {
      if (yyyy === undefined || mm === undefined) {
        // eslint-disable-next-line no-redeclare
        var yyyy = this.datepickerdata.years[this.datepickerdata.selectedYear]
        // eslint-disable-next-line no-redeclare
        var mm = this.datepickerdata.selectedMonth // 0..11
      }
      this.view(yyyy, mm)
    },
    addMonth (n_months) {
      var new_month = this.selectedMonth + n_months
      if (new_month >= 12) {
        this.selectedMonth = 0
        this.selectedYear++
      } else {
        this.selectedMonth = new_month
      }
      if (new_month < 0) {
        this.selectedMonth = 11
        this.selectedYear--
      }
      this.selectDate()
    },
    pad (value, length) {
      var res = (value.toString().length < length) ? this.pad('0' + value, length) : value
      return res
    },
    view (yyyy, mm) {
      // Get date from user input
      if (this.store.rows.length < 1 || this.data.error) {
        this.blankresult = 'No rows found'
        console.log('No rows found')
        return
      }

      // align to fiscal dates
      var d = DateUtils.addMonths(yyyy, mm, +1)
      // eslint-disable-next-line no-redeclare
      var yyyy = d[0]
      // eslint-disable-next-line no-redeclare
      var mm = d[1]
      // set cols, rows
      var columns = []
      this.data.columns = ['EmployeeName', 'FundCode', 'Account']
      var n_months = 3
      // generate column keys for the selected date.
      for (var i = 0; i < n_months; i++) {
        var offset = 1 - n_months + i
        // eslint-disable-next-line no-redeclare
        var d = DateUtils.addMonths(yyyy, mm, offset)
        // var date_str = d[0].toString() + this.pad(d[1], 2);
        var date_str = d[0] + ' - ' + this.pad(d[1], 2)
        columns.push(date_str)

        // var date_d = DateUtils.MONTHS[this.pad(d[1], 2)-1] + " " + d[0] + " ( $ )";
        // this.data.columns.push(date_d)
        this.data.columns.push(date_str)
      }

      var prj_ids = {}
      this.store.rows.forEach(row => {
        var uniqueID = row['EmployeeID'] + row['Account'] + row['FundCode'] + row['FinancialDeptID']
        if (Object.keys(prj_ids).indexOf(uniqueID) < 0) {
          prj_ids[uniqueID] = {
            EmployeeName: row['EmployeeName'],
            EmployeeJobTitle: row['EmployeeJobTitle'],
            EmployeeHRDeptName: row['EmployeeHRDeptName'],
            FundCode: row['FundCode'],
            Account: row['AccountDescription']
          }
          if (row['SalaryRestricted'] === 'Y') {
            prj_ids[uniqueID].SalaryRestricted = true
          }
          // prj_ids.push(value);
        }
      })
      // generate rows.
      var rows = Object.keys(prj_ids).map(prj => {
        var list = []
        var temp = ''
        this.store.rows.forEach(row => {
          var sel_prj = row['EmployeeID'] + row['Account'] + row['FundCode'] + row['FinancialDeptID'] === prj
          var temp = row['YYYYMM']
          temp = temp.substring(0, 4) + ' - ' + temp.substring(4, 6)
          var sel_date = columns.indexOf(temp) >= 0
          if (sel_prj && sel_date) {
            list.push(row)
          }
        })
        var new_row = {}
        // new_row['Account_EmployeeID'] = prj;

        columns.forEach(dt => {
          // var tm = DateUtils.MONTHS[parseInt(dt.substring(4)) - 1] + " " + dt.substring(0,4)
          var dkey = dt
          var dval = 0
          var pval = 'N/A'
          list.some(row => {
            temp = row['YYYYMM']
            temp = temp.substring(0, 4) + ' - ' + temp.substring(4, 6)
            if (temp === dt) {
              dval = row['PayrollAmount']
              if (row['PercentOfSalary'] && row['PercentOfSalary'] !== 'null') {
                pval = Math.round(row['PercentOfSalary'] * 100)
              }
              return true
            }
            return false
          })
          if (pval === 'N/A') {
            new_row[dkey] = dval
          } else {
            new_row[dkey] = dval + ' (' + pval + '%)'
          }
        })
        if (prj_ids[prj]['SalaryRestricted']) {
          Object.keys(new_row).forEach(datekeys => {
            new_row[datekeys] = 'SalaryRestricted'
          })
          new_row['SalaryRestricted'] = true
        }
        new_row['EmployeeName'] = prj_ids[prj]['EmployeeName']
        new_row['FundCode'] = prj_ids[prj]['FundCode'] === 'null' ? '' : prj_ids[prj]['FundCode']
        new_row['Account'] = prj_ids[prj]['Account']

        return new_row
      })
      this.data.rows = rows
    },
    load () {
      var timer = new Timer()
      this.data.loading = true
      var that = this

      // calculate the date range to retrieve
      var now = new Date()
      var toYear = now.getFullYear()
      var toMonth = now.getMonth() + 1
      // add 4 to align with the fiscal period
      var out_d = DateUtils.addMonths(toYear, toMonth, 0)
      toYear = out_d[0]
      toMonth = out_d[1]

      var d = DateUtils.addMonths(toYear, toMonth, -12 * this.offset_n) // 3 years
      var fromYYYYMM = '' + d[0] + this.pad(d[1], 2)
      var toYYYYMM = '' + toYear + this.pad(toMonth, 2)

      var report_opts = {
        report_name: 'era-rp-effort',
        p_Service: 'RD025',
        p_PrjID: this.project_id,
        p_FromYYYYMM: fromYYYYMM,
        p_ToYYYYMM: toYYYYMM
      }
      this.data.error = null
      ReportService.get(report_opts, this.$store).then(data => {
        if ('error' in data) {
          that.data.error = data.error
          if (that.data.error === 'No Data Available') {
            if (this.$route.name === 'Sponsored_People') {
              that.data.error = 'No Data In Last 3 Years or No Security Access'
            } else {
              that.data.error = 'No Data In Last 5 Years or No Security Access'
            }
          }
        } else {
          // hide columns
          that.store.columns = data.headers
          that.store.rows = data.rows

          // Fake user demo
          if (that.$route.params.netid === 'wildcat') {
            that.store.rows.forEach(row => {
              var fake_number
              var fake_percent
              if (Math.random() > 0.75) {
                fake_number = 500.42 + (Math.round(Math.random() * 25000))
                fake_percent = 0.42
              } else {
                fake_number = 0
                fake_percent = 0
              }
              row['PayrollAmount'] = fake_number
              row['PercentOfSalary'] = fake_percent
            })
          }

          // @TODO: pre-populate this.
          that.selectDate()
        }
        that.data.loading = false
        timer.tick('loaded in ')
        that.data.performance = timer.toString()
      }).catch(err => {
        that.data.loading = false
        console.log('Unable to parse the results', err)
      })
    }
  }
}
</script>
<style lang="scss" />
