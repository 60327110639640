<template>
  <keep-alive>
    <div class="sponsored-projects">
      <mytable
        keep-alive
        title="Sponsored Projects"
        v-bind:toggledata="selectdata"
        v-bind:columns="data.columns"
        v-bind:store="data.rows"
        v-bind:labels="data.labels"
        v-bind:sortkey="'ProjectID'"
        v-bind:sortdir="1"
        v-bind:sortorders="{'ProjectID':1}"
        v-bind:loading="data.loading"
        v-bind:performance="data.performance"
        v-bind:error="data.error"
        @update-selectdata="selectOpt"
      />
    </div>
  </keep-alive>
</template>

<script>

import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    mytable: mytable
  },
  data () {
    return {
      selectdata: {
        options: [
          { text: 'Principal Investigator', value: 'pi-projects', class: 'pi-projects'},
          { text: 'All Projects', value: 'all-projects', class: 'all-projects'}
        ],
        idx: 0
      },
      store: {
        rows: []
      },
      data: {
        columns: ['ProjectName', 'PrimaryRole', 'PrimaryProjectPIName', 'ProjectID', 'Balance', 'Sponsor', 'ProjectEndDate'],
        rows: [],
        performance: '',
        loading: true,
        error: null,
        netid: null
      }
    }
  },
  mounted () {
    // Implement fake user for demo
    if (this.$route.params.netid === 'wildcat') {
      this.data.netid = Wildcat.sponsored.NETID
    } else {
      this.data.netid = this.$route.params.netid // @TODO: obsolete??
    }

    var timer = new Timer()
    var that = this
    var report_opts = {
      report_name: 'era-rp-main',
      p_Invest_ID: (this.data.netid + '').toUpperCase(),
      p_Service: 'RD003'
    }
    ReportService.get(report_opts, this.$store).then(data => {
      that.data.loading = false
      if ('error' in data) {
        that.data.error = data.error
      } else {
        // that.data.columns = out.headers;
        if (that.$route.params.netid === 'wildcat') {
          that.store.rows = data.rows.map(row => {
            row['Balance'] = 500.42 + Math.round(Math.random() * 250000)
            row['ProjectID'] = '421' + 1000 + (Math.round(Math.random() * 1000))
            return row
          })
        } else {
          that.store.rows = data.rows.map(row => {
            if ('ProjectPIName' in row) {
              row['ProjectPIName'] = row['ProjectPIName'].replace(',', ', ')
            }
            if ('PrimaryProjectPIName' in row) {
              row['PrimaryProjectPIName'] = row['PrimaryProjectPIName'].replace(',', ', ')
            }
            return row
          })

        }
        that.data.rows = that.store.rows
        that.selectOpt('pi-projects')
      }
      timer.tick('loaded in ')
      that.data.performance = timer.toString()
    })
  },
  methods: {
    formatLabel (key) {
      var selection = 'SponsoredProjects'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'SponsoredProjects'
      return Dictionary.formatTitle(key, selection)
    },
    selectRow (row) {
      var id = (this.data.netid === Wildcat.sponsored.NETID && this.$route.params.netid === 'wildcat') ? Wildcat.sponsored.PROJECT_ID : row.ProjectID
      var netid = ''
      if (this.data.netid === Wildcat.sponsored.NETID && this.$route.params.netid === 'wildcat') {
        netid = 'wildcat'
      } else {
        netid = this.data.netid
      }
      var destination = { name: 'Project_Sponsored_InceptionToDate', params: { id: id, netid: netid } }
      if (this.$route.params.netid === 'wildcat') {
        localStorage.setItem('prevWasWildcat', 'true')
      } else {
        localStorage.setItem('prevWasWildcat', (this.$route.params.netid === 'wildcat'))
      }
      this.$router.push(destination)
    },
    selectOpt (value) {
      if (value === 'all-projects') {
        this.selectdata.idx = 1
        this.data.rows = this.store.rows
      } else {
        this.selectdata.idx = 0
        var rows = []
        this.store.rows.forEach(row => {
          if (row['ProjectPIFlag'] === 'Y') {
            rows.push(row)
          }
        })
        this.data.rows = rows
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.sponsored-projects {
  table {
    tbody {
      tr {
        td {
          // THIS IS IMPORTANT. This table does not drill-down!
          cursor: pointer;
        }
      }
    }
  }
}
</style>
