
import Projects from './Projects.vue'
import NonSponsored from './NonSponsored.vue'
import Overview from './Overview.vue'

import FiscalYearToDate from './FiscalYearToDate.vue'
import FiscalYearToDateDetails from './FiscalYearToDateDetails.vue'

import CostShare from './CostShare.vue'
import People from '../sponsored/People.vue'

export default {
  Projects: Projects,
  NonSponsored: NonSponsored,
  Overview: Overview,
  FiscalYearToDate: FiscalYearToDate,
  FiscalYearToDateDetails: FiscalYearToDateDetails,
  CostShare: CostShare,
  People: People
}
