<template>
  <keep-alive>
    <div class="non-sponsored-projects">
      <mytable
        title="Non-Sponsored Projects"
        v-bind:columns="data.columns"
        v-bind:store="data.rows"
        v-bind:labels="data.labels"
        v-bind:loading="data.loading"
        v-bind:performance="data.performance"
        v-bind:error="data.error"
      />
    </div>
  </keep-alive>
</template>

<script>
import mytable from '../../../components/_mytable.vue'
import {ReportService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    mytable: mytable
  },
  data () {
    return {
      data: {
        columns: ['ProjectName', 'CHARTSTRING', 'Balance'],
        rows: [],
        performance: '',
        loading: true,
        error: null,
        netid: ''
      }
    }
  },
  mounted () {
    if (this.$route.params.netid === 'wildcat') {
      console.warn('Using fake user')
      this.data.netid = Wildcat.nonsponsored.NETID
    } else {
      this.data.netid = this.$route.params.netid
    }

    var that = this
    var timer = new Timer()
    var report_opts = {
      report_name: 'era-rp-main',
      p_Invest_ID: (this.data.netid + '').toUpperCase(),
      p_Service: 'RD002'
    }

    ReportService.get(report_opts, this.$store).then(data => {
      that.data.loading = false
      // that.data.columns = data.headers;
      that.data.rows = (data.rows || []).map((row) => {
        row.CHARTSTRING = [row.FundCode, row.DepartmentID, row.ProjectID].join('-')

        // Demo User Wildcat -- override values
        if (that.$route.params.netid === 'wildcat') {
          row['CHARTSTRING'] = '123-1234567-12345678'
          row['ProjectName'] = 'PI Wildcat Discretionary'
          row['Balance'] = 500.42 + Math.round(Math.random() * 250000)
        }
        return row
      })
      if ('error' in data) {
        that.data.error = data.error
      }
      timer.tick('loaded in ')
      that.data.performance = timer.toString()
    })
  },
  methods: {
    formatLabel (key) {
      var selection = 'NonSponsoredProjects'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'NonSponsoredProjects'
      return Dictionary.formatTitle(key, selection)
    },
    selectRow (row) {
      var destination = { name: 'NonSponsored_FiscalYearToDate', params: { netid: this.data.netid, cs: row.CHARTSTRING } }
      localStorage.setItem('prevWasWildcat', (this.$route.params.netid === 'wildcat'))
      this.$router.push(destination)
    }
  }
}
</script>
<style lang="scss">
.non-sponsored-projects {
  table {
    tbody {
      tr {
        td {cursor: pointer}
      }
    }
  }
}
</style>
