import { render, staticRenderFns } from "./PeopleListing.vue?vue&type=template&id=7f265236"
import script from "./PeopleListing.vue?vue&type=script&lang=js"
export * from "./PeopleListing.vue?vue&type=script&lang=js"
import style0 from "./PeopleListing.vue?vue&type=style&index=0&id=7f265236&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/ADO-ResearchPortal/ADO-ResearchPortal/files-dash/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7f265236')) {
      api.createRecord('7f265236', component.options)
    } else {
      api.reload('7f265236', component.options)
    }
    module.hot.accept("./PeopleListing.vue?vue&type=template&id=7f265236", function () {
      api.rerender('7f265236', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/people/PeopleListing.vue"
export default component.exports