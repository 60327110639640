<template>
  <div>
    <mytable
      title=""
      class="non-sponsored"
      v-bind:hidesearch="true"
      v-bind:hidesort="true"
      v-bind:columns="dataTop.columns"
      v-bind:store="dataTop.rows"
      v-bind:labels="dataTop.labels"
      v-bind:loading="dataTop.loading"
      v-bind:performance="dataTop.performance"
      v-bind:error="dataTop.error"
    />

    <div v-show="!hideTable">
      <mytable
        v-bind:title="bottomTitle"
        v-bind:toggledata="selectdataFY"
        v-bind:columns="dataBottom.columns"
        v-bind:store="dataBottom.rows"
        v-bind:footer_rows="dataBottom.footer_rows"
        v-bind:labels="dataBottom.labels"
        v-bind:sortkey="'AccountCode'"
        v-bind:sortdir="1"
        v-bind:sortorders="{'AccountCode':1}"
        v-bind:loading="dataBottom.loading"
        v-bind:performance="dataBottom.performance"
        v-bind:error="dataBottom.error"
        @update-selectdata="selectOpt"
      />
    </div>

    <div v-show="hideTable">
      <fiscalyeartodatedetails
        title=""
        class="inception-to-date-details"
        v-bind:back="{text:'Fiscal Year to Date'}"
      />
    </div>
  </div>
</template>

<script>
import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import fiscalyeartodatedetails from './FiscalYearToDateDetails.vue'
import Wildcat from '../Wildcat.json'
export default {
  components: {
    mytable: mytable,
    fiscalyeartodatedetails: fiscalyeartodatedetails
  },
  data () {
    return {
      // This Determines what appears in the top and bottom tables
      hideTable: false,
      ignored_keys: ['AccountDescription', 'CurrentExpenseBudget', 'ExpenseBudgetBalance'],
      MAX_ACCOUNT_CATEGORY_CODE: 1000,
      categories: [],
      bottomTitle: 'fiscal year to date',
      selectdataFY: {
        options: [
          { text: 'Previous fiscal year', value: 'pfy-data', class: 'pfy-data'},
          { text: 'Fiscal Year to Date', value: 'fyd-data', class: 'fyd-data'}
        ],
        idx: 1
      },
      dataTop: {
        loading: false,
        columns: ['AccountDescription', 'RevenueLessActual', 'NetBudget', 'FYTDPreEncEncExpense', 'NetBalance'],
        rows: [
          {
            AccountDescription: 'Total',
            RevenueLessActual: null,
            NetBalance: null,
            NetBudget: null,
            FYTDPreEncEncExpense: null
          }
        ],
        labels: {PRJ_ID: 'Project #'}
      },
      dataBottom: {
        loading: false,
        columns: ['AccountDescription', 'CurrentExpenseBudget', 'FYTDActualExpense', 'FYTDPreEncEncExpense', 'ExpenseBudgetBalance'],
        rows: [],
        footer_rows: [],
        labels: {PRJ_ID: 'Project #'}
      },
      dataBottomStore: {
        rows: []
      },
      store: {
        columns: [],
        rows: []
      },
      selectdata: {
        options: [{text: 'Fiscal Year to Date', value: 'fiscal-year-to-date'}],
        idx: 0
      },
      details: {
        selectdata: {
          options: [],
          idx: 0
        }
      }
    }
  },
  watch: {
    '$parent.$parent.$data': {
      handler: function (newData, oldVal) {
        // NOTE! Cognos is sending the data for the top table in the RD004 (header service)
        // RD004 returns a 'table' with headers but rows are reduced to one single row of data (via .pop in NonSponsored.vue)
        if (!newData.data.rows) { return }

        var row = newData.data.rows
        this.dataTop.rows[0]['RevenueLessActual'] = row['RevenueLessActual']
        this.dataTop.rows[0]['NetBalance'] = row['NetBalance']
        this.dataTop.rows[0]['NetBudget'] = row['NetBudget']
      },
      deep: true
    }
  },
  mounted () {
    var wasDemo = localStorage.getItem('prevWasWildcat')
    if (wasDemo === 'true') {
      this.project_id = Wildcat.nonsponsored.PROJECT_ID
    } else {
      this.project_id = this.$route.params.cs
    }
    this.load()
  },
  methods: {
    formatLabel (key) {
      var selection = 'NonSponsoredProjectFinancials'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'NonSponsoredProjectFinancials'
      return Dictionary.formatTitle(key, selection)
    },
    select (row) {
      console.log('CLICK')
    },
    delegateClick (row, key, $event) {
      if (this.ignored_keys.indexOf(key) >= 0) {
        console.log('nothing to do')
        return 0
      }
      if (row.AccountDescription !== 'Total') {
        this.hideTable = true
        // format the categories into selectdata format
        var options = this.categories.map(cat => {
          return {
            text: cat['AccountDescription'],
            value: cat['AccountCode'],
            key: key
          }
        })
        // find the IDX for the current selection.
        var list_codes = options.map(_row => _row.text)
        var idx = list_codes.indexOf(row['AccountDescription'])
        var key_label = this.formatLabel(key)
        var label = ' (' + key_label + ')'
        // add context to the dropdown option (actual vs encumbered)
        // options = options.map(opt => opt.text += label);
        var filtertype = this.bottomTitle === 'fiscal year to date' ? 'CurrentYear' : 'PreviousYear'
        var options1 = this.categories.map(cat => {
          return {
            text: cat['AccountDescription'] + label,
            value: cat['AccountCode'],
            type: filtertype,
            key: key
          }
        })
        this.details.selectdata.options = options1
        this.details.selectdata.idx = idx
        this.details.selectdata.filterBy = key
      } else {
        console.debug('Ignoring top table click')
      }
    },
    goBack () {
      this.hideTable = false
    },
    load () {
      var timer = new Timer()
      var that = this
      this.dataTop.loading = true
      this.dataBottom.loading = true
      var parts = this.project_id.split('-')
      var fund_code = parts[0]
      var dept_id = parts[1]
      var project_id = parts[2]
      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD005',
        p_PrjID: project_id,
        p_FundCode: fund_code,
        p_DeptID: dept_id
      }
      ReportService.get(report_opts, this.$store).then(data => {
        if ('error' in data) {
          that.dataBottom.error = data.error
        } else {
          // Top Table NOTE! the top table is populate from both RD004 and RD005 !!!!!
          var totalRow = data.rows.filter(row => row.AccountDescription === 'Total').shift()
          this.dataTop.rows[0]['FYTDPreEncEncExpense'] = totalRow['FYTDPreEncEncExpense']

          // bottom table
          that.dataBottomStore.rows = data.rows
          // override numbers for the wildcat user
          var wasDemo = localStorage.getItem('prevWasWildcat')
          // if (this.$route.params.netid === 'wildcat') {
          if (wasDemo === 'true') {
            // that.dataBottom.rows.forEach(row => {
            that.dataBottomStore.rows.forEach(row => {
              ['CurrentExpenseBudget', 'FYTDActualExpense'].forEach(key => {
                var fake_number = 100.42 + Math.round(Math.random() * 2500)
                row[key] = fake_number
              })
              row['ExpenseBudgetBalance'] = (Math.round(row['CurrentExpenseBudget'] - row['FYTDActualExpense']) >= 0)
                ? Math.round(row['CurrentExpenseBudget'] - row['FYTDActualExpense']) + 0.42
                : Math.round(row['CurrentExpenseBudget'] - row['FYTDActualExpense']) + 0.58
            })
            that.dataBottom.footer_rows.forEach(row => {
              ['CurrentExpenseBudget', 'FYTDActualExpense'].forEach(key => {
                var fake_number = 100.42 + Math.round(Math.random() * 2500)
                row[key] = fake_number
              })
              row['ExpenseBudgetBalance'] = (Math.round(row['CurrentExpenseBudget'] - row['FYTDActualExpense']) >= 0)
                ? Math.round(row['CurrentExpenseBudget'] - row['FYTDActualExpense']) + 0.42
                : Math.round(row['CurrentExpenseBudget'] - row['FYTDActualExpense']) + 0.58
            })
          }
          that.selectOpt('fyd-data')
        }
        timer.tick('loaded in ')
        that.dataBottom.performance = timer.toString()
        that.dataTop.loading = false
        that.dataBottom.loading = false
      }).catch(err => {
        that.dataTop.loading = false
        that.dataBottom.loading = false
        console.log('Unable to parse the results', err)
      })
    },
    selectOpt (value) {
      var rows = []
      var totalrow = []
      if (value === 'fyd-data') {
        this.selectdataFY.idx = 1
        this.bottomTitle = 'fiscal year to date'
        this.dataBottomStore.rows.forEach(row => {
          if (row['FYFlag'] === 'CurrentYear') {
            if (row['AccountDescription'] === 'Total') {
              totalrow.push(row)
            } else {
              rows.push(row)
            }
          }
        })
      } else if (value === 'pfy-data') {
        this.selectdataFY.idx = 0
        this.bottomTitle = 'Previous fiscal year'
        this.dataBottomStore.rows.forEach(row => {
          if (row['FYFlag'] === 'PreviousYear') {
            if (row['AccountDescription'] === 'Total') {
              totalrow.push(row)
            } else {
              rows.push(row)
            }
          }
        })
      }
      this.dataBottom.rows = rows
        .map(row => {
          row.editable = false
          row['_clickable'] = true
          return row
        })
      var keys = ['AccountCode', 'AccountDescription']
      var seen = []
      var categories = this.dataBottom.rows.map(row => {
        var out = {}
        keys.forEach(key => {
          out[key] = row[key]
        })
        return out
      }).filter(row => {
        var code = row['AccountCode']
        if (seen.indexOf(code) >= 0) {
          return false
        } else {
          seen.push(code)
          return true
        }
      })
      this.categories = categories
      this.dataBottom.footer_rows = totalrow

      this.dataTop.rows[0]['RevenueLessActual'] = this.$parent.$parent.$data.data.rows['RevenueLessActual'] || null
      this.dataTop.rows[0]['NetBalance'] = this.$parent.$parent.$data.data.rows['NetBalance'] || null
      this.dataTop.rows[0]['NetBudget'] = this.$parent.$parent.$data.data.rows['NetBudget'] || null
    }
  }
}
</script>
<style lang="scss">
  .non-sponsored {
    th,
    td {text-align : right}
    td:first-child,
    th:first-child{text-align: left;}
  }
  .table-totals {
    table {
      tr {
        td {cursor : default}
      }
    }
  }
</style>
