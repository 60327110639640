import { render, staticRenderFns } from "./AMPSVueCountUp.vue?vue&type=template&id=74025996"
import script from "./AMPSVueCountUp.vue?vue&type=script&lang=js"
export * from "./AMPSVueCountUp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/ADO-ResearchPortal/ADO-ResearchPortal/files-dash/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74025996')) {
      api.createRecord('74025996', component.options)
    } else {
      api.reload('74025996', component.options)
    }
    module.hot.accept("./AMPSVueCountUp.vue?vue&type=template&id=74025996", function () {
      api.rerender('74025996', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/utils/amps-vue-countup/AMPSVueCountUp.vue"
export default component.exports