<template>
  <mytable
    title="Debug"
    class="debug"
    v-bind:columns="data.columns"
    v-bind:store="data.rows"
    v-bind:error="data.error"
    v-bind:loading="data.loading"
  />
</template>

<script>

import mytable from './_mytable.vue'
import {ReportService} from '../services/'

export default {
  components: {
    mytable: mytable
  },
  data () {
    return {
      data: {
        columns: [],
        rows: [],
        loading: false,
        error: ''
      }
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      var report_opts = {
        esb_path: 'RD300_NonSSO',
        path: 'cognosenv',
        selection: 'RD999',
        p_Invest_ID: 'RD000' // THIS FIELD IS A REQUIRED PLACEBO FOR THIS SERVICE
      }
      var that = this
      this.data.loading = true
      ReportService.get(report_opts, this.$store).then((data) => {
        // Add a performance measurement named timestamp and pass to <mytable/>
        that.data.columns = data.headers
        that.data.rows = data.rows
        that.data.loading = false
        that.data.error = ''
      }).catch(err => {
        that.data.loading = false
        that.error = err
      })
    }
  }
}
</script>
<style lang="scss" />
