var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "no-pad" }, [
    _c("section", { staticClass: "hero contain-1440 small-hero" }, [
      _c("div", { staticClass: "hero-image" }, [
        _c(
          "div",
          { staticClass: "contain-1120" },
          [
            _c("h2", [_vm._v("Northwestern Research Portal")]),
            _c("p", [_vm._v("Research Management. Made Easy.")]),
            !_vm.AuthService.authenticated
              ? [
                  _c(
                    "a",
                    {
                      staticClass: "skrollr_login gm-button netid_login",
                      class: { working: _vm.AuthService.isAuthenticating },
                      on: {
                        click: function ($event) {
                          return _vm.AuthService.login()
                        },
                      },
                    },
                    [_vm._v("Login With Your NetID")]
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
    _c("div", { staticClass: "col-sm-12" }, [
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _c("section", { staticClass: "contain-1120" }, [
        _vm._m(5),
        _c("div", { staticClass: "stats-callout contain-970" }, [
          _c("div", [
            _c("p", [
              _c("span", { staticClass: "big" }, [
                _vm._v(_vm._s(_vm.researcher)),
              ]),
              _c("span", { staticClass: "small" }, [_vm._v("researchers")]),
            ]),
          ]),
          _c("div", [
            _c("p", [
              _c("span", { staticClass: "big" }, [
                _vm._v(_vm._s(_vm.sponsoredPrj)),
              ]),
              _c("span", { staticClass: "small" }, [
                _vm._v("sponsored projects"),
              ]),
            ]),
          ]),
          _c("div", [
            _c("p", [
              _c("span", { staticClass: "big" }, [
                _vm._v(_vm._s(_vm.pendingPrj)),
              ]),
              _c("span", { staticClass: "small" }, [
                _vm._v("pending proposals"),
              ]),
            ]),
          ]),
          _vm._m(6),
        ]),
      ]),
      _vm._m(7),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", [
      _c("div", { staticClass: "section-top contain-970" }, [
        _c("h3", [_vm._v("What is the Research Portal?")]),
        _c("p", { staticClass: "subhead" }, [
          _vm._v("All of your research management in one place."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "skrollr_section", attrs: { id: "big_picture" } },
      [
        _c("div", { staticClass: "contain-970" }, [
          _c("div", { staticClass: "left" }, [
            _c("h1", [_vm._v("Get the Big Picture")]),
            _c("p", [
              _vm._v(
                " Answer questions like, What’s my balance? Do I have enough money to hire an extra grad student? When is my human subjects protocol expiring? It's like online banking for research administration. "
              ),
            ]),
          ]),
          _c("div", { staticClass: "right images" }, [
            _c("div", { staticClass: "obj image1" }),
            _c("div", { staticClass: "obj image2" }),
            _c("div", { staticClass: "obj image3" }),
            _c("div", { staticClass: "obj image4" }),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "skrollr_section", attrs: { id: "manage_people" } },
      [
        _c("div", { staticClass: "contain-970" }, [
          _c("div", { staticClass: "left images" }, [
            _c("div", { staticClass: "obj image1" }),
          ]),
          _c("div", { staticClass: "right" }, [
            _c("h1", [_vm._v("Manage Projects and People")]),
            _c("p", [
              _vm._v(
                "View your portfolio by project or view salary sources and percentages organized by person"
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "skrollr_section", attrs: { id: "design" } },
      [
        _c("div", { staticClass: "contain-970" }, [
          _c("div", { staticClass: "left" }, [
            _c("h1", [_vm._v("Responsive and Intuitive Design")]),
            _c("p", [
              _vm._v(
                " Mac or PC, phone or tablet, we've got you covered - the Research Portal will function well and look good, whatever device you're using. Research faculty and RAs helped shape the design - we want to know what you think! Use the Provide Feedback button to share suggestions or report issues. "
              ),
            ]),
          ]),
          _c("div", { staticClass: "right images" }, [
            _c("div", { staticClass: "obj image1" }),
            _c("div", { staticClass: "obj image2" }),
            _c("div", { staticClass: "obj image3" }),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "skrollr_section", attrs: { id: "save_time" } },
      [
        _c("div", { staticClass: "contain-970" }, [
          _c("div", { staticClass: "left images" }, [
            _c("div", { staticClass: "obj image1" }),
          ]),
          _c("div", { staticClass: "right" }, [
            _c("h1", [_vm._v("Save Time")]),
            _c("p", [
              _vm._v(
                "Researchers see their own portfolios immediately upon login – no searching required. Move from summary to transaction detail in three clicks. No need to run multiple reports for different time periods."
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "section-top contain-970" }, [
      _c("h3", [_vm._v("Portal Statistics")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [
        _c("span", { staticClass: "big" }, [_vm._v("4,758")]),
        _c("span", { staticClass: "small" }, [_vm._v("active protocols")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "contain-970" }, [
      _c("div", { staticClass: "section-top contain-970" }, [
        _c("h3", [_vm._v("Research Faculty and Staff Feedback")]),
      ]),
      _c("div", { staticClass: "text" }, [
        _c("figure", { staticClass: "quote" }, [
          _c("p", [
            _c("span"),
            _vm._v(
              "The information I can access at any time makes it easier for me to manage my lab and research, without constantly having to ask staff members to give me up-to-date information as I used to do.”"
            ),
          ]),
          _c("figcaption", [_c("p", [_vm._v(" Faculty Researcher ")])]),
        ]),
        _c("figure", { staticClass: "quote" }, [
          _c("p", [
            _c("span"),
            _vm._v(
              "This is a very powerful and useful addition to the Portal. Staffers don’t always have access to the tools I use. Using this will let me walk them through the issues on a common platform.”"
            ),
          ]),
          _c("figcaption", [_c("p", [_vm._v(" Central Administrator ")])]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }