import Vue from 'vue'
import AuthService from '../auth/AuthService.js'
import VueResource from 'vue-resource'
Vue.use(VueResource)

export default {
  set (fav_id) {
    var user = AuthService.getUser()
    if (!user) {
      console.warn('User is not logged in. Bailing')
      return null
    }

    var url = process.env.VUE_APP_API_URL + '/favorites' + '?sso_token=' + AuthService.getSSOToken()

    var payload = {
      viewer_id: user.data.uid.toUpperCase(),
      fav_id: fav_id
    }
    var body = JSON.stringify(payload)

    var opts = {
      method: 'POST',
      body: body,
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
    return fetch(url, opts)
      .then(response => { console.log('Fav added', response) }, function (err) { console.log('fetch error: ' + err) })
  },
  delete (fav_id) {
    var user = AuthService.getUser()
    if (!user) {
      console.warn('User is not logged in. Bailing')
      return null
    }

    var url = process.env.VUE_APP_API_URL + '/favorites' + '?sso_token=' + AuthService.getSSOToken()

    var payload = {
      viewer_id: user.data.uid.toUpperCase(),
      fav_id: fav_id
    }
    var body = JSON.stringify(payload)

    var opts = {
      method: 'DELETE',
      body: body,
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
    return fetch(url, opts)
      .then(response => { console.log('Fav removed', response) }, function (err) { console.log('fetch error: ' + err) })
  },
  get () {
    var user = AuthService.getUser()
    if (!user) {
      console.warn('User is not logged in. Bailing')
      return null
    }

    var url = process.env.VUE_APP_API_URL + '/favorites?viewer_id=' + user.data.uid.toUpperCase()

    // add sso token
    var signed_url = url + '&sso_token=' + AuthService.getSSOToken()
    // console.log('thisURL:', signed_url)

    return fetch(signed_url).then(response => {
      return response.json()
    }).catch(err => { console.error(err) })
  }
}
