<template>
  <div v-show="option===&quot;enhancement_log&quot;" id="eg_iframe_holder" />
</template>
<script>
import pym from 'pym.js'
export default {
  props: {
    option: {type: String, default: null}
  },
  data () {
    return {
      pymParent: null
    }
  },
  watch: {
    'option': {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal === 'enhancement_log' && this.pymParent === null) {

          var url = 'https://sites.northwestern.edu/researchportalblog/northwestern-research-portal-help-enhancement-log/'
          this.pymParent = new pym.Parent('eg_iframe_holder', url, {})
        }
      }
    }
  }
}
</script>
