<template>
  <keep-alive>
    <div class="protocols">
      <mytable
        keep-alive
        title="IACUC Protocols"
        v-bind:selection="data.iacucsel"
        v-bind:columns="data.columns"
        v-bind:store="data.iacuc"
        v-bind:loading="data.loading"
        v-bind:performance="data.performance"
        v-bind:error="data.iacucerror"
      />

      <mytable
        keep-alive
        title="IRB Protocols"
        v-bind:selection="data.irbsel"
        v-bind:columns="data.columns"
        v-bind:store="data.irb"
        v-bind:loading="data.loading"
        v-bind:performance="data.performance"
        v-bind:error="data.irberror"
      />
    </div>
  </keep-alive>
</template>

<script>

import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import AuthService from '../../../auth/AuthService.js'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    mytable: mytable
  },
  data () {
    return {
      ignored_keys: ['PROJECT_ID'],
      store: {
        state: {
          reports: {

          }

        }
      },
      data: {
        irbsel: 'ORITIRB_GetProtocolByPI',
        iacucsel: 'ORITIACUC_GetProtocolByPI',
        // columns: ["protocol_id", "protocol_name", "expiration_date", "protocol_status", "initial_approval_date", "last_approval_date"],
        columns: ['protocol_id', 'expiration_date', 'protocol_status', 'initial_approval_date', 'last_approval_date'],
        irb: [],
        iacuc: [],
        showirb: true,
        showiacuc: true,
        performance: '',
        loading: true,
        iacucerror: null,
        irberror: null
      }
    }
  },
  mounted () {
    if (this.$route.params.netid === 'wildcat') {
      this.netid = Wildcat.protocol.NETID
    } else {
      this.netid = this.$route.params.netid
    }

    var that = this
    var timer = new Timer()
    var report_opts = {
      path: 'protocols',
      queryid: this.netid,
      service: 'byNetID'
    }
    if (!AuthService.user.data) {
      AuthService.logout()
      return
    }
    if (AuthService.user.data.RD014_ROLE.role === 'NONE' || (AuthService.user.data.RD014_ROLE.role === 'RESEARCHER' && AuthService.user.data.uid.toUpperCase() !== that.netid)) {
      that.data.loading = false
      that.data.iacucerror = 'Not Authorized'
      that.data.irberror = 'Not Authorized'
      return
    }
    ReportService.get(report_opts, this.$store).then(data => {
      that.data.loading = false
      if ('error' in data) {
        that.data.error = data.error
      } else {
        console.log('protocol data: ', data)
        if (data.iacuc.indexOf('Internal Server Error') >= 0) {
          that.data.iacucerror = 'eIACUC data is currently being refreshed. Please check back later.'
        } else if (data.iacuc.length === 0) {
          that.data.iacucerror = 'No data available'
        } else {
          that.data.iacuc = data.iacuc
          that.data.iacuc.forEach(row => {
            if (that.$route.params.netid === 'wildcat') {
              row['protocol_id'] = 'IS4211' + Math.round(Math.random() * 10000)
            }

            row['_clickable'] = true
          })
        }
        if (data.irb.indexOf('Internal Server Error') >= 0) {
          that.data.irberror = 'eIRB data is currently being refreshed. Please check back later.'
        } else if (data.irb.length === 0) {
          that.data.irberror = 'No data available'
        } else {
          that.data.irb = data.irb
          that.data.irb.forEach(row => {
            if (that.$route.params.netid === 'wildcat') {
              row['protocol_id'] = 'STU4211' + Math.round(Math.random() * 10000)
              row['protocol_name'] = row['protocol_name'].replace('Paller', 'Wildcat')
            }
            row['_clickable'] = true
          })
        }

        timer.tick('loaded in ')
        that.data.performance = timer.toString()
        that.data.loading = false
      }
    })

  },
  methods: {
    formatLabel (key, selection) {
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key, selection) {
      return Dictionary.formatTitle(key, selection)
    },
    delegateClick (row, key, $event) {
      if (this.ignored_keys.indexOf(key) >= 0) {
        console.log('Ignoring click')
        return
      }
      var path = ''
      var target_netid = this.$route.params.netid === 'wildcat' ? 'wildcat' : this.netid
      if (row['protocol_id'].indexOf('IS') >= 0) {
        path = '/protocol/' + target_netid + '/iacuc/' + row['protocol_id']
      }
      if (row['protocol_id'].indexOf('STU') >= 0) {
        path = '/protocol/' + target_netid + '/irb/' + row['protocol_id']
      }
      this.$router.push({path: path})
    }
  }
}

</script>
