<template>
  <keep-alive>
    <div>
      <div v-show="!hideTable">
        <mytable
          keep-alive
          title=""
          v-bind:back="{text:'Subcontracts'}"
          class="subcontractor-details"
          v-bind:columns="data.columns"
          v-bind:dropdowndata="selectdata"
          v-bind:store="data.rows"
          v-bind:footer_rows="data.footer_rows"
          v-bind:labels="data.labels"
          v-bind:sortkey="'PONumber'"
          v-bind:sortdir="1"
          v-bind:sortorders="{'PONumber':1}"
          v-bind:loading="data.loading"
          v-bind:performance="data.performance"
          v-bind:error="data.error"
          @update-selectdata="selectOpt"
        />
      </div>

      <div v-show="hideTable">
        <subcontractorpo />
      </div>
    </div>
  </keep-alive>
</template>

<script>
import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import subcontractorpo from './SubcontractorPO.vue'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    subcontractorpo: subcontractorpo,
    mytable: mytable
  },
  data () {
    return {
      ignored_keys: [],
      store: {
        footer_rows: [],
        vendorid: '',
        vendorname: '',
        rows: []
      },
      details: {
        selectdata: {
          options: [{
            text: '',
            value: ''
          }],
          idx: 0,
          vendor: ''
        }
      },
      hideTable: false,
      selectdata: {
        options: [{
          text: '',
          value: ''
        }],
        idx: 0
      },
      data: {
        error: '',
        loading: true,
        columns: ['ReqName', 'PONumber', 'POAmount', 'AmountPaid', 'BalanceRemaining', 'POStatus'],
        rows: [],
        footer_rows: [],
        labels: {}
      }
    }
  },
  watch: {
    '$parent.$data.details.selectdata': {
      handler: function (newVal, oldVal) {
        this.selectdata = newVal
        console.debug('GOT SELECTION', newVal)
        this.load()
      },
      deep: true
    }
  },
  mounted () {
    this.netid = this.$route.params.netid
    if (this.netid === 'wildcat') {
      this.netid = Wildcat.sponsored.NETID
      this.project_id = Wildcat.sponsored.PROJECT_ID
      // need to add fake venderid
    } else {
      this.project_id = this.$route.params.id
      this.vendorid = this.$route.params.vendorid
    }
  },
  methods: {
    formatLabel (key) {
      var selection = 'SubcontractPOBalances'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'SubcontractPOBalances'
      return Dictionary.formatTitle(key, selection)
    },
    selectRow (row) { console.log('Ignore row select') },

    delegateClick (row, key, $event) {
      console.log('CLICK', key)
      this.showDetails(row, key)
    },
    showDetails (row, key) {
      if (this.ignored_keys.indexOf(key) >= 0) {
        console.log('Ignoring click')
        return
      }
      var options = this.data.rows.map(row => {
        return {
          text: row['ReqName'],
          value: row['PONumber']
        }
      })
      var total_row = {
        text: 'Total',
        value: false
      }
      options.push(total_row)
      this.hideTable = true
      var list_codes = options.map(_row => _row.value)
      var idx = list_codes.indexOf(row['PONumber'])
      if (idx < 0) {
        idx = list_codes.length - 1
      }
      options.forEach(opt => {
        if (!opt.value) {
          opt.text = 'All transactions of ' + this.store.vendorname
        } else {
          opt.text = this.store.vendorname + ', PO # ' + opt.value
        }
      })
      this.details.selectdata.options = options
      this.details.selectdata.idx = idx
      this.details.selectdata.vendor = this.store.vendorid
    },
    goBack () {
      this.$parent.hideTable = false
    },
    selectOpt (value) {
      var searchedopt = this.selectdata.options.filter(opt => opt.value === value).pop()
      this.selectdata.idx = this.selectdata.options.indexOf(searchedopt)
      this.load(value)
    },
    load (value) {
      var timer = new Timer()
      this.data.loading = true
      var that = this
      if (value === undefined) {
        if (this.selectdata.options.length === 0) {
          return
        }
        value = this.selectdata.options[this.selectdata.idx].value
      }
      this.store.vendorid = value
      this.store.vendorname = this.selectdata.options[this.selectdata.idx].text
      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD018',
        p_PrjID: this.project_id,
        p_VendorID: value
      }

      this.data.loading = true
      this.data.error = ''
      that.data.rows = []
      that.data.footer_rows = []
      ReportService.get(report_opts, this.$store).then(data => {
        that.data.loading = false
        if ('error' in data) {
          that.data.error = data.error
        } else {
          that.data.rows = data.rows.map(row => {
            row['_clickable'] = true
            return row
          }) // override with fake data.
          if (that.$route.params.netid === 'wildcat') {
            that.data.rows.forEach(row => {
              var amount = 15000.42 + Math.round(Math.random() * 15000)
              var amount_paid = 500.42 + Math.round(Math.random() * 5000)
              row['PONumber'] = 'PUR42' + 100 + (Math.round(Math.random() * 1000))
              row['POAmount'] = amount
              row['AmountPaid'] = amount_paid
              row['BalanceRemaining'] = amount - amount_paid
            })
          }
          var footer_row = {}
          var footer_rows = []
          if (that.data.rows.length === 0) {
            that.data.error = 'No data available'
          } else {
            footer_row['ReqName'] = 'Total'
            footer_row['_clickable'] = true
            var amount_total = 0
            var paid_total = 0
            var balance_total = 0
            that.data.rows.forEach(row => {
              amount_total += parseFloat(row['POAmount'])
              paid_total += parseFloat(row['AmountPaid'])
              balance_total += parseFloat(row['BalanceRemaining'])
            })
            footer_row['POAmount'] = amount_total
            footer_row['AmountPaid'] = paid_total
            footer_row['BalanceRemaining'] = balance_total

            footer_rows.push(footer_row)
            that.data.footer_rows = footer_rows
          }
        }
        timer.tick('loaded in ')
        that.data.performance = timer.toString()
      }).catch(err => {
        that.data.loading = false
        console.error('Unable to parse the results', err)
      })
    }

  }

}
</script>
