import Vue from 'vue'
import AuthService from '../auth/AuthService.js'
import VueResource from 'vue-resource'
Vue.use(VueResource)

export default {
  send (data) {
    var user = AuthService.getUser()
    if (!user) {
      console.warn('User is not logged in. Bailing')
      return null
    }

    // var timer = new Timer()
    var BASE_URL = process.env.VUE_APP_API_URL + '/feedback' + '?sso_token=' + AuthService.getSSOToken()

    var url = BASE_URL

    var payload = {
      sso_token: AuthService.getSSOToken(),
      feedback: data
    }
    var body = JSON.stringify(payload)

    var opts = {
      method: 'POST',
      body: body,
      headers: new Headers({
        'Content-Type': 'application/json'
      })
      // mode: "cors",
    }
    console.log(opts)
    return fetch(url, opts).then((response) => {
      var data = response.json()
      return data
    }, function (err) {
      console.error(err)
    })
  }
}
