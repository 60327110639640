<template>
  <div>
    <div v-if="download_modal_expanded" class="modal-mask download-modal">
      <transition name="modal">
        <div v-on-clickaway="myClickAway" class="modal-container">
          <div class="modal-header">
            <h2>{{ buttonText }}</h2>
            <div class="modal-close" @click="cancel()">Close</div>
          </div>
          <div class="modal-body transaction">
            <p>{{ messageText }}</p>
            <p>This process can take up to five minutes.</p>
            <p>For the appropriate use of salary data, please refer to the <b><u><a href="http://www.northwestern.edu/myhr/admin/salary-access-policy.html" target="_blank">Salary Access Policy</a></u></b>.</p>
            <p>For the appropriate use of student data, please refer to the <b><u><a href="http://www.registrar.northwestern.edu/academic_records/FERPA_policy.html" target="_blank">FERPA Policy</a></u></b>.</p>
          </div>
          <div class="modal-footer">
            <button class="rd-submit-button" @click="send()">Send me the link</button>
            <button class="rd-small-button feed_close" @click="cancel()">Cancel</button>
          </div>
        </div>
      </transition>
    </div>

    <template v-if="AuthService.authenticated">
      <button id="show-modal" class="rd-download email" @click.stop="openModal()">{{ buttonText }}</button>
    </template>
  </div>
</template>

<script>
import AuthService2 from '../auth/AuthService.js'
import { directive as onClickaway } from 'vue-clickaway'
import {ReportService} from '../services/'
import Wildcat from './projects/Wildcat.json'
import {DateUtils} from '../utils/'

export default {
  directives: {
    onClickaway: onClickaway
  },
  props: {
    request: {type: String, default: null}
  },
  data () {
    return {
      AuthService: AuthService2,
      salaryRole: false,
      messageText: '',
      buttonText: '',
      download_modal_expanded: false,
      project_id: '123',
      email: '123@gmail.com'
    }
  },
  mounted () {
    if (this.$route.params.netid === 'wildcat') {
      this.project_id = Wildcat.sponsored.PROJECT_ID
    } else {
      this.project_id = this.$route.params.id // @TODO: Sanitize
    }
    this.load()
  },
  methods: {
    hideModal () {
      this.download_modal_expanded = false
    },
    openModal () {
      this.download_modal_expanded = true
    },
    toggleModal () {
      console.log('toggle')
      this.download_modal_expanded = !this.download_modal_expanded
    },
    myClickAway () {
      console.log('click away')

      if (this.download_modal_expanded) {
        this.hideModal()
      }
    },
    close () {
      this.hideModal()
    },
    cancel () {
      console.log('cancel')
      this.hideModal()
    },
    pad (value, length) {
      var res = (value.toString().length < length) ? this.pad('0' + value, length) : value
      return res
    },
    send () {
      if (this.request === 'allSalary') {
        // calculate the date range to retrieve
        var now = new Date()
        var toYear = now.getFullYear()
        var toMonth = now.getMonth() + 1
        // add 4 to align with the fiscal period
        var out_d = DateUtils.addMonths(toYear, toMonth, 0)
        toYear = out_d[0]
        toMonth = out_d[1]

        var d = DateUtils.addMonths(toYear, toMonth, -36) // 3 years
        var fromYYYYMM = '' + d[0] + this.pad(d[1], 2)
        var toYYYYMM = '' + toYear + this.pad(toMonth, 2)

        var opts = {
          project_type: 'all-salary',
          path: 'transactions',
          p_PrjID: this.$route.params.netid, // This is only used in the title of the exported spreadsheet.
          p_NetID: this.$route.params.netid,
          report_name: 'era-rp-effort',
          p_Service: 'RD027',
          p_EmplID: this.AuthService.user.data.uid,
          p_FromYYYYMM: fromYYYYMM,
          p_ToYYYYMM: toYYYYMM
        }
      } else {
        // eslint-disable-next-line no-redeclare
        var opts = {
          p_Service: 'RD010_Download',
          p_PrjID: this.project_id,
          p_Invest_ID: this.AuthService.user.data.uid,
          path: 'transactions'
        }
      }
      console.log('opts: ', opts)
      ReportService.get(opts, this.$store).then(data => { console.log(data) })
      this.hideModal()
    },

    load () {
      if (this.request === 'allSalary') {
        this.messageText = 'We will email you a link to download salary details for all listed personnel at ' + this.AuthService.mail + '. '
        this.buttonText = 'Email All Personnel Salary Details'
      } else {
        var opts = {
          report_name: 'era-rp-support',
          p_Service: 'RD022',
          p_PrjID: this.project_id
        }
        this.messageText = 'We will email you a link to download the Transaction file at ' + this.AuthService.mail + '. '
        this.buttonText = 'Email Transactions'
        ReportService.get(opts, this.$store).then(data => {
          if ('error' in data) {
          } else {
            if (data.rows[0].SalaryAccess === 'Yes') {
              this.messageText = 'We will email you a link to download the Transaction and Salary files at ' + this.AuthService.mail + '. '
              this.buttonText = 'Email Transaction and Salary Details'
            }
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" />
