import { render, staticRenderFns } from "./Error.vue?vue&type=template&id=36c62c42"
import script from "./Error.vue?vue&type=script&lang=js"
export * from "./Error.vue?vue&type=script&lang=js"
import style0 from "./Error.vue?vue&type=style&index=0&id=36c62c42&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/ADO-ResearchPortal/ADO-ResearchPortal/files-dash/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36c62c42')) {
      api.createRecord('36c62c42', component.options)
    } else {
      api.reload('36c62c42', component.options)
    }
    module.hot.accept("./Error.vue?vue&type=template&id=36c62c42", function () {
      api.rerender('36c62c42', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Error.vue"
export default component.exports