<template>
  <div class="no-pad">
    <!-- <div id='iframe_holder_home'></div> -->

    <section class="hero contain-1440 small-hero">
      <div class="hero-image">
        <div class="contain-1120">
          <h2>Northwestern Research Portal</h2>
          <p>Research Management. Made Easy.</p>
          <template v-if="!AuthService.authenticated">
            <a class="skrollr_login gm-button netid_login" v-bind:class="{'working': AuthService.isAuthenticating}" @click="AuthService.login()">Login With Your NetID</a>
          </template>
        </div>
      </div>
    </section>

    <div class="col-sm-12">
      <section>
        <div class="section-top contain-970">
          <h3>What is the Research Portal?</h3>
          <p class="subhead">All of your research management in one place.</p>

          <!-- <p>Optional content</p>
            <ul class="center-list">
            <li><a class="button" href="#">Optional Button</a></li>
            <li><a class="button" href="#">Optional Button</a></li>
            </ul> -->
        </div>
        <!-- component code here -->
      </section>

      <section id="big_picture" class="skrollr_section">
        <div class="contain-970">
          <div class="left">
            <h1>Get the Big Picture</h1>
            <p>
              Answer questions like, What’s my balance? Do I have enough money to hire an extra grad student? When is my human subjects protocol expiring?
              It's like online banking for research administration.
            </p>
          </div>
          <div class="right images">
            <div class="obj image1" />
            <div class="obj image2" />
            <div class="obj image3" />
            <div class="obj image4" />
          </div>
        </div>
      </section>

      <section id="manage_people" class="skrollr_section">
        <div class="contain-970">
          <div class="left images">
            <div class="obj image1" />
          </div>
          <div class="right">
            <h1>Manage Projects and People</h1>
            <p>View your portfolio by project or view salary sources and percentages organized by person</p>
          </div>
        </div>
      </section>

      <section id="design" class="skrollr_section">
        <div class="contain-970">
          <div class="left">
            <h1>Responsive and Intuitive Design</h1>
            <p>
              Mac or PC, phone or tablet, we've got you covered - the Research Portal will function well and look good, whatever device you're using.
              Research faculty and RAs helped shape the design - we want to know what you think! Use the Provide Feedback button to share suggestions or report issues.
            </p>
          </div>
          <div class="right images">
            <div class="obj image1" />
            <div class="obj image2" />
            <div class="obj image3" />
          </div>
        </div>
      </section>

      <section id="save_time" class="skrollr_section">
        <div class="contain-970">
          <div class="left images">
            <div class="obj image1" />
          </div>
          <div class="right">
            <h1>Save Time</h1>
            <p>Researchers see their own portfolios immediately upon login – no searching required. Move from summary to transaction detail in three clicks. No need to run multiple reports for different time periods.</p>
          </div>
        </div>
      </section>

      <section class="contain-1120">
        <div class="section-top contain-970">
          <h3>Portal Statistics</h3>
        </div>
        <div class="stats-callout contain-970">
          <div>
            <p><span class="big">{{ researcher }}</span><span class="small">researchers</span></p>
          </div>
          <div>
            <p><span class="big">{{ sponsoredPrj }}</span><span class="small">sponsored projects</span></p>
          </div>
          <div>
            <p><span class="big">{{ pendingPrj }}</span><span class="small">pending proposals</span></p>
          </div>
          <div>
            <p><span class="big">4,758</span><span class="small">active protocols</span></p>
          </div>
        </div>
        <!-- <ul class="center-list">
            <li><a class="button" href="#">Optional link</a></li>
          </ul> -->
      </section>

      <section class="contain-970">
        <div class="section-top contain-970">
          <h3>Research Faculty and Staff Feedback</h3>
        </div>
        <div class="text">
          <figure class="quote">
            <p><span />The information I can access at any time makes it easier for me to manage my lab and research, without constantly having to ask staff members to give me up-to-date information as I used to do.”</p>
            <figcaption>
              <p>
                <!-- <strong>Liz Gerber</strong><br> -->
                <!-- McCormick School of Engineering<br> -->
                Faculty Researcher
              </p>
            </figcaption>
          </figure>

          <figure class="quote">
            <p><span />This is a very powerful and useful addition to the Portal. Staffers don’t always have access to the tools I use. Using this will let me walk them through the issues on a common platform.”</p>
            <figcaption>
              <p>
                <!-- <strong>Michelle Grana</strong><br>
                Feinberg School of Medicine<br> -->
                Central Administrator
              </p>
            </figcaption>
          </figure>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

import AuthService from '../auth/AuthService.js'
import {ReportService} from '../services/'

export default {
  directives: {
    onClickaway: onClickaway
  },
  components: {},
  data () {
    return {
      sponsoredPrj: '6,159',
      pendingPrj: '11,179',
      researcher: '3,656',
      selectOptions: [
        { text: 'Inception to Date', value: 'inception-to-date'},
        { text: 'Expenses by Month', value: 'expenases-by-month'},
        { text: 'Inception tfo Date', value: 'inceptiaon-to-date'},
        { text: 'Expenses byf Month', value: 'expensaes-by-month'},
        { text: 'Inception tfo Date', value: 'incepation-to-date'},
        { text: 'Expenses byd Month', value: 'expendses-by-month'}
      ],
      showYear: false,
      showMonth: false,
      years: [2011, 2012, 2013, 2014, 2015, 2016],
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      selectedYear: 0,
      selectedMonth: 0,
      AuthService: AuthService,
      modal_expanded: false,
      proj_modal_expanded: false,
      feed_modal_expanded: false
    }
  },
  mounted () {

    var opts = {
      p_Service: 'RD015'
    }
    var that = this
    // console.log('Running RD015')
    ReportService.get(opts, this.$store).then(data => {
      if (data.rows[0]) {
        // eslint-disable-next-line no-undef
        that.pendingPrj = data.rows[0]['PendingProposalCount'] ? accounting.formatNumber(data.rows[0]['PendingProposalCount']) : that.pendingPrj
        // eslint-disable-next-line no-undef
        that.researcher = data.rows[0]['ResearcherCount'] ? accounting.formatNumber(data.rows[0]['ResearcherCount']) : that.researcher
        // eslint-disable-next-line no-undef
        that.sponsoredPrj = data.rows[0]['SponsoredProjectsCount'] ? accounting.formatNumber(data.rows[0]['SponsoredProjectsCount']) : that.sponsoredPrj
      }
    }).catch(err => { console.log(err) })
  },
  methods: {
    foo () {
      this.modal_expanded = false
    },
    boo () {
      this.proj_modal_expanded = false
    },
    coo () {
      this.feed_modal_expanded = false
    }
  }
}
</script>
<style lang="scss">

.no-pad{
  padding:0;
}

</style>
