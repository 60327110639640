<template>
  <div>
    <div class="actions" />

    <div v-show="!hideTable">
      <mytable
        title="Payments"
        class="payments"
        v-bind:columns="data.columns"
        v-bind:store="data.rows"
        v-bind:labels="data.labels"
        v-bind:loading="data.loading"
        v-bind:performance="data.performance"
        v-bind:error="data.error"
      />

      <mytable
        title="Outstanding Invoices"
        class="outstanding-invoices"
        v-bind:columns="outstandingInvoicesData.columns"
        v-bind:store="outstandingInvoicesData.rows"
        v-bind:labels="outstandingInvoicesData.labels"
        v-bind:loading="outstandingInvoicesData.loading"
        v-bind:performance="outstandingInvoicesData.performance"
        v-bind:error="outstandingInvoicesData.error"
      />
    </div>

    <div v-show="hideTable">
      <costsharedetails
        title=""
        class="cost-share-details"
        v-bind:back="{text:'Cost Share'}"
      />
    </div>
  </div>
</template>

<script>

import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import costsharedetails from './CostShareDetails.vue'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    costsharedetails: costsharedetails,
    mytable: mytable
  },
  data () {
    return {
      hideTable: false,
      store: {
        rows: []
      },
      outstandingInvoicesStore: {
        rows: []
      },
      selectdata: {
        options: [
          { text: 'View all', value: '0'},
          { text: 'Over the Cap', value: '192' },
          { text: 'Mandatory', value: '191'},
          { text: 'Voluntary', value: '193'}
        ],
        idx: 0
      },
      data: {
        loading: false,
        columns: ['Invoice', 'PaymentAmount', 'DepositDate', 'PostedDate', 'PaymentID'],
        rows: [],
        labels: {}
      },
      outstandingInvoicesData: {
        loading: false,
        columns: ['Invoice', 'InvoiceAmount', 'DateSent'],
        rows: [],
        labels: {}
      }
    }
  },
  mounted () {
    if (this.$route.params.netid === 'wildcat') {
      this.project_id = Wildcat.sponsored.PROJECT_ID
    } else {
      this.project_id = this.$route.params.id
    }
    this.load()
    this.loadOutstandingInvoices()
  },
  methods: {
    formatLabel (key) {
      var selection = 'PaymentFeature'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'PaymentFeature'
      return Dictionary.formatTitle(key, selection)
    },
    selectRow (row) { console.debug('nothing to do') },

    load () {
      var timer = new Timer()
      var that = this

      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD016',
        p_PrjID: this.project_id,
        p_PaymentStatus: 'P'
      }

      this.data.loading = true

      ReportService.get(report_opts, this.$store).then(data => {
        that.data.loading = false
        if ('error' in data) {
          that.data.error = data.error
        } else {
          that.data.rows = data.rows
          // Demo user
          if (this.$route.params.netid === 'wildcat') {
            that.data.rows.forEach(row => {
              var payment = 1000.42 + Math.round(Math.random() * 5000)
              row['PaymentAmount'] = payment
            })
          }
          that.store.rows = that.data.rows.map(it => it)
        }
        timer.tick('loaded in ')
        that.data.performance = timer.toString()
      }).catch(err => {
        console.error('Unable to parse the results', err)
      })
    },
    loadOutstandingInvoices () {
      var timer = new Timer()
      var that = this

      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD016',
        p_PrjID: this.project_id,
        p_PaymentStatus: 'O'
      }

      this.outstandingInvoicesData.loading = true

      ReportService.get(report_opts, this.$store).then(data => {

        console.log(data.rows)
        that.outstandingInvoicesData.loading = false
        if ('error' in data) {
          that.outstandingInvoicesData.error = data.error
        } else {
          that.outstandingInvoicesData.rows = data.rows
          that.outstandingInvoicesStore.rows = that.outstandingInvoicesData.rows.map(it => it)
          // Demo user
        }
        timer.tick('loaded in ')
        that.outstandingInvoicesData.performance = timer.toString()
      }).catch(err => {
        console.error('Unable to parse the results', err)
      })
    }

  }
}
</script>
<style lang="scss">
.cost-share {
  table {
    td {cursor: default}
  }
}
</style>
