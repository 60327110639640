<template>
  <mytable
    title=""
    v-bind:back="{text:'Purchase Orders'}"
    class="subcontractor-po"
    v-bind:columns="data.columns"
    v-bind:dropdowndata="selectdata"
    v-bind:store="data.rows"
    v-bind:footer_rows="data.footer_rows"
    v-bind:sortkey="'AccountCode'"
    v-bind:sortdir="1"
    v-bind:sortorders="{'AccountCode':1}"
    v-bind:labels="data.labels"
    v-bind:loading="data.loading"
    v-bind:performance="data.performance"
    v-bind:error="data.error"
    @update-selectdata="selectOpt"
  />
</template>

<script>
import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    mytable: mytable
  },
  data () {
    return {
      store: {
        footer_rows: [],
        rows: []
      },
      hideTable: false,
      selectdata: {
        options: [{
          text: '',
          value: ''
        }],
        idx: 0
      },
      data: {
        loading: true,
        columns: ['AccountDescription', 'AmountPaid', 'AccountingDate', 'InvoiceNumber', 'InvoiceDate', 'PONumber'],
        rows: [],
        footer_rows: [],
        labels: {},
        error: null
      }
    }
  },
  watch: {
    '$parent.$data.details.selectdata': {
      handler: function (newVal, oldVal) {
        this.selectdata = newVal
        console.debug('GOT SELECTION', newVal)
        this.load()
      },
      deep: true
    }
  },
  mounted () {
    this.netid = this.$route.params.netid
    if (this.netid === 'wildcat') {
      this.project_id = Wildcat.sponsored.PROJECT_ID
    } else {
      this.project_id = this.$route.params.id
    }
  },
  methods: {
    formatLabel (key) {
      var selection = 'SubcontractInvoices'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'SubcontractInvoices'
      return Dictionary.formatTitle(key, selection)
    },
    formatValue (key, row) {
      return Dictionary.formatValue(key, row)
    },
    goBack () {
      this.$parent.hideTable = false
    },
    selectOpt (value) {
      var searchedopt = this.selectdata.options.filter(opt => opt.value === value).pop()
      this.selectdata.idx = this.selectdata.options.indexOf(searchedopt)
      this.load(value)
    },
    load (value) {
      var timer = new Timer()
      this.data.loading = true
      var that = this

      if (value === undefined) {
        if (this.selectdata.options.length === 0) {
          return
        }
        value = this.selectdata.options[this.selectdata.idx].value
        this.vendorid = this.selectdata.vendor
      }
      if (this.netid === 'wildcat') {
        this.vendorid = Wildcat.sponsored.VENDORID_ID
        if (value) {
          value = Wildcat.sponsored.PO
        }

      }

      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD019',
        p_PrjID: this.project_id,
        p_VendorID: this.vendorid,
        p_PO: value
      }
      if (!value) {
        report_opts = {
          report_name: 'era-rp-main',
          p_Service: 'RD019',
          p_PrjID: this.project_id,
          p_VendorID: this.vendorid
        }
      }

      this.data.loading = true
      this.data.error = ''
      that.data.rows = []
      that.data.footer_rows = []
      ReportService.get(report_opts, this.$store).then(data => {
        that.data.loading = false
        if ('error' in data) {
          that.data.error = data.error
        } else {
          that.data.rows = data.rows
          // override with fake data.
          if (that.$route.params.netid === 'wildcat') {
            that.data.rows.forEach(row => {
              var amount_paid = 500.42 + Math.round(Math.random() * 5000)
              row['AmountPaid'] = amount_paid
              row['PONumber'] = 'PUR42' + 100 + (Math.round(Math.random() * 1000))
            })
          }
          var footer_row = {}
          var footer_rows = []
          if (that.data.rows.length === 0) {
            that.data.error = 'No data available'
          } else {
            footer_row['AccountDescription'] = 'Total'
            var total = that.data.rows.reduce((sum, row) => {
              return sum + parseFloat(row['AmountPaid'])
            }, 0)
            footer_row['AmountPaid'] = total
            footer_rows.push(footer_row)
            that.data.footer_rows = footer_rows
          }
        }
        timer.tick('loaded in ')
        that.data.performance = timer.toString()
      }).catch(err => {
        that.data.loading = false
        console.error('Unable to parse the results', err)
      })
    }

  }

}

</script>
