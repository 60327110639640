<template>
  <div v-on-clickaway="onClickAway" class="dropdown">
    <button id="dropdownMenu1" class="rd-btn rd-dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" @click="toggle">
      {{ options[idx].text }}
    </button>
    <ul v-bind:class="{'active': showOptions}" class="rd-dropdown-menu dropdown-menu" aria-labelledby="dropdownMenu1">
      <li v-if="options.length >= 5">
        <input id="searchboxinput" v-model="query" type="search" class="search-box" placeholder="Search...">
        <i class="glyphicon glyphicon-search" />
      </li>

      <li
        v-for="(opt, index) in filteredOptions"
        v-bind:key="index"
        v-bind:class="{'active': opt.value === options[idx].value}"
        @click="selectOpt(index, opt, name)"
      >
        <a>{{ opt.text }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {onClickaway: onClickaway},
  props: {
    name: {type: String, default: null},
    options: {type: Array, default: null},
    idx: {type: Number, default: null}
  },
  data () {
    return {
      showOptions: false,
      query: ''
    }
  },
  computed: {
    filteredOptions: function () {
      return this.options.filter((opt) => {
        return opt.text.toLowerCase().trim().match(this.query.toLowerCase().trim()) // return t/f about including that opt
      })
    }
  },
  methods: {
    onClickAway () {
      this.showOptions = false
    },
    selectOpt (idx, opt) {
      // eslint-disable-next-line
      this.idx = idx
      this.showOptions = false
      this.$emit('update-table-dropdown', opt.value)
    },
    focusInput () {
      if (this.showOptions) {
        var el = document.querySelector('.dropdown .search-box')
        if (el) { el.focus() }
      }
    },
    toggle () {
      this.showOptions = !this.showOptions
      this.focusInput()
    }
  }
}
</script>
<style lang="scss" />
