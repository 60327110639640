var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("keep-alive", [
    _c(
      "div",
      [
        _vm.projection_tool.modal_expanded
          ? _c(
              "div",
              { staticClass: "modal-mask" },
              [
                _c("transition", { attrs: { name: "modal" } }, [
                  _c("div", { staticClass: "modal-wrapper" }, [
                    _c("div", { staticClass: "modal-container" }, [
                      _c("div", { staticClass: "modal-header" }, [
                        _c("h2", [_vm._v("Manage Projections")]),
                        _c(
                          "div",
                          {
                            staticClass: "modal-close",
                            on: {
                              click: function ($event) {
                                _vm.projection_tool.modal_expanded =
                                  !_vm.projection_tool.modal_expanded
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                      ]),
                      _c("div", { staticClass: "modal-body" }, [
                        _c(
                          "ul",
                          { staticClass: "projections_list" },
                          [
                            _vm.projectiontooldata.projections.length === 0
                              ? _c("li", { staticClass: "projection" }, [
                                  _c("div", { staticClass: "card not_avail" }, [
                                    _vm._v(" No Projections available "),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._l(
                              _vm.projectiontooldata.projections,
                              function (projection, index) {
                                return _c(
                                  "li",
                                  {
                                    key: "projection-" + index,
                                    staticClass: "projection",
                                    class: { disabled: projection.disabled },
                                  },
                                  [
                                    _c(
                                      "transition",
                                      { attrs: { name: "fadeout" } },
                                      [
                                        _c("div", { staticClass: "card" }, [
                                          _c("ul", [
                                            _c("li", [
                                              _c(
                                                "span",
                                                { staticClass: "key" },
                                                [_vm._v("Projection Title")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "value" },
                                                [
                                                  _vm._v(
                                                    _vm._s(projection.title)
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("li", [
                                              _c(
                                                "span",
                                                { staticClass: "key" },
                                                [_vm._v("Author")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "value" },
                                                [
                                                  _vm._v(
                                                    _vm._s(projection.author.cn)
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("li", [
                                              _c(
                                                "span",
                                                { staticClass: "key" },
                                                [_vm._v("Last Modified:")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "value" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      new Date(
                                                        projection.created
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                          projection.author.netid === _vm.netid
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "projection_options",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "rd-small-button proj_apply",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.applyProjection(
                                                            projection
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Apply This Projection"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "rd-small-button proj_delete",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteProjection(
                                                            projection
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Delete This Projection"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "projection_options",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "This projection can only be accessed by " +
                                                        _vm._s(
                                                          projection.author.cn
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.modal_expanded
          ? _c(
              "div",
              { staticClass: "modal-mask" },
              [
                _c("transition", { attrs: { name: "modal" } }, [
                  _c("div", { staticClass: "modal-wrapper modal-data" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "on-clickaway",
                            rawName: "v-on-clickaway",
                            value: _vm.foo,
                            expression: "foo",
                          },
                        ],
                        staticClass: "modal-container",
                      },
                      [
                        _c("div", { staticClass: "modal-header" }, [
                          _c("h2", [_vm._v("Pending Project Detail")]),
                          _c(
                            "div",
                            {
                              staticClass: "modal-close",
                              on: {
                                click: function ($event) {
                                  _vm.modal_expanded = !_vm.modal_expanded
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                        ]),
                        _c("div", { staticClass: "modal-body" }, [
                          _c("div", { staticClass: "card" }, [
                            _c(
                              "ul",
                              _vm._l(_vm.columns, function (col, index) {
                                return _c("li", { key: "col-" + index }, [
                                  _c("div", { staticClass: "key" }, [
                                    _vm._v(_vm._s(_vm.formatLabel(col))),
                                  ]),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatValue(col, _vm.selectedRow)
                                      )
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "modal-footer" }, [
                          _c("div", { staticClass: "modal_options" }, [
                            _vm.selectedRow["_STATUS"] === "ACTIVE"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "funding_status",
                                    class: {
                                      not_funded:
                                        _vm.selectedRow.funding ===
                                        "not_funded",
                                      waiting:
                                        _vm.selectedRow.funding === "waiting",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.mark(
                                          _vm.selectedRow,
                                          "NOT_FUNDED"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm.selectedRow.funding === ""
                                      ? _c("span", [
                                          _vm._v("Mark as 'not funded'"),
                                        ])
                                      : _vm._e(),
                                    _vm.selectedRow.funding === "active"
                                      ? _c("span", [
                                          _vm._v("Mark as 'not funded'"),
                                        ])
                                      : _vm._e(),
                                    _vm.selectedRow.funding === "waiting"
                                      ? _c("span", [
                                          _vm._v("Marking as 'not funded'"),
                                        ])
                                      : _vm._e(),
                                    _vm.selectedRow.funding === "not_funded"
                                      ? _c("span", [
                                          _vm._v("Marked as 'not funded'"),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _vm.selectedRow["_STATUS"] !== "ACTIVE"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "funding_status",
                                    class: {
                                      active:
                                        _vm.selectedRow.funding === "active",
                                      waiting:
                                        _vm.selectedRow.funding === "waiting",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.mark(
                                          _vm.selectedRow,
                                          "ACTIVE"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm.selectedRow.funding === ""
                                      ? _c("span", [_vm._v("Mark as 'Active'")])
                                      : _vm._e(),
                                    _vm.selectedRow.funding === "not_funded"
                                      ? _c("span", [_vm._v("Mark as 'Active'")])
                                      : _vm._e(),
                                    _vm.selectedRow.funding === "waiting"
                                      ? _c("span", [
                                          _vm._v("Marking as 'active'"),
                                        ])
                                      : _vm._e(),
                                    _vm.selectedRow.funding === "active"
                                      ? _c("span", [
                                          _vm._v("Marked as 'active'"),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.NO_ACCESS
          ? [_c("h3", [_vm._v("Oops!")]), _c("p", [_vm._v(_vm._s(_vm.error))])]
          : _vm._e(),
        !_vm.NO_ACCESS
          ? [
              _c(
                "div",
                { staticClass: "table_wrap" },
                [
                  _c(
                    "div",
                    { staticClass: "table_menu_flex" },
                    [
                      _vm.back
                        ? [
                            _c("div", { staticClass: "table_nav flex_child" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "arr back",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$parent.goBack()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.back.text))]
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.title
                        ? [
                            _c(
                              "div",
                              { staticClass: "table_title flex_child" },
                              [
                                _c("h2", [
                                  _vm._v(
                                    _vm._s(_vm.title) +
                                      " (" +
                                      _vm._s(_vm.store.length) +
                                      ")"
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm.toggledata
                        ? [
                            _c(
                              "div",
                              { staticClass: "table_switcher flex_child" },
                              [
                                _c("mytoggle", {
                                  attrs: {
                                    options: _vm.toggledata.options,
                                    activeindex: _vm.toggledata.idx,
                                  },
                                  on: {
                                    "update-table-toggle":
                                      _vm.updatetabletoggledata,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm.buttons
                        ? [
                            _c(
                              "div",
                              { staticClass: "table_buttons flex_child" },
                              _vm._l(_vm.buttons, function (button, index) {
                                return _c(
                                  "button",
                                  {
                                    key: "button-" + index,
                                    class: button.active
                                      ? "rd-small-button proj_new active"
                                      : "rd-small-button proj_new",
                                    on: {
                                      click: function ($event) {
                                        return _vm.buttonClick(button.value)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          button.label +
                                            (button.active ? "*" : "")
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        : _vm._e(),
                      _vm.projectiontooldata
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "table_projection_menu flex_child",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "rd-small-button proj_new",
                                    on: {
                                      click: function ($event) {
                                        return _vm.newProjection()
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("New Projection")])]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "rd-small-button proj_save",
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveProjection()
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("Save Projection")])]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "rd-small-button proj_manage",
                                    on: {
                                      click: function ($event) {
                                        return _vm.manageProjections()
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("Manage Projections")])]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm.dropdowndata
                        ? [
                            _c(
                              "div",
                              { staticClass: "table_switcher flex_child" },
                              [
                                _c("mydropdown", {
                                  attrs: {
                                    options: _vm.dropdowndata.options,
                                    idx: _vm.dropdowndata.idx,
                                  },
                                  on: {
                                    "update-table-dropdown":
                                      _vm.updatetabledropdowndata,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      !_vm.hidesearch
                        ? [
                            _c(
                              "div",
                              { staticClass: "table_search search flex_child" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.search.query,
                                      expression: "search.query",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    name: "query",
                                    placeholder: "Search This Table",
                                  },
                                  domProps: { value: _vm.search.query },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.search,
                                          "query",
                                          $event.target.value
                                        )
                                      },
                                      _vm.debounceSearch,
                                    ],
                                    keyup: function ($event) {
                                      return _vm.view(true)
                                    },
                                  },
                                }),
                                _c("i", {
                                  staticClass: "glyphicon glyphicon-search",
                                }),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm.datepickerdata
                        ? [
                            _c(
                              "div",
                              { staticClass: "table_datepicker flex_child" },
                              [
                                _c("mydatepicker", {
                                  attrs: { datepickerdata: _vm.datepickerdata },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm.periodpickerdata
                        ? [
                            _c(
                              "div",
                              { staticClass: "table_periodpicker flex_child" },
                              [
                                _c("myperiodpicker", {
                                  attrs: {
                                    periodpickerdata: _vm.periodpickerdata,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm.table_mode === "VERTICAL"
                    ? [
                        _c(
                          "div",
                          { staticClass: "table-vertical" },
                          [
                            _vm.loading
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticStyle: { position: "relative" } },
                                    [_c("loading")],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            !_vm.loading && _vm.rows.length === 0
                              ? _c("div", [
                                  _vm._v(" " + _vm._s(_vm.error) + " "),
                                ])
                              : _vm._e(),
                            _vm._l(_vm.rows, function (row, index) {
                              return _c(
                                "div",
                                {
                                  key: "row-" + index,
                                  staticClass: "card",
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectRow(row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.columns, function (key) {
                                      return _c(
                                        "li",
                                        { key: key },
                                        [
                                          key === "profile_style" ||
                                          key === "Favorites"
                                            ? [
                                                key === "profile_style"
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "user-thumb",
                                                          style: {
                                                            backgroundImage:
                                                              row[key],
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                row[key]
                                                                  ? ""
                                                                  : row[
                                                                      "PrimaryFirstName"
                                                                    ]
                                                                  ? row[
                                                                      "PrimaryFirstName"
                                                                    ][0]
                                                                  : row[
                                                                      "EmployeeFirstName"
                                                                    ][0]
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                row[key]
                                                                  ? ""
                                                                  : row[
                                                                      "PrimaryLastName"
                                                                    ]
                                                                  ? row[
                                                                      "PrimaryLastName"
                                                                    ][0]
                                                                  : row[
                                                                      "EmployeeLastName"
                                                                    ][0]
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                                key === "Favorites"
                                                  ? [
                                                      row[key] === true
                                                        ? _c(
                                                            "div",
                                                            {
                                                              key:
                                                                "fv-" + index,
                                                              attrs: {
                                                                align: "center",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  key:
                                                                    "fvs1-" +
                                                                    index,
                                                                  staticStyle: {
                                                                    color:
                                                                      "#FFFFFF",
                                                                    "font-size":
                                                                      "20px",
                                                                  },
                                                                },
                                                                [_vm._v("★")]
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              key:
                                                                "fve-" + index,
                                                              attrs: {
                                                                align: "center",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  key:
                                                                    "fvs2-" +
                                                                    index,
                                                                  staticStyle: {
                                                                    color:
                                                                      "##4E2A84",
                                                                    "font-size":
                                                                      "20px",
                                                                  },
                                                                },
                                                                [_vm._v("★")]
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  : _vm._e(),
                                              ]
                                            : [
                                                _c(
                                                  "span",
                                                  { staticClass: "key" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatLabel(
                                                          key,
                                                          _vm.selection
                                                        )
                                                      ) + ":"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "value",
                                                    class: _vm.formatClass(
                                                      key,
                                                      row
                                                    ),
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatValue(
                                                          key,
                                                          row
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.table_mode === "HORIZONTAL"
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "table_content",
                            attrs: {
                              id: "skrollr-body",
                              "data-97-top": "top:98px",
                              "data-98-top": "top:-200px",
                              "data-anchor-target": "tbody",
                            },
                          },
                          [
                            _c("div", { staticClass: "container" }, [
                              _c("div", { staticClass: "table_head_wrap" }, [
                                _c(
                                  "div",
                                  { staticClass: "table_menu_flex" },
                                  [
                                    _vm.back
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "table_nav flex_child",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "arr back",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$parent.goBack()
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(_vm.back.text))]
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm.title
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "table_title flex_child",
                                            },
                                            [
                                              _c("h2", [
                                                _vm._v(
                                                  _vm._s(_vm.title) +
                                                    " (" +
                                                    _vm._s(_vm.store.length) +
                                                    ")"
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm.dropdowndata
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "table_switcher flex_child",
                                            },
                                            [
                                              _c("mydropdown", {
                                                attrs: {
                                                  options:
                                                    _vm.dropdowndata.options,
                                                  idx: _vm.dropdowndata.idx,
                                                },
                                                on: {
                                                  "update-table-dropdown":
                                                    _vm.updatetabledropdowndata,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                    !_vm.hidesearch
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "table_search search flex_child",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.search.query,
                                                    expression: "search.query",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  name: "query",
                                                  placeholder:
                                                    "Search This Table",
                                                },
                                                domProps: {
                                                  value: _vm.search.query,
                                                },
                                                on: {
                                                  input: [
                                                    function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.search,
                                                        "query",
                                                        $event.target.value
                                                      )
                                                    },
                                                    _vm.debounceSearch,
                                                  ],
                                                  keyup: function ($event) {
                                                    return _vm.view(true)
                                                  },
                                                },
                                              }),
                                              _c("i", {
                                                staticClass:
                                                  "glyphicon glyphicon-search",
                                              }),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm.datepickerdata
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "table_datepicker flex_child",
                                            },
                                            [
                                              _c("mydatepicker", {
                                                attrs: {
                                                  datepickerdata:
                                                    _vm.datepickerdata,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm.periodpickerdata
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "table_periodpicker flex_child",
                                            },
                                            [
                                              _c("myperiodpicker", {
                                                attrs: {
                                                  periodpickerdata:
                                                    _vm.periodpickerdata,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  { staticStyle: { width: "100%" } },
                                  [
                                    _c("thead", [
                                      _c(
                                        "tr",
                                        _vm._l(_vm.columns, function (key) {
                                          return _c(
                                            "th",
                                            {
                                              key: key,
                                              class: _vm.formatClass(key, {}),
                                              attrs: {
                                                title: _vm.formatTitle(key),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sortBy(key)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.formatLabel(key)) +
                                                  " "
                                              ),
                                              !_vm.hidesort
                                                ? [
                                                    _c("span", {
                                                      staticClass: "arrow",
                                                      class:
                                                        _vm.sortOrders[key] > 0
                                                          ? "asc"
                                                          : "dsc",
                                                    }),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        }),
                                        0
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "table_content",
                            attrs: { id: "table_content" },
                          },
                          [
                            _c("table", { staticStyle: { width: "100%" } }, [
                              _c("thead", [
                                _c(
                                  "tr",
                                  _vm._l(_vm.columns, function (key) {
                                    return _c(
                                      "th",
                                      {
                                        key: key,
                                        class: _vm.formatClass(key, {}),
                                        attrs: {
                                          title: _vm.formatTitle(
                                            key,
                                            _vm.selection
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sortBy(key)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatLabel(
                                                key,
                                                _vm.selection
                                              )
                                            ) +
                                            " "
                                        ),
                                        !_vm.hidesort
                                          ? [
                                              _c("span", {
                                                staticClass: "arrow",
                                                class:
                                                  _vm.sortOrders[key] > 0
                                                    ? "asc"
                                                    : "dsc",
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                ),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm.loading
                                    ? _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              height: "50px !important",
                                            },
                                            attrs: {
                                              colspan: "columns.length",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  position: "relative",
                                                },
                                              },
                                              [_c("loading")],
                                              1
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._l(_vm.rows, function (entry, index) {
                                    return _c(
                                      "tr",
                                      {
                                        key: "entry-" + index,
                                        class: entry["_STATUS"],
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectRow(entry)
                                          },
                                        },
                                      },
                                      _vm._l(_vm.columns, function (key) {
                                        return _c(
                                          "td",
                                          {
                                            key: key,
                                            class: _vm.formatClass(key, entry),
                                            attrs: { title: entry[key] },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.delegateClick(
                                                  entry,
                                                  key,
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            key === "profile_style" ||
                                            key === "Favorites"
                                              ? [
                                                  key === "profile_style"
                                                    ? [
                                                        _c(
                                                          "div",
                                                          {
                                                            key: "ps-" + index,
                                                            staticClass:
                                                              "user-thumb profile_style",
                                                            style: {
                                                              backgroundImage:
                                                                entry[
                                                                  "profile_style"
                                                                ],
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  entry[
                                                                    "profile_style"
                                                                  ]
                                                                    ? ""
                                                                    : entry[
                                                                        "PrimaryFirstName"
                                                                      ]
                                                                    ? entry[
                                                                        "PrimaryFirstName"
                                                                      ][0]
                                                                    : entry[
                                                                        "EmployeeFirstName"
                                                                      ][0]
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  entry[
                                                                    "profile_style"
                                                                  ]
                                                                    ? ""
                                                                    : entry[
                                                                        "PrimaryLastName"
                                                                      ]
                                                                    ? entry[
                                                                        "PrimaryLastName"
                                                                      ][0]
                                                                    : entry[
                                                                        "EmployeeLastName"
                                                                      ][0]
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                  key === "Favorites"
                                                    ? [
                                                        entry[key] === true
                                                          ? _c(
                                                              "div",
                                                              {
                                                                key:
                                                                  "fv-" + index,
                                                                attrs: {
                                                                  align:
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    key:
                                                                      "fvs1-" +
                                                                      index,
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#4E2A84",
                                                                        "font-size":
                                                                          "20px",
                                                                      },
                                                                  },
                                                                  [_vm._v("★")]
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                key:
                                                                  "fve-" +
                                                                  index,
                                                                attrs: {
                                                                  align:
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    key:
                                                                      "fvs2-" +
                                                                      index,
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#FFFFFF",
                                                                        "font-size":
                                                                          "20px",
                                                                      },
                                                                  },
                                                                  [_vm._v("★")]
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    : _vm._e(),
                                                ]
                                              : [
                                                  key === "_PROJECTED"
                                                    ? [
                                                        _c(
                                                          "div",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  entry.editable,
                                                                expression:
                                                                  "entry.editable",
                                                              },
                                                            ],
                                                            key: "pj-" + index,
                                                            staticClass:
                                                              "edit-mode",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                key:
                                                                  "pjs1-" +
                                                                  index,
                                                                staticClass:
                                                                  "dollar",
                                                              },
                                                              [_vm._v("$")]
                                                            ),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    entry[key],
                                                                  expression:
                                                                    "entry[key]",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                "text-align":
                                                                  "right",
                                                              },
                                                              attrs: {
                                                                tabindex: "0",
                                                                type: "text",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  entry[key],
                                                              },
                                                              on: {
                                                                keyup:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    return _vm.accept(
                                                                      entry,
                                                                      $event
                                                                    )
                                                                  },
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.accept(
                                                                    entry,
                                                                    $event
                                                                  )
                                                                },
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      entry,
                                                                      key,
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            entry.editable !==
                                                            null
                                                              ? _c("i", {
                                                                  staticClass:
                                                                    "ion-checkmark-circled",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.toggleEdit(
                                                                          entry,
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  !entry.editable,
                                                                expression:
                                                                  "!entry.editable",
                                                              },
                                                            ],
                                                            key: "sh-" + index,
                                                            staticClass:
                                                              "non-edit-mode",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                key:
                                                                  "shs1-" +
                                                                  index,
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.toggleEdit(
                                                                        entry,
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                entry[
                                                                  "_clickable"
                                                                ]
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        key:
                                                                          "shs2-" +
                                                                          index,
                                                                        staticClass:
                                                                          "dollar",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "$"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.isCurrency(
                                                                  key,
                                                                  entry
                                                                )
                                                                  ? _c(
                                                                      "ampsvuecountup",
                                                                      {
                                                                        staticClass:
                                                                          "myCounter",
                                                                        attrs: {
                                                                          decimal: 2,
                                                                          end:
                                                                            parseFloat(
                                                                              entry[
                                                                                key
                                                                              ]
                                                                            ) ||
                                                                            0,
                                                                          duration: 1,
                                                                          options:
                                                                            {
                                                                              useEasing: true,
                                                                              useGrouping: true,
                                                                              separator:
                                                                                ",",
                                                                              decimal:
                                                                                ".",
                                                                              prefix:
                                                                                "",
                                                                              suffix:
                                                                                "",
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                !_vm.isCurrency(
                                                                  key,
                                                                  entry
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        key:
                                                                          "shs3-" +
                                                                          index,
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.formatValue(
                                                                              key,
                                                                              entry
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            entry.editable !==
                                                            null
                                                              ? _c("i", {
                                                                  staticClass:
                                                                    "ion-edit",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.toggleEdit(
                                                                          entry,
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    : [
                                                        _vm.isCurrency(
                                                          key,
                                                          entry
                                                        )
                                                          ? _c(
                                                              "ampsvuecountup",
                                                              {
                                                                staticClass:
                                                                  "myCounter",
                                                                attrs: {
                                                                  decimal: 2,
                                                                  end: parseFloat(
                                                                    entry[key]
                                                                  ),
                                                                  duration: 1,
                                                                  options: {
                                                                    useEasing: true,
                                                                    useGrouping: true,
                                                                    separator:
                                                                      ",",
                                                                    decimal:
                                                                      ".",
                                                                    prefix: "",
                                                                    suffix: "",
                                                                  },
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        !_vm.isCurrency(
                                                          key,
                                                          entry
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                key:
                                                                  "shs4-" +
                                                                  index,
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.formatValue(
                                                                      key,
                                                                      entry
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                ],
                                          ],
                                          2
                                        )
                                      }),
                                      0
                                    )
                                  }),
                                  _vm._l(
                                    _vm.footer_rows,
                                    function (entry, index) {
                                      return _c(
                                        "tr",
                                        {
                                          key: "footentry-" + index,
                                          staticClass: "footer-row",
                                        },
                                        _vm._l(_vm.columns, function (key) {
                                          return _c(
                                            "td",
                                            {
                                              key: key,
                                              class: _vm.formatClass(
                                                key,
                                                entry
                                              ),
                                              attrs: { title: entry[key] },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.delegateClick(
                                                    entry,
                                                    key,
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "transition-group",
                                                { attrs: { name: "expand" } },
                                                [
                                                  key === "profile_style"
                                                    ? [
                                                        _c(
                                                          "div",
                                                          {
                                                            key: "ft1-" + index,
                                                            staticClass:
                                                              "user-thumb",
                                                            style: {
                                                              background:
                                                                entry[key],
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  entry[key]
                                                                    ? ""
                                                                    : entry[
                                                                        "PrimaryFirstName"
                                                                      ][0]
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  entry[key]
                                                                    ? ""
                                                                    : entry[
                                                                        "PrimaryLastName"
                                                                      ][0]
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    : [
                                                        key === "_PROJECTED"
                                                          ? [
                                                              _c(
                                                                "div",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        entry.editable,
                                                                      expression:
                                                                        "entry.editable",
                                                                    },
                                                                  ],
                                                                  key:
                                                                    "ft2-" +
                                                                    index,
                                                                  staticClass:
                                                                    "edit-mode",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      key:
                                                                        "ft2s1-" +
                                                                        index,
                                                                      staticClass:
                                                                        "dollar",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "$"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            entry[
                                                                              key
                                                                            ],
                                                                          expression:
                                                                            "entry[key]",
                                                                        },
                                                                      ],
                                                                    staticStyle:
                                                                      {
                                                                        "text-align":
                                                                          "right",
                                                                      },
                                                                    attrs: {
                                                                      pattern:
                                                                        "[0-9]",
                                                                      tabindex:
                                                                        "0",
                                                                      type: "text",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        entry[
                                                                          key
                                                                        ],
                                                                    },
                                                                    on: {
                                                                      keyup:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          return _vm.accept(
                                                                            entry,
                                                                            $event
                                                                          )
                                                                        },
                                                                      blur: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.accept(
                                                                          entry,
                                                                          $event
                                                                        )
                                                                      },
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.$set(
                                                                            entry,
                                                                            key,
                                                                            $event
                                                                              .target
                                                                              .value
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                  entry.editable !==
                                                                  null
                                                                    ? _c("i", {
                                                                        staticClass:
                                                                          "ion-checkmark-circled",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.toggleEdit(
                                                                                entry,
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      })
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        !entry.editable,
                                                                      expression:
                                                                        "!entry.editable",
                                                                    },
                                                                  ],
                                                                  key:
                                                                    "ft3-" +
                                                                    index,
                                                                  staticClass:
                                                                    "non-edit-mode",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      key:
                                                                        "ft3s1-" +
                                                                        index,
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            return _vm.toggleEdit(
                                                                              entry,
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "dollar",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "$"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.formatValue(
                                                                            key,
                                                                            entry
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  entry.editable !==
                                                                  null
                                                                    ? _c("i", {
                                                                        staticClass:
                                                                          "ion-edit",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.toggleEdit(
                                                                                entry,
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      })
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]
                                                          : [
                                                              _c(
                                                                "span",
                                                                {
                                                                  key:
                                                                    "ft4s-" +
                                                                    index,
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.formatValue(
                                                                        key,
                                                                        entry
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                      ],
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                            _vm.error
                              ? _c("p", { staticClass: "error" }, [
                                  _vm._v(_vm._s(_vm.error)),
                                ])
                              : _vm._e(),
                            _vm.searchnoresult
                              ? _c("p", { staticClass: "error" }, [
                                  _vm._v(_vm._s(_vm.searchnoresult)),
                                ])
                              : _vm._e(),
                            _vm.limit < _vm.filtered_result_count
                              ? [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "rd-load-more",
                                      on: {
                                        click: function ($event) {
                                          return _vm.loadMore()
                                        },
                                      },
                                    },
                                    [_vm._v("Load More")]
                                  ),
                                ]
                              : _vm._e(),
                            _c("small", { staticClass: "loading-time" }, [
                              _vm._v(_vm._s(_vm.performance)),
                            ]),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }