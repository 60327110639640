// Handle redirects
// function handleRedirects(){
//   var redirect_urls = {"researchportal.northwestern.edu" : "researchportal-ng.northwestern.edu"};
//   if (window.location.host in redirect_urls){
//     var target_url = redirect_urls[window.location.host];
//     window.location = "https://" + target_url;
//   }
// }
// handleRedirects();

// IE support for promises. Used in fetch.

import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import VueMoment from 'vue-moment'

import App from './App.vue'
import routes from './routes'
import AuthService from './auth/AuthService.js'
import Dictionary from './utils/Dictionary.js'
import es6promise from 'es6-promise'
import store from './services/CacheStore'
import lazyload from 'vue-lazyload'

es6promise.polyfill()

Vue.use(lazyload, {
  error: 'assets/logo.png',
  loading: 'assets/logo.png'
})
Vue.use(VueResource)
Vue.use(VueMoment)
Vue.use(Vuex)
Vue.use(VueRouter)

export var router = new VueRouter({routes: routes})

router.beforeEach(function (to, from, next) {
  if (to.path !== '/login') {
    localStorage.setItem('goto', to.path)
  }

  if (to.auth) {
    if (AuthService.authenticated) {
      // if the user has logged in, continue to the route.
      next()
    } else {
      // if the user has not logged in yet, redirect
      router.push({name: 'login'})
    }
  } else {
    // localStorage.removeItem('goto');
    next()
  }
})

// start the app on the #app div
Vue.config.productionTip = true

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Dictionary.load(function (err, data) {
  if (err) {
    console.log('Unable to load the dictionary')
  } else {
    // Auth Service bind to Router
    console.log('Starting authservice init')
    AuthService.init({})
    AuthService.setRouter(router)
  }
})
