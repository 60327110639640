var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("keep-alive", [
    _c(
      "div",
      { staticClass: "protocols" },
      [
        _c("mytable", {
          attrs: {
            "keep-alive": "",
            title: "IACUC Protocols",
            selection: _vm.data.iacucsel,
            columns: _vm.data.columns,
            store: _vm.data.iacuc,
            loading: _vm.data.loading,
            performance: _vm.data.performance,
            error: _vm.data.iacucerror,
          },
        }),
        _c("mytable", {
          attrs: {
            "keep-alive": "",
            title: "IRB Protocols",
            selection: _vm.data.irbsel,
            columns: _vm.data.columns,
            store: _vm.data.irb,
            loading: _vm.data.loading,
            performance: _vm.data.performance,
            error: _vm.data.irberror,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }