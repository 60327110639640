import Projects from './Projects.vue'
import Sponsored from './Sponsored.vue'
import Overview from './Overview.vue'

import ExpensesByMonth from './ExpensesByMonth.vue'
import ExpensesByMonthDetails from './ExpensesByMonthDetails.vue'

import InceptionToDate from './InceptionToDate.vue'
import InceptionToDateDetails from './InceptionToDateDetails.vue'

import People from './People.vue'

import CostShare from './CostShare.vue'
import Payments from './Payments.vue'
import Subcontractor from './Subcontractor.vue'
import SubcontractorDetail from './SubcontractorDetail.vue'
import SubcontractorPO from './SubcontractorPO.vue'
import SubcontractsProposal from './SubcontractsProposal.vue'

export default {
  Projects: Projects,
  Sponsored: Sponsored,
  Overview: Overview,
  InceptionToDate: InceptionToDate,
  InceptionToDateDetails: InceptionToDateDetails,
  ExpensesByMonth: ExpensesByMonth,
  ExpensesByMonthDetails: ExpensesByMonthDetails,
  People: People,
  CostShare: CostShare,
  Payments: Payments,
  Subcontractor: Subcontractor,
  SubcontractorDetail: SubcontractorDetail,
  SubcontractorPO: SubcontractorPO,
  SubcontractsProposal: SubcontractsProposal
}
