<template>
  <keep-alive>
    <div>
      <div v-if="tryagain" class="modal-mask download-modal">
        <transition name="modal">
          <div v-on-clickaway="hideTryagain()" class="modal-container">
            <div class="modal-header">
              <div class="modal-close" @click="hideTryagain()">Close</div>
            </div>
            <div class="modal-body transaction">
              <p>Data load timed out. Please, refresh page.</p>
            </div>
            <div class="modal-footer">
              <button class="rd-small-button feed_close" @click="hideTryagain()">Ok</button>
            </div>
          </div>
        </transition>
      </div>
      <div class="metadata-block">
        <template v-if="AuthService.getUser().data.uid !== pi_netid">
          <div class="usercard-wrapper">
            <usercard v-bind:netid="pi_netid" />
          </div>
        </template>
        <template v-if="AuthService.getUser().data.uid === pi_netid">
          <h2>My People</h2>
        </template>

        <div v-if="employeeID" class="download-actions">
          <button v-bind:class="{'working':downloading.xlsx }" class="rd-download excel" @click="download('xlsx')">DOWNLOAD SALARY DETAILS</button>
        </div>
        <div v-else class="download-actions">
          <transactiondownload request="allSalary" />
        </div>
      </div>
      <peoplelisting />
    </div>
  </keep-alive>
</template>

<script>
import {DataExportService} from '../../services/'
import usercard from '../_usercard.vue'
import AuthService from '../../auth/AuthService.js'
import {DateUtils} from '../../utils/'
import peoplelisting from './PeopleListing.vue'
import Wildcat from '../projects/Wildcat.json'
import { directive as onClickaway } from 'vue-clickaway'
import transactiondownload from '../TransactionDownload.vue'

export default {
  directives: {
    onClickaway: onClickaway
  },
  components: {
    usercard: usercard,
    transactiondownload: transactiondownload,
    peoplelisting: peoplelisting
  },
  data () {
    return {
      pi_netid: this.$route.params.netid,
      AuthService: AuthService,
      employeeID: null,
      tryagain: false,
      downloading: {
        'xlsx': false,
        'docx': false,
        'pdf': false
      },
      wasDemo: false
    }
  },
  mounted () {
    this.wasDemo = localStorage.getItem('prevWasWildcat')
    // if (this.$route.params.netid === 'wildcat') {
    if (this.wasDemo === 'true') {
      console.warn('using fake user')
      this.netid = Wildcat.effort.sponsored.NETID // real-user to be used as a stand-in for wildcat
      this.employeeID = this.$route.params.eid
    } else {
      this.netid = this.$route.params.netid
      this.employeeID = this.$route.params.eid
    }
  },
  methods: {
    hideTryagain () {
      this.tryagain = false
    },
    showTryagain () {
      this.tryagain = true
    },
    pad (value, length) {
      var res = (value.toString().length < length) ? this.pad('0' + value, length) : value
      return res
    },
    download (format) {
      var valid_formats = ['xlsx']
      if (valid_formats.indexOf(format) < 0) {
        return
      }
      // calculate the date range to retrieve
      var now = new Date()
      var toYear = now.getFullYear()
      var toMonth = now.getMonth() + 1
      // add 4 to align with the fiscal period
      var out_d = DateUtils.addMonths(toYear, toMonth, 0)
      toYear = out_d[0]
      toMonth = out_d[1]

      var d = DateUtils.addMonths(toYear, toMonth, -36) // 3 years
      var fromYYYYMM = '' + d[0] + this.pad(d[1], 2)
      var toYYYYMM = '' + toYear + this.pad(toMonth, 2)
      var opts = {
        project_type: 'people-salary',
        report_name: 'era-rp-effort',
        p_EmplID: this.employeeID,
        p_FromYYYYMM: fromYYYYMM,
        p_ToYYYYMM: toYYYYMM
      }

      var that = this
      this.downloading[format] = true
      DataExportService.get(opts).then(data => {
        that.downloading[format] = false
        if (data.match(/^https/)) {
          window.location.href = data
        } else {
          console.warn('Invalid download URL', data)
        }

      }).catch(err => {
        console.warn('Unable to download', err)
        that.downloading[format] = false
        that.showTryagain()
      })
    }
  }
}

</script>
