<template>
  <div style="display: flex">
    <template v-for="(opt, index) in options">
      <div v-bind:key="index">
        <button class="rd-small-button" v-bind:class="[opt.class, (activeindex === index ? 'active' : '')]" @click="selectOpt(index, opt)"><span>{{ opt.text }}</span></button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    options: {type: Array, default: null},
    activeindex: {type: Number, default: null}
  },
  methods: {
    selectOpt (index, opt) {
      // eslint-disable-next-line
      this.activeindex = index
      this.$emit('update-table-toggle', opt.value)
    }
  }
}
</script>
<style lang="scss" />
