import Vue from 'vue'
import AuthService from '../auth/AuthService.js'
import {QueryBuilder} from '../utils/'
import VueResource from 'vue-resource'
Vue.use(VueResource)

export default {
  get (data, store) {
    var user = AuthService.getUser()
    if (!user && data.p_Service !== 'RD015') {
      console.warn('User is not logged in. Bailing')
      return null
    }

    var path = (data.path || 'selection')
    var BASE_URL = process.env.VUE_APP_API_URL + '/' + path

    var params = QueryBuilder.build(data)
    var url = BASE_URL + '?' + params

    // store is available at the 'app' level.
    if (data.path !== 'transactions') {
      var cache = store.state.reports[url]
      cache = cache ? JSON.parse(cache) : cache
      if (cache) {
        // GOT Cache!
        var promise = new Promise((resolve, reject) => { resolve(cache) })
        return promise
      }
    }

    // add sso token
    var token = AuthService.getSSOToken()
    var signed_url
    if (token === 'null') { signed_url = url } else { signed_url = url + '&sso_token=' + token }
    // console.log('thisURL:', signed_url)
    // var that = this

    return fetch(signed_url)
      .then(response => { return response.json() })
      .then(data => {
        if (data.errorMessage === 'UNAUTHORIZED') {
          AuthService.logout()
          return {rows: [], columns: []}
        }
        store.commit('set', url, data)
        return data

      // check for error if contains status EX...
      // if happens AuthService.logout();
      // dont forget import
      // use two tabs to test, logout in one tab and opt in another to see test results
      // However, the behavior cannot be tested. If the right token is not sent, the returning data is a websso login page
      }).catch(err => {
        return {error: 'Data load timed out. Please, refresh page. ' + err}
      })
  },
  formatResponseRD000 (response) {
    if (response.filterResultSet) {
      var filterResult = response.filterResultSet.filterResult[0]

      var items = filterResult.reportElement[0].body.item[0].lst.group.row[0].cell[0].item
      return items
    }
  },
  formatResponseRD009 (response) {
    console.warn('DEPRECATED')

    // if (response.filterResultSet) {
    //   var filterResult = response.filterResultSet.filterResult
    //   // check if array
    //   var reportEl = filterResult[0].reportElement
    //   // check if array
    //   var item = reportEl[0].page.body.item
    //   // check for 'No Data Available'
    //   var NO_DATA_AVAILABLE = 'No Data Available'
    //   if ('txt' in item[0]) {
    //     if (item[0].txt.val === NO_DATA_AVAILABLE) {
    //       return {
    //         // headers: [],
    //         rows: [],
    //         error: NO_DATA_AVAILABLE
    //       }
    //     }
    //   }

    //   // check if array
    //   var columns = item[0].lst.colTitle.map(function (title) {
    //     return title.item[0].txt.val
    //   })
    //   // check if array
    //   var _row = 'row' in item[0].lst.group ? item[0].lst.group.row : item[0].lst.group.grp[0].row
    //   var rowValues = _row.map(function (row) {
    //     return row.cell.map(function (cell_it) {
    //       return cell_it.item[0].txt.val
    //     })
    //   })

    //   var rows = []
    //   rowValues.forEach((rowVal, idx) => {
    //     var row = {}
    //     columns.forEach((val, idx) => {
    //       var key = columns[idx]
    //       row[key] = rowVal[idx]
    //     })
    //     rows.push(row)
    //   })

    //   var result = {
    //     headers: columns,
    //     rows: rows
    //   }
    //   return result
    // }
    // return null
  },
  formatResponse (response) {
    if (response.filterResultSet) {
      var filterResult = response.filterResultSet.filterResult
      // check if array
      var reportEl = filterResult[0].reportElement
      // check if array
      var item = reportEl[0].page.body.item
      // check for 'No Data Available'
      var NO_DATA_AVAILABLE = 'No Data Available'
      if ('txt' in item[0]) {
        if (item[0].txt.val === NO_DATA_AVAILABLE) {
          return {
            // headers: [],
            rows: [],
            error: NO_DATA_AVAILABLE
          }
        }
      }

      // check if array
      var columns = item[0].lst.colTitle.map(function (title) {
        return title.item[0].txt.val
      })
      // check if array
      var _row = 'row' in item[0].lst.group ? item[0].lst.group.row : item[0].lst.group.grp[0].row
      var rowValues = _row.map(function (row) {
        return row.cell.map(function (cell_it) {
          return cell_it.item[0].txt.val
        })
      })

      var rows = []
      rowValues.forEach((rowVal, idx) => {
        var row = {}
        columns.forEach((val, idx) => {
          var key = columns[idx]
          row[key] = rowVal[idx]
        })
        rows.push(row)
      })

      var result = {
        headers: columns,
        rows: rows
      }
      return result
    }
    return null
  }
}
