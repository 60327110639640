var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("keep-alive", [
    _c(
      "div",
      { staticClass: "sponsored-projects" },
      [
        _c("mytable", {
          attrs: {
            "keep-alive": "",
            title: "Sponsored Projects",
            toggledata: _vm.selectdata,
            columns: _vm.data.columns,
            store: _vm.data.rows,
            labels: _vm.data.labels,
            sortkey: "ProjectID",
            sortdir: 1,
            sortorders: { ProjectID: 1 },
            loading: _vm.data.loading,
            performance: _vm.data.performance,
            error: _vm.data.error,
          },
          on: { "update-selectdata": _vm.selectOpt },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }