var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "dir-btn prev page-top",
        on: {
          click: function ($event) {
            return _vm.backToPortfolio()
          },
        },
      },
      [_vm._v("Back to " + _vm._s(_vm.$route.params.netid) + "'s protocols")]
    ),
    _c(
      "div",
      { staticClass: "metadata-block" },
      [
        _vm.data.loading
          ? [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    width: "100%",
                    height: "40px",
                  },
                },
                [_c("loading")],
                1
              ),
            ]
          : _vm._e(),
        _c("div", { staticClass: "metadata-flex-wrap" }, [
          _c("div", { staticClass: "sponsored-project-metadata" }, [
            _c("ul", [
              _c("li", { attrs: { title: _vm.formatTitle("protocol_id") } }, [
                _c("div", { staticClass: "key" }, [
                  _vm._v(_vm._s(_vm.formatLabel("protocol_id"))),
                ]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.data.rows["protocol_id"])),
                ]),
              ]),
              _c("li", { attrs: { title: _vm.formatTitle("pi_last_name") } }, [
                _c("div", { staticClass: "key" }, [
                  _vm._v(_vm._s(_vm.formatLabel("pi_last_name"))),
                ]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.data.rows["pi_last_name"])),
                ]),
              ]),
              _c("li", { attrs: { title: _vm.formatTitle("pi_first_name") } }, [
                _c("div", { staticClass: "key" }, [
                  _vm._v(_vm._s(_vm.formatLabel("pi_first_name"))),
                ]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.data.rows["pi_first_name"])),
                ]),
              ]),
              _c(
                "li",
                { attrs: { title: _vm.formatTitle("protocol_status") } },
                [
                  _c("div", { staticClass: "key" }, [
                    _vm._v(_vm._s(_vm.formatLabel("protocol_status"))),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.data.rows["protocol_status"])),
                  ]),
                ]
              ),
              _c(
                "li",
                { attrs: { title: _vm.formatTitle("expiration_date") } },
                [
                  _c("div", { staticClass: "key" }, [
                    _vm._v(_vm._s(_vm.formatLabel("expiration_date"))),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(_vm.formatValue("expiration_date", _vm.data.rows))
                    ),
                  ]),
                ]
              ),
              _c(
                "li",
                { attrs: { title: _vm.formatTitle("dept_chart_actt") } },
                [
                  _c("div", { staticClass: "key" }, [
                    _vm._v(_vm._s(_vm.formatLabel("dept_chart_actt"))),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(_vm.formatValue("dept_chart_actt", _vm.data.rows))
                    ),
                  ]),
                ]
              ),
              _c("li", { attrs: { title: _vm.formatTitle("grant_number") } }, [
                _c("div", { staticClass: "key" }, [
                  _vm._v(_vm._s(_vm.formatLabel("grant_number"))),
                ]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(_vm.formatValue("grant_number", _vm.data.rows))
                  ),
                ]),
              ]),
              _c(
                "li",
                { attrs: { title: _vm.formatTitle("funding_information") } },
                [
                  _c("div", { staticClass: "key" }, [
                    _vm._v(_vm._s(_vm.formatLabel("funding_information"))),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatValue("funding_information", _vm.data.rows)
                      )
                    ),
                  ]),
                ]
              ),
              _c("li", { attrs: { title: _vm.formatTitle("funding_other") } }, [
                _c("div", { staticClass: "key" }, [
                  _vm._v(_vm._s(_vm.formatLabel("funding_other"))),
                ]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(_vm.formatValue("funding_other", _vm.data.rows))
                  ),
                ]),
              ]),
              _c(
                "li",
                { attrs: { title: _vm.formatTitle("initial_approval_date") } },
                [
                  _c("div", { staticClass: "key" }, [
                    _vm._v(_vm._s(_vm.formatLabel("initial_approval_date"))),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatValue("initial_approval_date", _vm.data.rows)
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "li",
                { attrs: { title: _vm.formatTitle("last_approval_date") } },
                [
                  _c("div", { staticClass: "key" }, [
                    _vm._v(_vm._s(_vm.formatLabel("last_approval_date"))),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatValue("last_approval_date", _vm.data.rows)
                      )
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }