<template>
  <div v-on-clickaway="clickAway" class="datepicker">
    <a v-bind:class="preClass" @click="addMonth(-1)">Prev</a>
    <a class="dp-btn pick" v-bind:title="datepickerdata.selectedMonth" @click="toggleMonth()">{{ datepickerdata.months[parseInt(datepickerdata.selectedMonth)] }}</a>
    <a class="dp-btn pick" @click="toggleYear()">{{ datepickerdata.years[datepickerdata.selectedYear] }}</a>
    <a v-bind:class="nextClass" @click="addMonth(1)">Next</a>

    <template v-if="showYear">
      <ul class="dp-drawer">
        <li v-for="(year, index) in datepickerdata.years" v-bind:key="index">
          <button v-bind:class="{'active':index===datepickerdata.selectedYear}" class="dp-btn grid" @click="selectYear(index)">{{ year }}</button>
        </li>
      </ul>
    </template>

    <template v-if="showMonth">
      <ul class="dp-drawer">
        <li v-for="(month, index) in datepickerdata.months" v-bind:key="index">
          <button v-bind:class="{'active': index===datepickerdata.selectedMonth}" class="dp-btn grid" @click="selectMonth(index)">{{ month }}</button>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'
import {DateUtils} from '../utils/'

export default {
  directives: {
    onClickaway: onClickaway
  },
  props: {
    datepickerdata: {type: Object, default: null},
    options: {type: Object, default: null},
    idx: {type: Number, default: null}
  },
  data () {
    return {
      preClass: 'dir-btn prev',
      nextClass: 'dir-btn next',
      showOptions: false,
      showYear: false,
      showMonth: false
    }
  },
  watch: {
    'datepickerdata.selectedYear': {
      handler: function (newVal, oldVal) {
        var now = new Date()
        var month = now.getMonth()
        this.preClass = 'dir-btn prev'
        this.nextClass = 'dir-btn next'
        if (this.datepickerdata.selectedYear === 0 && this.datepickerdata.selectedMonth <= month) {
          this.preClass = 'dir-btn prev disabled'
        }
        if (this.datepickerdata.selectedYear === this.datepickerdata.years.length - 1 && this.datepickerdata.selectedMonth >= month) {
          this.nextClass = 'dir-btn next disabled'
        }
      }
    },
    'datepickerdata.selectedMonth': {
      handler: function (newVal, oldVal) {
        var now = new Date()
        var month = now.getMonth()
        this.preClass = 'dir-btn prev'
        this.nextClass = 'dir-btn next'
        if (this.datepickerdata.selectedYear === 0 && this.datepickerdata.selectedMonth <= month) {
          this.preClass = 'dir-btn prev disabled'
        }
        if (this.datepickerdata.selectedYear === this.datepickerdata.years.length - 1 && this.datepickerdata.selectedMonth >= month) {
          this.nextClass = 'dir-btn next disabled'
        }
      }
    }
  },
  mounted () {
    this.nextClass = 'dir-btn next disabled'
  },
  methods: {
    clickAway ($event) {
      this.showYear = false
      this.showMonth = false
    },
    toggleYear (state) {
      this.showYear = state === undefined ? !this.showYear : state
    },
    toggleMonth (state) {
      this.showMonth = state === undefined ? !this.showMonth : state
    },
    selectMonth (idx) {
      this.datepickerdata.selectedMonth = idx
      this.selectDate()
      this.toggleMonth(false)
    },
    selectYear (idx) {
      this.datepickerdata.selectedYear = idx
      this.selectDate()
      this.toggleYear(false)
    },
    getPreClass () {
      var toYear
      var now = new Date()
      var toMonth = now.getMonth() + 1
      // add 4 to align with the fiscal period
      var out_d = DateUtils.addMonths(toYear, toMonth, 4)
      var month = out_d[1]
      if (this.datepickerdata.selectedYear === 0 && this.years.selectedMonth <= month - 1) {
        return 'dir-btn prev disabled'
      }
      return 'dir-btn prev'
    },
    getNextClass () {
      var now = new Date()
      var toYear = now.getFullYear()
      var toMonth = now.getMonth() + 1
      // add 4 to align with the fiscal period
      var out_d = DateUtils.addMonths(toYear, toMonth, 4)
      var month = out_d[1]
      if (this.datepickerdata.selectedYear === this.datepickerdata.years.length - 1 && this.datepickerdata.selectedMonth >= month - 1) {
        return 'dir-btn next disabled'
      }
      return 'dir-btn next'
    },
    selectDate () {
      var yyyy = this.datepickerdata.years[this.datepickerdata.selectedYear]
      var mm = this.datepickerdata.selectedMonth
      var now = new Date()
      var toMonth = now.getMonth() + 1

      this.preClass = 'dir-btn prev'
      this.nextClass = 'dir-btn next'
      if (this.datepickerdata.selectedYear === this.datepickerdata.years.length - 1 && this.datepickerdata.selectedMonth >= toMonth - 1) {
        this.nextClass = 'dir-btn next disabled'
        mm = toMonth - 1
      }
      if (this.datepickerdata.selectedYear === 0 && this.datepickerdata.selectedMonth <= toMonth - 1) {
        this.preClass = 'dir-btn prev disabled'
        mm = toMonth - 1
      }

      this.datepickerdata.selectedMonth = mm

      if (this.$parent.selectDate) {
        this.$parent.selectDate(yyyy, mm)
      }
    },
    addMonth (n_months) {
      if (this.nextClass === 'dir-btn next disabled' && n_months > 0) {
        return
      }
      if (this.preClass === 'dir-btn prev disabled' && n_months < 0) {
        return
      }
      var new_month = this.datepickerdata.selectedMonth + n_months
      if (new_month >= 12) {
        this.datepickerdata.selectedMonth = 0
        this.datepickerdata.selectedYear++
      } else {
        this.datepickerdata.selectedMonth = new_month
      }
      if (new_month < 0) {
        this.datepickerdata.selectedMonth = 11
        this.datepickerdata.selectedYear--
      }
      this.selectDate()
    },
    addMonths (year, month, n_months) {
      month += n_months
      while (month > 12) {
        year++
        month -= 12
      }
      while (month < 1) {
        year--
        month += 12
      }
      return [year, month]
    }
  }
}
</script>
<style lang="scss" />
