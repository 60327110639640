<template>
  <!-- keep-alive="false" -->
  <div>
    <div v-if="tryagain" class="modal-mask download-modal">
      <transition name="modal">
        <div v-on-clickaway="hideTryagain()" class="modal-container">
          <div class="modal-header">
            <div class="modal-close" @click="hideTryagain()">Close</div>
          </div>
          <div class="modal-body transaction">
            <p>Data load timed out. Please, refresh page.</p>
          </div>
          <div class="modal-footer">
            <button class="rd-small-button feed_close" @click="hideTryagain()">Ok</button>
          </div>
        </div>
      </transition>
    </div>

    <button class="dir-btn prev page-top" @click="backToPortfolio()" ref="backBtn">Back to {{ $route.params.netid }}'s Portfolio</button>

    <div class="metadata-block">
      <h1>{{ data.rows['ProjectName'] }} {{ '(Project ID: ' + (data.rows['ProjectID'] || '') + ')' }}</h1>
      <template v-if="data.loading">
        <div style="position:relative;width:100%;height:40px">
          <loading />
        </div>
      </template>

      <div class="metadata-flex-wrap">
        <div class="sponsored-project-metadata">
          <ul>
            <li v-bind:title="formatTitle('ProjectSponsor','SponsoredProjectHeader')">
              <div class="key">Direct Sponsor</div><div class="value">{{ data.rows['ProjectSponsor'] }}</div>
            </li>
            <li v-bind:title="formatTitle('OriginatingSponsor','SponsoredProjectHeader')">
              <div class="key">Originating Sponsor</div><div class="value">{{ data.rows['OriginatingSponsor'] }}</div>
            </li>
            <li v-bind:title="formatTitle('ASRSPGCFA','SponsoredProjectHeader')">
              <div class="key">ASRSP GCFA</div>
              <div class="value">{{ data.rows['ASRSPGCFA'] }}</div>
            </li>
            <li v-bind:title="formatTitle('PrimaryProjectPIName','SponsoredProjectHeader')">
              <div class="key">Primary PI</div>
              <div class="value">{{ data.rows['PrimaryProjectPIName'] }}</div>
            </li>
            <li v-bind:title="formatTitle('ResearchAdministrator','SponsoredProjectHeader')">
              <div class="key">Research Administrator</div>
              <div class="value">{{ data.rows['ResearchAdministrator'] }}</div>
            </li>

            <li v-bind:title="formatTitle('ManagingDepartmentDescription','SponsoredProjectHeader')">
              <div class="key">{{ formatLabel("ManagingDepartmentDescription", "SponsoredProjectHeader") }}</div>
              <div class="value">{{ data.rows['ManagingDepartmentDescription'] }}</div>
            </li>

            <li v-bind:title="formatTitle('AwardID','SponsoredProjectHeader')">
              <div class="key">Award ID</div>
              <div class="value">{{ data.rows['AwardID'] }}</div>
            </li>
            <li v-bind:title="formatTitle('SponsorReferenceAwardNumber','SponsoredProjectHeader')">
              <div class="key">Sponsor Reference Award #</div>
              <div class="value">{{ data.rows['SponsorReferenceAwardNumber'] }}</div>
            </li>
            <li v-bind:title="formatTitle('DATE','SponsoredProjectHeader')">
              <div class="key">Project Dates</div>
              <div class="value">{{ formatValue("DATE", data.rows) }}</div>
            </li>
            <li v-bind:title="formatTitle('TotalPaymentsApplied','SponsoredProjectHeader')">
              <div class="key">Total Payments Applied</div>
              <div class="value special-currency">{{ formatValue("TotalPaymentsApplied", data.rows) }}</div>
            </li>
            <li v-show="hasCT" title="formatTitle('ClinicalTrailBalance','SponsoredProjectHeader')">
              <div class="key">Clinical Trial Balance</div>
              <div class="value special-currency">{{ formatValue("ClinicalTrailBalance", data.rows) }}</div>
            </li>

            <li v-bind:title="formatTitle('FandARate','SponsoredProjectHeader')">
              <div class="key">{{ formatLabel('FandARate','SponsoredProjectHeader') }}</div>
              <div class="value">{{ formatValue("FandARate", data.rows) }}</div>
            </li>
            <li v-bind:title="formatTitle('_CHARTSTRING','SponsoredProjectHeader')">
              <div class="key">{{ formatLabel('_CHARTSTRING','SponsoredProjectHeader') }}</div>
              <div class="value">{{ formatValue("_CHARTSTRING", data.rows) }}</div>
            </li>

            <li>
              <br>
            </li>
            <li v-bind:title="formatTitle('CarryForwardAllowed','SponsoredProjectHeader')">
              <div class="key">Carry Over</div>
              <div class="value"><b>{{ data.rows['CarryForwardAllowed'] }}</b></div>
            </li>

            <li v-bind:title="formatTitle('LastUpdateTime')">
              <div class="key">Last Update Time</div>
              <div class="value"><b>{{ ts }}</b></div>
            </li>
          </ul>
        </div>

        <div class="download-actions">
          <button v-bind:class="{'working':downloading.xlsx }" class="rd-download excel" @click="exportSponsoredProject('xlsx')">DOWNLOAD PROJECT DATA</button>
          <transactiondownload />
        </div>
      </div>

      <div v-if="netid" class="tabs">
        <ul class="nav nav-pills">
          <router-link v-bind:to="{ name: 'Project_Sponsored_InceptionToDate', params: { netid: netid, id: id }}" tag="li" role="presentation">
            <a class="finoverview tab">Financial Overview</a>
          </router-link>

          <router-link v-bind:to="{ name: 'Project_Sponsored_People', params: { netid: netid, id: id }}" tag="li" role="presentation">
            <a class="people tab">Project Team</a>
          </router-link>

          <router-link v-bind:to="{ name: 'Sponsored_CostShare', params: { netid: netid, id: id }}" tag="li" role="presentation">
            <a class="costshare tab">Cost Share Detail</a>
          </router-link>

          <router-link v-bind:to="{ name: 'Sponsored_Payments', params: { netid: netid, id: id }}" tag="li" role="presentation">
            <a class="payments tab">Payments</a>
          </router-link>

          <router-link v-bind:to="{ name: 'Sponsored_Subcontractor', params: { netid: netid, id: id }}" tag="li" role="presentation">
            <a class="subcontractor tab">Subcontracts</a>
          </router-link>
        </ul>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import {ReportService, DataExportService} from '../../../services/'
import {Dictionary, Timer} from '../../../utils/'
import loading from '../../viz/loading.vue'
import transactiondownload from '../../TransactionDownload.vue'
import Wildcat from '../Wildcat.json'
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {
    onClickaway: onClickaway
  },
  components: {
    transactiondownload: transactiondownload,
    loading: loading
  },
  data () {
    return {
      hasCT: true,
      tryagain: false,
      netid: '',
      id: 0,
      ts: '',
      project: {
        title: 'Foo'
      },
      data: {
        columns: ['ProjectID', 'ProjectSponsor', 'OriginatingSponsor', 'ASRSPGCFA', 'PrimaryProjectPIName', 'ResearchAdministrator', 'AwardID', 'SponsorReferenceAwardNumber', 'DATE', 'CarryForwardAllowed', 'TotalPaymentsApplied', 'LastUpdateTime', 'ClinicalTrailBalance'],
        rows: [],
        loading: false
      },
      downloading: {
        'xlsx': false,
        'docx': false,
        'pdf': false
      },
      wasDemo: false
    }
  },
  mounted () {
    this.wasDemo = localStorage.getItem('prevWasWildcat')
    if (this.wasDemo === 'true') {
      this.netid = Wildcat.sponsored.NETID
      this.id = Wildcat.sponsored.PROJECT_ID
    } else {
      this.netid = this.$route.params.netid // @TODO: Sanitize
      this.id = this.$route.params.id // @TODO: Sanitize
    }
    if (this.wasDemo === 'true') {
      this.$refs.backBtn.innerText = 'Back to Wildcat\'s Portfolio'
    } else {
      this.$refs.backBtn.innerText = 'Back to ' + this.$route.params.netid + '\'s Portfolio'
    }
    this.project.title = ''
    this.load()
  },
  methods: {
    hideTryagain () {
      this.tryagain = false
    },
    showTryagain () {
      this.tryagain = true
    },
    formatTitle (key, selection) {
      return Dictionary.formatTitle(key, selection)
    },
    formatLabel (key, selection) {
      return Dictionary.formatLabel(key, selection)
    },
    exportSponsoredProject (format) {
      var opts = {
        report_name: 'era-rp-main',
        project_type: 'sponsored',
        p_PrjID: this.id
      }

      var that = this
      if (this.downloading[format]) {
        console.log('Already downloading. wait for it to complete')
        return
      }
      this.downloading[format] = true
      DataExportService.get(opts).then(data => {
        that.downloading[format] = false
        // @TODO: Check browser support for this.
        if (typeof data !== 'string') {
          console.log('Error generating the download', data)
          that.showTryagain()
        } else if (typeof data === 'string' && data.match(/^https/)) {
          window.location.href = data
        } else {
          console.log('Invalid Download URL', data)
        }
        // Download(data);
        // trigger download
      }).catch(err => {
        console.log('Unable to download', err)
        that.downloading[format] = false
        that.showTryagain()
      })
    },
    backToPortfolio () {
      var path = '/projects/' + (this.wasDemo === 'true' ? 'wildcat' : this.$route.params.netid) + '/sponsored'
      localStorage.setItem('prevWasWildcat', false)
      this.$router.push({path: path})
    },
    formatValue (key, row) {
      return Dictionary.formatValue(key, row)
    },
    load () {
      var timer = new Timer()
      this.data.loading = true
      var that = this

      var lut_opts = {
        path: 'etlstatus'
      }

      ReportService.get(lut_opts, this.$store).then((data) => {
        var _ts = [
          new Date(data.rows[0].LastUpdateTime),
          ' (' + data.rows[0].BuildIndicatorDescription + ')'
        ].join('')
        that.ts = _ts

      }).catch(err => {
        that.data.loading = false
        that.error = err
      })

      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD006',
        p_PrjID: this.id
      }
      ReportService.get(report_opts, this.$store).then(data => {
        that.data.loading = false
        if ('error' in data) {
          that.data.error = data.error
        } else {
          // that.data.columns = data.headers;
          that.data.rows = data.rows[0] || []

          if (this.wasDemo === 'true') {
            that.data.rows['TotalPaymentsApplied'] = 1500.42 + Math.round(Math.random() * 1000)
            that.data.rows['ClinicalTrailBalance'] = 1750.42 + Math.round(Math.random() * 1000)
            that.data.rows['DefaultFund'] = 420 + Math.round(Math.random() * 10)
            that.data.rows['ManagingDeptID'] = 42 * 100000 + Math.round(Math.random() * 100000)
            that.data.rows['ProjectID'] = 42 * 1000000 + Math.round(Math.random() * 1000000)
            that.data.rows['AwardID'] = 'SP1234567'
          }
          // compute the chart string.
          var chartstring = this.wasDemo === 'false' ? [that.data.rows['DefaultFund'], that.data.rows['ManagingDeptID'], that.data.rows['ProjectID']].join('-') : '123-1234567-12345678'

          that.data.rows['_CHARTSTRING'] = chartstring
          that.data.rows['LastUpdateTime'] = that.ts

          if (that.data.rows['ClinicalTrailBalance'] === '0') {
            that.hasCT = false
          }
        }
        timer.tick('loaded in ')
        that.data.performance = timer.toString()
      }).catch(err => {
        that.data.loading = false
        console.log('Unable to parse the results', err)
      })
    }
  }
}

</script>
<style lang="scss">

.tabs {
  .router-link-active {
    font-weight : bold;
  }
}
</style>
