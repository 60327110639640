
export default {
  pages: [],
  get (page_name) {
    return this.pages.filter(page => page.name === page_name).pop()
  },
  remove (page_name) {
    for (var i = 0; i < this.pages.lenght;i++) {
      if (this.pages[i].name === page_name) {
        this.pages.splice(i, 1)
      }
    }
  },
  set (page) {
    this.remove(page.name)
    this.pages.push(page)
    if (this.validate()) {
      this.store()
    }
  },
  validate () {
    // @TODO: implement
    return true
  },
  store () {
    console.log('Stored pages')
    localStorage.setItem('pages', JSON.stringify(this.pages))
    console.log(this.pages)
  },
  reset () {
    localStorage.removeItem('pages')
  },
  load () {
    var cache_json = localStorage.getItem('pages')
    if (cache_json) {
      this.pages = JSON.parse(cache_json)
    } else {
      // create random page
      var page = {
        name: 'help',
        title: 'Help',
        body: '<h3>hello</h3><ul><li>a</li><li>b</li></ul>',
        changed: Date.now(),
        author: 'abc123'
      }
      this.pages.push(page)
    }
  }
}
