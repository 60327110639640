var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.option === "tips",
        expression: 'option==="tips"',
      },
    ],
    attrs: { id: "tip_iframe_holder" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }