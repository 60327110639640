var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("keep-alive", [
    _c(
      "div",
      [
        _c("mytable", {
          staticClass: "people-salary",
          attrs: {
            title: _vm.title,
            datepickerdata: _vm.datepickerdata,
            columns: _vm.data.columns,
            store: _vm.data.rows,
            sortkey: "EmployeeName",
            sortdir: 1,
            sortorders: { EmployeeName: 1 },
            footer_rows: _vm.data.footer_rows,
            labels: _vm.data.labels,
            loading: _vm.data.loading,
            performance: _vm.data.performance,
            error: _vm.data.error,
          },
        }),
        _c("small", [
          _vm._v(
            "* Earned monthly payroll amount and percent of base salary. Shows when pay was earned not when paycheck or journal was processed. See Help for more information."
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }