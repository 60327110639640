<template>
  <div>
    <div v-if="feed_modal_expanded" class="modal-mask feedback-modal">
      <transition name="modal">
        <div class="modal-wrapper">
          <div v-on-clickaway="myClickAway" class="modal-container">
            <div class="modal-header">
              <h2>Provide Feedback</h2>
              <div class="modal-close" @click="cancel()">Close</div>
            </div>
            <div class="modal-body feedback">
              <p>Please provide your feedback below:</p>
              <form class="web-form">
                <div class="field">
                  <label for="subject"><strong>Subject</strong></label>
                  <input v-model="feedback.subject" autofocus name="subject" class="feedback-input" placeholder="Enter a subject here (optional)">
                </div>
                <div class="field">
                  <label for="comments"><strong>Comments</strong></label>
                  <textarea id="comments" v-model="feedback.body" name="comments" placeholder="Enter your comment here..." />
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <span class="rd-submit-status">{{ status }}</span>
              <button class="rd-submit-button" v-bind:class="{'submitted':validated==1}" v-bind:disabled="validated==1 ? true: false" @click="send()">Submit</button>
              <button class="rd-small-button feed_close" @click="cancel()">Close</button>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <template v-if="AuthService.authenticated">
      <div class="feedback-button-wrapper">
        <button id="show-modal" class="rd-small-button feed_feedback" @click.stop="openModal()">Provide Feedback</button>
      </div>
    </template>
  </div>
</template>

<script>

import AuthService2 from '../auth/AuthService.js'
import {FeedbackService} from '../services/'
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {
    onClickaway: onClickaway
  },
  data () {
    return {
      AuthService: AuthService2,
      feed_modal_expanded: false,
      modal_expanded: false,
      status: '',
      validated: 0,
      feedback: {
        subject: '',
        body: ''
      }
    }
  },
  mounted () {
  },
  methods: {
    hideModal () {
      this.feed_modal_expanded = false
    },
    openModal () {
      this.feed_modal_expanded = true
      var $input = document.querySelector('.feedback-input')
      if ($input) {
        $input.focus()
      }
    },
    toggleModal () {
      console.log('toggle')
      this.feed_modal_expanded = !this.feed_modal_expanded
    },
    myClickAway () {
      console.log('click away')

      if (this.feed_modal_expanded) {
        this.hideModal()
      }
    },
    close () {
      this.hideModal()
    },
    collectData () {
      var data = {
        subject: this.feedback.subject,
        body: this.feedback.body,
        url: window.location.href,
        browser: window.navigator.userAgent
      }
      return data
    },
    clearForm () {
      this.feedback.subject = ''
      this.feedback.body = ''
    },
    send () {
      var data = this.collectData()
      console.log('sending', data)
      var that = this
      this.status = 'Sending...'
      FeedbackService.send(data)
        .then(response => {
          console.log('r', response)
          that.status = 'Your feedback was submitted. Thank You!'
          this.validated = 1
          // that.clearForm();

        // this.hideModal();
        }).catch(err => {
          console.log(err)
          this.validated = 0
          that.status = 'Unable to send feedback. Please try again.'
        })
    },
    cancel () {
      console.log('cancel')
      this.hideModal()
    }
  }
}
</script>
<style lang="scss">

.feedback-modal {
  input {
    width : 100%;
  }
}
.feedback-button-wrapper {
  opacity: .9;
  z-index: 1000;
  position: fixed;
  bottom : -1px;
  right : -1px;
  button{
    border-color: #401f68;
  }
}
</style>
