
/**
 * Usage:
 * var timer = new Timer();
 * // Do some long/async operations...
 * (few seconds later)
 * timer.tick("operation A"); // this will add a named data point with a timestamp.
 * timer.tick("operation B");
 * var str = timer.toString(); // This will output the elapsed time and name for the operation.
 */
function Timer () {
  this.start = Date.now()
  this.ticks = []
}

Timer.prototype.tick = function (name) {
  var elapsed
  if (this.ticks.length === 0) {
    elapsed = Date.now() - this.start
  } else {
    var size = this.ticks.length
    elapsed = Date.now() - this.ticks[size - 1].time
  }
  this.ticks.push({
    name: name,
    time: Date.now(),
    elapsed: elapsed
  })
}
Timer.prototype.toString = function () {
  return this.ticks.map((it) => {
    return it.name + (it.elapsed / 1000).toFixed(2) + ' sec'
  }).join('; ')
}

export default Timer
