var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mytable", {
    staticClass: "subcontractor-po",
    attrs: {
      title: "",
      back: { text: "Purchase Orders" },
      columns: _vm.data.columns,
      dropdowndata: _vm.selectdata,
      store: _vm.data.rows,
      footer_rows: _vm.data.footer_rows,
      sortkey: "AccountCode",
      sortdir: 1,
      sortorders: { AccountCode: 1 },
      labels: _vm.data.labels,
      loading: _vm.data.loading,
      performance: _vm.data.performance,
      error: _vm.data.error,
    },
    on: { "update-selectdata": _vm.selectOpt },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }