<template>
  <keep-alive>
    <div>
      <div v-if="tryagain" class="modal-mask download-modal">
        <transition name="modal">
          <div v-on-clickaway="hideTryagain()" class="modal-container">
            <div class="modal-header">
              <div class="modal-close" @click="hideTryagain()">Close</div>
            </div>
            <div class="modal-body transaction">
              <p>Data load timed out. Please, refresh page.</p>
            </div>
            <div class="modal-footer">
              <button class="rd-small-button feed_close" @click="hideTryagain()">Ok</button>
            </div>
          </div>
        </transition>
      </div>

      <!-- <template v-if="AuthService.getUser().data.uid === pi_netid">
    <button class='dir-btn prev page-top' @click="backToInvestigators()">Back To Investigator List</button>
  </template> -->

      <div class="metadata-block">
        <template v-if="AuthService.getUser().data.uid !== pi_netid">
          <div class="usercard-wrapper">
            <usercard v-bind:netid="pi_netid" />
          </div>
        </template>
        <template v-if="AuthService.getUser().data.uid === pi_netid">
          <h2>My Projects</h2>
        </template>

        <div class="download-actions">
          <button v-bind:class="{'working':downloading.xlsx }" class="rd-download excel" @click="download('xlsx')">DOWNLOAD PORTFOLIO DATA</button>
          <!-- <button @click="download('xlsx')" v-bind:class="{'working':downloading.excel }" class="rd-download word">Download .docx</button> -->
          <!-- <button @click="download('xlsx')" v-bind:class="{'working':downloading.excel }" class="rd-download pdf">Download .pdf</button> -->
        </div>

        <div class="tabs">
          <ul class="nav nav-pills">
            <router-link v-bind:to="{ name: 'Sponsored_Projects', params: { netid: pi_netid }}" tag="li" role="presentation">
              <a class="sponsored tab">Sponsored</a>
            </router-link>

            <router-link v-bind:to="{ name: 'NonSponsored_Projects', params: { netid: pi_netid }}" tag="li" role="presentation">
              <a class="nonsponsored tab">Non-Sponsored</a>
            </router-link>

            <router-link v-bind:to="{ name: 'Pending_Projects', params: { netid: pi_netid }}" tag="li" role="presentation">
              <a class="pending tab">Pending</a>
            </router-link>

            <router-link v-bind:to="{ name: 'Protocols_Protocols', params: { netid: pi_netid }}" tag="li" role="presentation">
              <a class="protocol tab">All Protocols</a>
            </router-link>
          </ul>
        </div>
      </div>
      <router-view />
    </div>
  </keep-alive>
</template>

<script>
import {DataExportService} from '../../services/'
import usercard from '../_usercard.vue'
import AuthService from '../../auth/AuthService.js'
import Wildcat from './Wildcat.json'
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {
    onClickaway: onClickaway
  },
  components: {
    usercard: usercard
  },
  data () {
    return {
      // TODO: bug, user always see usercard, PI doesn't see "MY PROJECT"
      pi_netid: this.$route.params.netid,
      AuthService: AuthService,
      tryagain: false,
      downloading: {
        'xlsx': false,
        'docx': false,
        'pdf': false
      }
    }
  },
  mounted () {
    if (this.$route.params.netid === 'wildcat') {
      console.warn('using fake user')
      this.netid = Wildcat.projects.NETID // real-user to be used as a stand-in for wildcat
    } else {
      this.netid = this.$route.params.netid
    }
  },
  methods: {
    backToInvestigators () {
      var path = '/investigators/'
      this.$router.push({path: path})
    },
    hideTryagain () {
      this.tryagain = false
    },
    showTryagain () {
      this.tryagain = true
    },
    download (format) {
      var valid_formats = ['xlsx']
      if (valid_formats.indexOf(format) < 0) {
        return
      }
      var opts = {
        project_type: 'UserPortofolio',
        p_Invest_ID: (this.netid + '').toUpperCase()
      }

      var that = this
      this.downloading[format] = true
      DataExportService.get(opts).then(data => {
        that.downloading[format] = false
        // @TODO: CHeck browser support for this.
        // assert that this is a url
        if (data.match(/^https/)) {
          window.location.href = data
        } else {
          console.warn('Invalid download URL', data)
        }
        // Download(data);
        // trigger download
      }).catch(err => {
        console.warn('Unable to download', err)
        that.downloading[format] = false
        that.showTryagain()
      })
    }
  }
}
</script>
<style lang="scss">

.tabs {
  .router-link-active {
    font-weight : bold;
  }
}
</style>
