<template>
  <mytable
    title="Subcontracts - Budget Info"
    class="subcontracts-proposal"
    v-bind:columns="data.columns"
    v-bind:toggledata="selectdata"
    v-bind:periodpickerdata="periodpickerdata"
    v-bind:store="data.rows"
    v-bind:footer_rows="data.footer_rows"
    v-bind:sortkey="'ProposalNumber'"
    v-bind:sortdir="1"
    v-bind:sortorders="{'ProposalNumber':1}"
    v-bind:labels="data.labels"
    v-bind:loading="data.loading"
    v-bind:performance="data.performance"
    v-bind:error="data.error"
    @update-selectdata="selectOpt"
  />
</template>

<script>
import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    mytable: mytable
  },
  data () {
    return {
      store: {
        footer_rows: [],
        rows: []
      },
      temp: {},
      selectdata: {
        options: [
          { text: 'Actual', value: 'subcontracts-actual', class: 'subcontracts-actual'}
          // { text: 'Budget', value: 'subcontracts-proposal', class: 'subcontracts-proposal'}
        ],
        idx: 1
      },
      periodpickerdata: {
        periods: [],
        selectedPeriod: 4
      },
      hideTable: false,
      data: {
        loading: false,
        columns: [],
        rows: [],
        footer_rows: [],
        labels: {},
        error: null
      }
    }
  },
  mounted () {
    this.netid = this.$route.params.netid
    if (this.netid === 'wildcat') {
      this.netid = Wildcat.sponsored.NETID
      this.project_id = Wildcat.sponsored.PROJECT_ID
      // need to add fake venderid and po
    } else {
      this.project_id = this.$route.params.id
    }
    this.load()
  },
  methods: {
    formatLabel (key) {
      var selection = 'SubcontractProposal'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'SubcontractProposal'
      return Dictionary.formatTitle(key, selection)
    },
    formatValue (key, row) {
      return Dictionary.formatValue(key, row)
    },
    selectPeriod (new_period) {
      if (new_period === undefined) {
        new_period = this.periodpickerdata.selectedPeriod
      }
      this.view(new_period)
    },
    getDefaultPeriod () {
      var period
      var project_start = new Date(this.$parent.data.rows['ProjectStartDate']).getFullYear()
      var project_end = new Date(this.$parent.data.rows['ProjectEndDate']).getFullYear()
      var today = new Date().getFullYear()
      if (project_start) {
        var report_opts = {
          report_name: 'era-rp-main',
          p_Service: 'RD006',
          p_PrjID: this.project_id
        }
        ReportService.get(report_opts, this.temp).then(data => {
          if ('error' in data) {
            this.data.error = data.error
          } else {
            project_start = data.rows[0]['ProjectStartDate']
            project_end = data.rows[0]['ProjectEndDate']
          }
        })
      }
      if (project_start >= today) {
        period = 4
      } else if (project_end <= today) {
        period = this.periodpickerdata.periods.length - 1
      } else {
        period = Math.min(4, project_start - today)
      }
      console.log(period)
      this.periodpickerdata.selectedPeriod = period
    },
    view (period) {
      var columns = ['InstitutionNumber', 'VendorName']
      var periods = []
      this.store.rows.forEach(row => {
        var col = 'Period ' + row['BudgetPeriod']
        if (periods.indexOf(col) < 0) {
          // columns.push(col);
          periods.push(col)
        }
      })
      this.periodpickerdata.periods = periods

      if (periods.length <= 5) {
        this.periodpickerdata.selectedPeriod = periods.length - 1
        periods.forEach(prd => {
          columns.push(prd)
        })
      } else {
        var i = period - 4
        for (i; i <= period; i++) {
          columns.push(periods[i])
        }
      }
      columns.push('Total')
      this.data.columns = columns
      var institutions = []
      var item
      var periodid = this.store.rows[0]['BudgetPeriod']
      this.store.rows.forEach(row => {
        item = {
          text: row['VendorName'],
          prop: row['InstitutionNumber']
        }
        if (row['BudgetPeriod'] === periodid) {
          institutions.push(item)
        }
      })
      var wildcat = false
      if (this.$route.params.netid === 'wildcat') {
        wildcat = true
      }

      var rows = institutions.map(institution => {
        var list = this.store.rows.filter(row => {
          var sel_name = (row['VendorName'] === institution.text && row['InstitutionNumber'] === institution.prop)
          var per_lable = 'Period ' + row['BudgetPeriod']
          var sel_period = (periods.indexOf(per_lable) >= 0)
          return sel_name && sel_period
        })
        var new_row = {}
        new_row['VendorName'] = institution.text
        new_row['InstitutionNumber'] = institution.prop
        var tot = 0
        list.forEach(row => {
          var key = 'Period ' + row['BudgetPeriod']
          var val = parseFloat(row['Amount'])
          if (wildcat) {
            val = 500.42 + Math.round(Math.random() * 5000)
          }
          tot += val
          new_row[key] = val
        })
        new_row['Total'] = tot
        return new_row
      })

      this.data.rows = rows

      var footer_row = {}
      var footer_rows = []
      footer_row['VendorName'] = 'Total'
      var footer_col = columns.filter(col => {
        return !(col === 'VendorName' || col === 'InstitutionNumber')
      })
      footer_col.forEach(col => {
        footer_row[col] = 0
      })
      this.data.rows.forEach(row => {
        footer_col.map(col => (
          footer_row[col] += parseFloat(row[col])
        ))
      })
      footer_rows.push(footer_row)
      this.data.footer_rows = footer_rows
    },
    selectOpt (value) {
      var path = '/project/' + this.$route.params.id + '/' + this.$route.params.netid + '/' + value
      this.$router.push({path: path})
    },
    selectRow (row) {
      console.log('Ignore row select')

    },
    load () {
      var timer = new Timer()
      this.data.loading = true
      var that = this

      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD020',
        p_PrjID: this.project_id
      }

      this.data.loading = true
      this.data.error = ''
      ReportService.get(report_opts, this.$store).then(data => {
        that.data.loading = false
        if ('error' in data) {
          that.data.error = data.error
        } else {
          that.store.rows = data.rows
          if (that.store.rows.length < 1) {
            console.debug('No rows found')
            that.data.error = 'No data available'
            return
          }
          that.view(that.periodpickerdata.selectedPeriod)
        }
        timer.tick('loaded in ')
        that.data.performance = timer.toString()
      }).catch(err => {
        that.data.loading = false
        console.error('Unable to parse the results', err)
      })
    }
  }

}

</script>
