var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "flex" } },
    [
      _vm._l(_vm.options, function (opt, index) {
        return [
          _c("div", { key: index }, [
            _c(
              "button",
              {
                staticClass: "rd-small-button",
                class: [opt.class, _vm.activeindex === index ? "active" : ""],
                on: {
                  click: function ($event) {
                    return _vm.selectOpt(index, opt)
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(opt.text))])]
            ),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }