<template>
  <!-- v-bind:dropdowndata="selectdata" -->
  <mytable
    title=""
    v-bind:back="{text: backtext}"
    v-bind:columns="data.columns"
    v-bind:store="data.rows"
    v-bind:buttons="data.buttons"
    v-bind:labels="data.labels"
    v-bind:sortkey="'CheckDate'"
    v-bind:sortdir="-1"
    v-bind:sortorders="{'CheckDate':-1}"
    v-bind:loading="data.loading"
    v-bind:performance="data.performance"
    v-bind:error="data.error"
  />
</template>

<script>

import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import Wildcat from '../Wildcat.json'
import async from 'async'

export default {
  components: {
    mytable: mytable
  },
  data () {
    return {
      backtext: '',
      fund_code: '',
      dept_id: '',
      project_id: '',
      accountlist: ['60065', '60011', '60020', '60030', '60040', '60050', '60076', '60104', '60106', '60111', '60113', '60120', '60060', '60062', '60063', '60070', '60077', '60078', '60079', '60101', '60102', '60103', '60112', '78050', '78060', '78070', '78080', '78084'],
      accountcode: '',
      salaryRole: true,
      selectdata: {
        options: [],
        idx: 0
      },
      data: {
        error: '',
        columns: ['Description', 'Amount', 'Date', 'TransactionID', 'TransactionType'],
        rows: [],
        loading: true,
        buttons: [{
          'label': 'Transactions',
          'value': 'individual-transactions',
          'active': true
        }]
      },
      store: {
        columns: ['Description', 'Amount', 'Date', 'TransactionID', 'TransactionType'],
        columnsSalary: ['CheckDate', 'AccountDescription', 'Name', 'EmplID', 'EntryType', 'Amount'],
        rows: []
      }
    }
  },
  watch: {
    '$parent.$data.details.selectdata': {
      handler: function (newVal, oldVal) {
        this.selectdata = newVal
        console.debug('NEWVAL', newVal)
        this.load()
      },
      deep: true
    }
  },
  mounted () {
    var wasDemo = localStorage.getItem('prevWasWildcat')
    if (wasDemo === 'true') {
      this.cs = Wildcat.nonsponsored.PROJECT_ID
    } else {
      this.cs = this.$route.params.cs
    }
  },
  methods: {
    buttonClick (button_value) {
      // update the button state
      // eslint-disable-next-line no-return-assign
      this.data.buttons.forEach(button => button.active = button.value === button_value)

      switch (button_value) {
        case 'individual-transactions':
          this.individualTransactions()
          break
        case 'salary-details':
          this.salaryDetails()
          break
        default:
          console.log('Unknown button value', button_value)
          break
      }

    },
    salaryDetails () {
      var opts = {
        report_name: 'era-rp-salary',
        p_Service: 'RD021',
        p_PrjID: this.project_id,
        p_Account: this.accountcode,
        p_FundCode: this.fund_code,
        p_DeptID: this.dept_id,
        p_DrillType: 'NONSPON',
        rowLimit: 1000
      }
      var that = this
      this.data.loading = true

      this.data.error = ''
      this.data.rows = []
      ReportService.get(opts, this.$store).then(data => {
        that.data.columns = that.store.columnsSalary.slice(0)
        if ('error' in data) {
          that.data.error = data.error
          that.data.loading = false
          that.data.rows = []
        } else {
          that.data.rows = data.rows
          var wasDemo = localStorage.getItem('prevWasWildcat')
          // if (that.$route.params.netid === 'wildcat') {
          if (wasDemo === 'true') {
            that.data.rows.forEach(item => {
              item['Amount'] = 500.42 + Math.round(Math.random() * 150000)
            })
          }
          if (that.data.rows.length === 0) {
            that.data.error = 'No data available'
          }
          that.data.loading = false
        }
      })

    },
    individualTransactions () {
      // set original headers
      this.data.columns = this.store.columns.slice(0)
      // show original individual transactions.
      // this.data.rows = this.store.rows;
      var filtercodition = this.selectdata.options[this.selectdata.idx].type
      this.data.rows = this.store.rows
        .filter(row => row['FYFlag'] === filtercodition)
      if (this.data.rows.length === 0) {
        this.data.error = 'No data available'
      } else {
        this.data.error = ''
      }
    },
    formatLabel (key) {
      var selection = 'NonSponsoredTransactionDetail'
      if (this.data.buttons.length > 1) {
        selection = this.data.buttons[1].active ? 'SalaryDetail' : 'NonSponsoredTransactionDetail'
      }
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'NonSponsoredTransactionDetail'
      if (this.data.buttons.length > 1) {
        selection = this.data.buttons[1].active ? 'SalaryDetail' : 'NonSponsoredTransactionDetail'
      }
      return Dictionary.formatTitle(key, selection)
    },
    goBack () {
      this.$parent.goBack()
    },
    selectOpt (value) {
      var searchedopt = this.selectdata.options.filter(opt => opt.value === value).pop()
      this.selectdata.idx = this.selectdata.options.indexOf(searchedopt)
      this.load(value)
    },
    selectRow (row) {
      console.log('do nothing')
    },
    view () {
      var filtercodition = this.selectdata.options[this.selectdata.idx].type
      this.data.rows = this.store.rows
        .filter(row => row['FYFlag'] === filtercodition)
        .slice(0, 50)
    },
    load (account_code) {
      var timer = new Timer()
      this.data.loading = true
      var that = this

      // Check the default account code from call arg, otherwise,
      // get the value from the select object.
      if (account_code === undefined) {
        account_code = this.selectdata.options[this.selectdata.idx].value
      }
      this.accountcode = account_code
      var account_key = this.selectdata.options[this.selectdata.idx].key
      var filtercodition = this.selectdata.options[this.selectdata.idx].type
      this.backtext = filtercodition === 'CurrentYear' ? 'Fiscal Year to Date' : 'Previous fiscal year'
      // break CS into parts
      var cs_parts = this.cs.split('-')
      this.fund_code = cs_parts[0]
      this.dept_id = cs_parts[1]
      this.project_id = cs_parts[2]

      var opts = []
      opts[1] = {
        report_name: 'era-rp-main',
        p_Service: 'RD012',
        p_FundCode: this.fund_code,
        p_DeptID: this.dept_id,
        p_PrjID: this.project_id,
        p_Account: account_code,
        rowLimit: 1000
      }
      opts[0] = {
        report_name: 'era-rp-support',
        p_Service: 'RD022',
        p_PrjID: this.project_id
      }

      this.data.error = ''
      that.data.rows = []
      that.store.rows = []
      var count = 0
      async.map(opts, function (item, callback) {
        callback(null, ReportService.get(item, this.param).then(data => {
          if ('error' in data) {
            that.data.error = data.error
            that.data.loading = false
          } else {
            if (data.headers.indexOf('SalaryAccess') >= 0) {
              that.salaryRole = data.rows[0].SalaryAccess === 'Yes'
              if (that.accountlist.indexOf(account_code) >= 0 && that.salaryRole && that.selectdata.filterBy === 'FYTDActualExpense') {
                if (that.data.buttons.length < 2) {
                  var b = {
                    'label': 'Salary Details',
                    'value': 'salary-details',
                    'active': false
                  }
                  that.data.buttons.push(b)
                }
                that.data.buttons[1].active = true
                that.data.buttons[0].active = false
              } else {
                if (that.data.buttons.length > 2) {
                  that.data.buttons = that.data.buttons.slice(0, 2)
                }
              }
            } else {
              var transaction_map = {
                'FYTDActualExpense': 'Actual',
                'FYTDPreEncEncExpense': 'Encumbrance'
              }
              var transaction_type = account_key in transaction_map ? transaction_map[account_key] : account_key
              that.store.rows = data.rows.filter(row => {
                return row['TransactionType'] === transaction_type
              })
              var wasDemo = localStorage.getItem('prevWasWildcat')
              // if (that.$route.params.netid === 'wildcat') {
              if (wasDemo === 'true') {
                that.store.rows.forEach(item => {
                  item['Amount'] = 500.42 + Math.round(Math.random() * 150000)
                })
              }
              that.view()
              if ((that.selectdata.filterBy === 'FYTDPreEncEncExpense' && that.data.buttons.length > 1) || that.accountlist.indexOf(that.accountcode) < 0) {
                that.data.buttons = that.data.buttons.slice(0, 1)
                that.data.buttons[0].active = true
              }
              if (that.data.buttons[0].active) {
                that.individualTransactions()
              }

              if (that.data.buttons.length > 1 && that.data.buttons[1].active) {
                that.data.rows = []
                that.salaryDetails()
              }
            }
          }
          timer.tick('loaded in ')
          that.data.performance = timer.toString()

          if (count > 0 && that.data.buttons[0].active) {
            that.data.loading = false
          } else {
            count++
          }

        }).catch(err => {
          that.data.loading = false
          console.error('Unable to parse the results', err)
        }))
      }.bind({
        param: this.$store
      }), function done (err, data) { console.log(err) })
    }
  }
}
</script>
<style lang="scss" />
