<template>
  <keep-alive>
    <div>
      <div v-show="!hideTable">
        <mytable
          title="Expense Categories"
          class="expenses-by-month"
          v-bind:toggledata="selectdata"
          v-bind:datepickerdata="datepickerdata"
          v-bind:columns="data.columns"
          v-bind:store="data.rows"
          v-bind:footer_rows="data.footer_rows"
          v-bind:labels="data.labels"
          v-bind:sortkey="'AccountCategoryCode'"
          v-bind:sortdir="1"
          v-bind:sortorders="{'AccountCategoryCode':1}"
          v-bind:loading="data.loading"
          v-bind:performance="data.performance"
          v-bind:error="data.error"
          @update-selectdata="selectOpt"
        />
      </div>

      <div v-show="hideTable">
        <myexpensebymonthdetails
          title=""
          class="expenses-by-month-details"
          v-bind:datepickerdata="expense_by_month_datepickerdata"
          v-bind:back="{text:'Expenses by month'}"
        />
      </div>
    </div>
  </keep-alive>
</template>

<script>
import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {DateUtils, Dictionary, Timer} from '../../../utils/'
import ExpensesByMonthDetails from './ExpensesByMonthDetails'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    mytable: mytable,
    myexpensebymonthdetails: ExpensesByMonthDetails
  },
  data () {
    return {
      project_id: 0,
      hideTable: false,
      categories: [],
      ignored_keys: ['AccountCategoryDescription'],
      details: {
        selectdata: {
          options: [],
          idx: 0
        }
      },
      data: {
        // ["YYYYMM", "Fiscal Year - Accounting Period", "Fiscal Year - Accounting Period Description", "Project ID", "ACCOUNT CATEGORY CODE", "ACCOUNT CATEGORY DESCR", "ACTUAL EXPENSE"]
        // columns: ['ACCOUNT CATEGORY DESCR','Fiscal Year - Accounting Period','ACTUAL EXPENSE','ACCOUNT CATEGORY CODE'],
        columns: [],
        rows: [],
        footer_rows: [],
        labels: {},
        error: '',
        loading: false
      },
      // Store the pivot data source here. Render the contents into the regular data.rows and data.cols
      store: {
        columns: [],
        rows: [],
        footer_rows: []
      },
      selectdata: {
        options: [
          { text: 'Inception to Date', value: 'inception-to-date', class: 'inception'},
          { text: 'Expenses by Month', value: 'expenses-by-month', class: 'month_to_month'}
        ],
        idx: 1
      },
      datepickerdata: {
        years: DateUtils.getLastYears(5),
        months: DateUtils.MONTHS,
        selectedYear: 0,
        selectedMonth: 0
      },
      expense_by_month_datepickerdata: {
        years: DateUtils.getLastYears(5),
        months: DateUtils.MONTHS,
        selectedYear: 0,
        selectedMonth: 0
      },
      MAX_ACCOUNT_CATEGORY_CODE: 1000,
      selectedYear: 0,
      selectedMonth: 0,
      range: {
        start: {
          year: 2016,
          month: 1
        },
        end: {
          year: 2016,
          month: 4
        }
      }
    }
  },
  mounted () {
    // Demo User Wildcat -- override
    if (this.$route.params.netid === 'wildcat') {
      this.netid = Wildcat.sponsored.NETID
      this.project_id = Wildcat.sponsored.PROJECT_ID
    } else {
      this.netid = this.$route.params.netid
      this.project_id = this.$route.params.id
    }
    this.hideTable = false

    // calculate initial date.
    var now = new Date()
    var yyyy = now.getFullYear()
    var m = now.getMonth()
    var offset_months = 0 // months
    var d = DateUtils.addMonths(yyyy, m, offset_months)
    // this.datepickerdata.selectedYear = 5 - (yyyy - d[0])
    var year_idx = this.datepickerdata.years.indexOf(d[0])
    this.datepickerdata.selectedYear = year_idx
    this.datepickerdata.selectedMonth = d[1]

    this.load()
  },
  methods: {
    formatLabel (key) {
      var selection = 'SponsoredProjectFinancialsMonthly'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'SponsoredProjectFinancialsMonthly'
      return Dictionary.formatTitle(key, selection)
    },
    selectOpt (value) {
      // Use the netid passed via URL. OK to pass 'wildcat' for the demo user.
      var path = '/project/' + this.$route.params.id + '/' + this.$route.params.netid + '/overview/' + value
      this.$router.push({path: path})
    },
    selectDate (yyyy, mm) {
      if (yyyy === undefined || mm === undefined) {
        // eslint-disable-next-line no-redeclare
        var yyyy = this.datepickerdata.years[this.datepickerdata.selectedYear]
        // eslint-disable-next-line no-redeclare
        var mm = this.datepickerdata.selectedMonth // 0..11
      }
      this.view(yyyy, mm)
    },
    goBack () {
      console.debug('PARENT GO BACK')
      this.hideTable = false
    },
    delegateClick (row, key, $event) {
      if (key !== 'AccountCategoryDescription') {
        this.showDetails(row, key)
      }
    },
    selectRow (row, target_date) {
      console.log('ignore click on row')
    },
    showDetails (row, key) {
      // ignore clicks on the column with the categories.
      // Users should clikc on the specific cells for the month they wnat to drill down.
      if (this.ignored_keys.indexOf(key) >= 0) {
        return
      }
      var target_date = key
      this.hideTable = true
      // format the categories into selectdata format.
      var options = this.categories.map(cat => {
        return {
          text: cat['AccountCategoryDescription'],
          value: cat['AccountCategoryCode'],
          row: cat
        }
      })
      // find the IDX for the current selection.
      var list_codes = options.map(_row => _row.text)
      var idx = list_codes.indexOf(row['AccountCategoryDescription'])
      this.details.selectdata.options = options
      this.details.selectdata.idx = idx

      // set the year and month selected for the expense details
      var date = target_date.split(' - ')
      // eslint-disable-next-line radix
      var yyyy = parseInt(date[0])
      // eslint-disable-next-line radix
      var mm = parseInt(date[1])
      var out_pair = DateUtils.addMonths(yyyy, mm, -5)
      var out_yyyy = out_pair[0]
      var out_mm = out_pair[1]
      var out_year_index = this.expense_by_month_datepickerdata.years.indexOf(out_yyyy)

      this.expense_by_month_datepickerdata.selectedYear = out_year_index
      this.expense_by_month_datepickerdata.selectedMonth = out_mm

    },
    addMonth (n_months) {
      var new_month = this.selectedMonth + n_months
      if (new_month >= 12) {
        this.selectedMonth = 0
        this.selectedYear++
      } else {
        this.selectedMonth = new_month
      }
      if (new_month < 0) {
        this.selectedMonth = 11
        this.selectedYear--
      }
      this.selectDate()
    },
    // month: 1..12
    // addMonths(year, month, n_months){
    //   return DateUtils.addMonths(year, month, n_months);
    // },
    pad (value, length) {
      return (value.toString().length < length) ? this.pad('0' + value, length) : value
    },
    // yyyy: 2016
    // mm: 0-11.
    view (yyyy, mm) {
      // Get date from user input
      if (this.store.rows.length < 1) {
        console.debug('No rows found')
        return
      }

      // align to fiscal dates
      var d = DateUtils.addMonths(yyyy, mm, +5)
      // eslint-disable-next-line no-redeclare
      var yyyy = d[0]
      // eslint-disable-next-line no-redeclare
      var mm = d[1]

      // set cols, rows
      var columns = ['AccountCategoryDescription']
      var n_months = 5
      // generate column keys for the selected date.
      for (var i = 0; i < n_months; i++) {
        var offset = 1 - n_months + i
        // eslint-disable-next-line no-redeclare
        var d = DateUtils.addMonths(yyyy, mm, offset)
        var date_str = d[0] + ' - ' + this.pad(d[1], 2)
        columns.push(date_str)
      }
      this.data.columns = columns

      var cat_names = []
      this.store.rows.forEach(row => {
        var value = row['AccountCategoryDescription']
        if (cat_names.indexOf(value) < 0) {
          cat_names.push(value)
        }
      })

      // generate rows.
      var rows = cat_names.map(cat => {
        var list = this.store.rows.filter(row => {
          var sel_cat = row['AccountCategoryDescription'] === cat
          var sel_date = columns.indexOf(row['FiscalYearAccountingPeriod'] >= 0)
          return sel_cat && sel_date
        })
        var new_row = {}
        new_row['AccountCategoryDescription'] = cat
        list.forEach(row => {
          var key = row['FiscalYearAccountingPeriod']
          var val = parseFloat(row['ActualExpense'])
          new_row[key] = val
          new_row['AccountCategoryCode'] = row['AccountCategoryCode']
          new_row['_clickable'] = true
        })
        return new_row
      })
      this.data.rows = rows

      // cognos wants to be able to update the AccountCategoryDescription field and have the description display as the row header
      // and still display the total values based on the AccountCategoryCode
      var totalAccountCategoryCodes = ['100', '200']
      var footer_rows = totalAccountCategoryCodes.map(code => {
        var list = this.store.footer_rows.filter(row => {
          var sel_code = row['AccountCategoryCode'] === code
          var sel_date = columns.indexOf(row['FiscalYearAccountingPeriod'] >= 0)
          return sel_code && sel_date
        })
        var new_row = {}
        // later code maps 'AccountCategoryDescription' in to the row title
        new_row['AccountCategoryDescription'] = list[0]['AccountCategoryDescription'] // all with same code have same description
        list.forEach(row => {
          var key = row['FiscalYearAccountingPeriod']
          var val = parseFloat(row['ActualExpense'])
          new_row[key] = val
          new_row['_clickable'] = false
        })
        return new_row
      })
      this.data.footer_rows = footer_rows
    },
    load () {
      var timer = new Timer()
      this.data.loading = true
      var that = this

      // calculate the date range to retrieve
      var now = new Date()
      var toYear = now.getFullYear()
      var toMonth = now.getMonth() + 1
      // add 4 to align with the fiscal period
      var out_d = DateUtils.addMonths(toYear, toMonth, 4)

      toYear = out_d[0]
      toMonth = out_d[1]

      var d = DateUtils.addMonths(toYear, toMonth, -60) // 5 years
      var fromYYYYMM = '' + d[0] + this.pad(d[1], 2)
      var toYYYYMM = '' + toYear + this.pad(toMonth, 2)

      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD008',
        p_PrjID: this.project_id,
        p_FromYYYYMM: fromYYYYMM,
        p_ToYYYYMM: toYYYYMM
      }

      ReportService.get(report_opts, this.$store).then(data => {
        if ('error' in data) {
          that.data.error = data.error
        } else {
          // hide columns
          that.store.columns = data.headers
          // that.store.rows = data.rows;

          that.store.rows = data.rows.filter(row => {
            return row['AccountCategoryCode'] > that.MAX_ACCOUNT_CATEGORY_CODE
          })
          // these are frozen rows (unsortable) at the bottom.
          that.store.footer_rows = data.rows.filter(row => {
            return row['AccountCategoryCode'] <= that.MAX_ACCOUNT_CATEGORY_CODE
          })

          // Fake user demo
          if (that.$route.params.netid === 'wildcat') {
            that.store.rows.forEach(row => {
              var fake_number
              if (Math.random() > 0.75 || row['AccountCategoryDescription'] === 'Services') {
                fake_number = 500.42 + (Math.round(Math.random() * 25000))
              } else {
                fake_number = 0
              }
              row['ActualExpense'] = fake_number
            })
          }

          // Load Categories for the <select> object in the details
          var seen = []
          var categories = that.store.rows.filter(row => {
            var code = row['AccountCategoryCode']
            if (seen.indexOf(code) >= 0) {
              return false
            } else {
              seen.push(code)
              return true
            }
          })

          that.categories = categories
          that.data.loading = false
        }
        timer.tick('loaded in ')
        that.data.performance = timer.toString()

        // @TODO: pre-populate this.
        that.selectDate()
      }).catch(err => {
        that.data.loading = false
        console.log('Unable to parse the results', err)
      })
    }
  }
  // beforeRouteEnter () {
  //   console.debug('ACTIVATE BY MONTH')
  //   this.hideTable = false
  // }
}
</script>
<style lang="scss" />
