import { render, staticRenderFns } from "./IRBDetail.vue?vue&type=template&id=0ba3e031"
import script from "./IRBDetail.vue?vue&type=script&lang=js"
export * from "./IRBDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/ADO-ResearchPortal/ADO-ResearchPortal/files-dash/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ba3e031')) {
      api.createRecord('0ba3e031', component.options)
    } else {
      api.reload('0ba3e031', component.options)
    }
    module.hot.accept("./IRBDetail.vue?vue&type=template&id=0ba3e031", function () {
      api.rerender('0ba3e031', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/projects/protocols/IRBDetail.vue"
export default component.exports