import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// Vuex store
const state = {
  reports: {}
}
const mutations = {
  set (state, url, data) { state.reports[url] = JSON.stringify(data) },
  reset (state) { state.reports = {} }
}

// old
var USE_LOCALSTORAGE = false
// console.warn('USING LOCALSTORAGE', USE_LOCALSTORAGE)
// eslint-disable-next-line no-unused-vars
var CacheStore = {
  store: {},
  set (key, value) {
    if (USE_LOCALSTORAGE) {
      window.localStorage.setItem(key, value)
    } else {
      this.store[key] = value
    }
  },
  remove (key) {
    if (USE_LOCALSTORAGE) {
      window.localStorage.removeItem(key)
    } else {
      delete this.store[key]
    }
  },
  get (key) {
    if (USE_LOCALSTORAGE) {
      return window.localStorage.getItem(key)
    } else {
      return this.store[key] || null
    }
  },
  reset () {
    console.log('Cache reset')
    if (USE_LOCALSTORAGE) {
      window.localStorage.clear()
    } else {
      this.store = {}

    }
  },
  stats () {
    console.debug('CACHE STATS')
    console.debug('keys:', Object.keys(this.store).length)
    console.debug('size:', JSON.stringify(this.store).length + ' bytes')
  }
}

// export default CacheStore;
export default new Vuex.Store({
  state,
  mutations
})
