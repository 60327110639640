<template>
  <keep-alive>
    <div>
      <div v-show="!hideTable">
        <mytable
          keep-alive
          title="Subcontracts"
          class="subcontracts-actual"
          v-bind:toggledata="selectdata"
          v-bind:columns="data.columns"
          v-bind:store="data.rows"
          v-bind:sortkey="'VendorName'"
          v-bind:sortdir="1"
          v-bind:sortorders="{'VendorName':1}"
          v-bind:labels="data.labels"
          v-bind:loading="data.loading"
          v-bind:performance="data.performance"
          v-bind:error="data.error"
          @update-selectdata="selectOpt"
        />
        <small>* Subcontracts paid by wire or online voucher rather than via Purchase Orders will not display NUFinancials data</small>
      </div>

      <!-- do we need this when detail table is showing and user wants to come back -->
      <div v-show="hideTable">
        <subcontractordetails />
      </div>
    </div>
  </keep-alive>
</template>

<script>

import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import subcontractordetails from './SubcontractorDetail.vue'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    subcontractordetails: subcontractordetails,
    mytable: mytable
  },
  data () {
    return {
      ignored_keys: ['SubContractAmount', 'AmountPaid', 'BalanceRemaining'],
      store: {
        rows: []
      },
      details: {
        selectdata: {
          options: [{
            text: '',
            value: ''
          }],
          idx: 0
        }
      },
      selectdata: {
        options: [
          { text: 'Actual', value: 'subcontracts-actual', class: 'subcontracts-actual'}
          // { text: 'Budget', value: 'subcontracts-proposal', class: 'subcontracts-proposal'}
        ],
        idx: 0
      },
      hideTable: false,
      data: {
        error: '',
        loading: false,
        columns: ['VendorID', 'VendorName', 'SubContractAmount', 'AmountPaid', 'BalanceRemaining'],
        rows: [],
        labels: {}
      }
    }
  },
  mounted () {
    if (this.$route.params.netid === 'wildcat') {
      this.project_id = Wildcat.sponsored.PROJECT_ID
    } else {
      this.project_id = this.$route.params.id
    }
    this.load()
  },
  methods: {
    formatLabel (key) {
      var selection = 'SubcontractsByVendor'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'SubcontractsByVendor'
      return Dictionary.formatTitle(key, selection)
    },
    goBack () {
      console.debug('do nothing')
      this.hideTable = false
    },
    selectOpt (value) {
      var path = '/project/' + this.$route.params.id + '/' + this.$route.params.netid + '/' + value
      this.$router.push({path: path})
    },
    selectRow (row) { console.log('Ignore row select') },

    delegateClick (row, key, $event) {
      console.log('CLICK', key)
      this.showDetails(row, key)
    },
    showDetails (row, key) {
      if (this.ignored_keys.indexOf(key) >= 0) {
        console.log('Ignoring click')
        return
      }

      var options = this.data.rows.map(row => {
        return {
          text: row['VendorName'],
          value: row['VendorID']
        }
      })
      this.hideTable = true
      options.sort((a, b) => {
        return a.text > b.text ? 1 : -1
      })
      var list_codes = options.map(_row => _row.value)
      var idx = list_codes.indexOf(row['VendorID'])
      this.details.selectdata.options = options
      this.details.selectdata.idx = idx

    },
    load () {
      var timer = new Timer()
      var that = this
      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD017',
        p_PrjID: this.project_id
      }

      this.data.loading = true

      ReportService.get(report_opts, this.$store).then(data => {
        that.data.loading = false
        if ('error' in data) {
          that.data.error = data.error
        } else {
          that.data.rows = data.rows.map(row => {
            row['_clickable'] = true
            return row
          })
          // Demo user
          if (this.$route.params.netid === 'wildcat') {
            that.data.rows.forEach(row => {
              var amount = 15000.42 + Math.round(Math.random() * 15000)
              var amount_paid = 500.42 + Math.round(Math.random() * 5000)
              row['SubContractAmount'] = amount
              row['AmountPaid'] = amount_paid
              row['BalanceRemaining'] = amount - amount_paid
            })
          }
          that.store.rows = that.data.rows.map(it => it)
        }
        if (that.data.rows.length === 0) {
          that.data.error = 'No data available'
        }
        timer.tick('loaded in ')
        that.data.performance = timer.toString()
      }).catch(err => {
        console.error('Unable to parse the results', err)
      })
    }
  }

}

</script>
