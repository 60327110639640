<template>
  <keep-alive>
    <div>
      <!-- modal code -->
      <!-- start projection tool manage -->
      <div v-if="projection_tool.modal_expanded" class="modal-mask">
        <transition name="modal">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-header">
                <h2>Manage Projections</h2>
                <div class="modal-close" @click="projection_tool.modal_expanded=!projection_tool.modal_expanded">Close</div>
              </div>

              <div class="modal-body">
                <ul class="projections_list">
                  <li v-if="projectiontooldata.projections.length === 0" class="projection">
                    <div class="card not_avail">
                      No Projections available
                    </div>
                  </li>
                  <li v-for="(projection, index) in projectiontooldata.projections" v-bind:key="'projection-' + index" class="projection" v-bind:class="{'disabled': projection.disabled}">
                    <transition name="fadeout">
                      <div class="card">
                        <ul>
                          <li>
                            <span class="key">Projection Title</span>
                            <span class="value">{{ projection.title }}</span>
                          </li>
                          <li>
                            <span class="key">Author</span>
                            <span class="value">{{ projection.author.cn }}</span>
                          </li>
                          <li>
                            <span class="key">Last Modified:</span>
                            <span class="value">{{ new Date(projection.created) }}</span>
                          </li>
                        </ul>

                        <div v-if="projection.author.netid === netid" class="projection_options">
                          <button class="rd-small-button proj_apply" @click="applyProjection(projection);"><span>Apply This Projection</span></button>
                          <button class="rd-small-button proj_delete" @click="deleteProjection(projection)"><span>Delete This Projection</span></button>
                        </div>
                        <div v-else class="projection_options">
                          <span>This projection can only be accessed by {{ projection.author.cn }}</span>
                        </div>
                      </div>
                    </transition>
                  </li>
                </ul>
              </div>

              <!-- <div class="modal-footer">
          <button class="rd-small-button proj_new"><span>New Projection</span></button>
          </div> -->
            </div>
          </div>
        </transition>
      </div>
      <!-- end of manage projection -->
      <div v-if="modal_expanded" class="modal-mask">
        <transition name="modal">
          <div class="modal-wrapper modal-data">
            <div v-on-clickaway="foo" class="modal-container">
              <div class="modal-header">
                <h2>Pending Project Detail</h2>
                <div class="modal-close" @click="modal_expanded=!modal_expanded">Close</div>
              </div>

              <div class="modal-body">
                <div class="card">
                  <ul>
                    <li v-for="(col, index) in columns" v-bind:key="'col-' + index">
                      <div class="key">{{ formatLabel(col) }}</div>
                      <div class="value">{{ formatValue(col, selectedRow) }}</div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="modal-footer">
                <!-- <a class="dir-btn prev">{prevRow.PROPOSAL_TITLE}</a> -->
                <div class="modal_options">
                  <a v-if="selectedRow['_STATUS'] === 'ACTIVE'" class="funding_status" v-bind:class="{'not_funded': selectedRow.funding === 'not_funded', 'waiting': selectedRow.funding === 'waiting'}" @click="mark(selectedRow, 'NOT_FUNDED')">
                    <span v-if="selectedRow.funding === ''">Mark as 'not funded'</span>
                    <span v-if="selectedRow.funding === 'active'">Mark as 'not funded'</span>
                    <span v-if="selectedRow.funding === 'waiting'">Marking as 'not funded'</span>
                    <span v-if="selectedRow.funding === 'not_funded'">Marked as 'not funded'</span>
                  </a>
                  <a v-if="selectedRow['_STATUS'] !== 'ACTIVE'" class="funding_status" v-bind:class="{'active': selectedRow.funding === 'active', 'waiting': selectedRow.funding === 'waiting'}" @click="mark(selectedRow, 'ACTIVE')">
                    <span v-if="selectedRow.funding === ''">Mark as 'Active'</span>
                    <span v-if="selectedRow.funding === 'not_funded'">Mark as 'Active'</span>
                    <span v-if="selectedRow.funding === 'waiting'">Marking as 'active'</span>
                    <span v-if="selectedRow.funding === 'active'">Marked as 'active'</span>
                  </a>
                </div>
              <!--- <a class="dir-btn next">{nextRow.PROPOSAL_TITLE}</a> -->
              </div>
            </div>
          </div>
        </transition>
      </div>

      <template v-if="NO_ACCESS">
        <h3>Oops!</h3>
        <p>{{ error }}</p>
      </template>

      <template v-if="!NO_ACCESS">
        <div class="table_wrap">
          <div class="table_menu_flex">
            <template v-if="back">
              <div class="table_nav flex_child">
                <button class="arr back" @click="$parent.goBack()">{{ back.text }}</button>
              </div>
            </template>

            <template v-if="title">
              <div class="table_title flex_child">
                <h2>{{ title }} ({{ store.length }})</h2>
              </div>
            </template>

            <template v-if="toggledata">
              <div class="table_switcher flex_child">
                <mytoggle
                  v-bind:options="toggledata.options"
                  v-bind:activeindex="toggledata.idx"
                  @update-table-toggle="updatetabletoggledata"
                />
              </div>
            </template>

            <template v-if="buttons">
              <div class="table_buttons flex_child">
                <button
                  v-for="(button, index) in buttons"
                  v-bind:key="'button-' + index"
                  v-bind:class="button.active ? 'rd-small-button proj_new active' : 'rd-small-button proj_new'"
                  @click="buttonClick(button.value)"
                >
                  {{ button.label + (button.active ? "*" : '') }}
                </button>
              </div>
            </template>

            <template v-if="projectiontooldata">
              <div class="table_projection_menu flex_child">
                <button class="rd-small-button proj_new" @click="newProjection()"><span>New Projection</span></button>
                <button class="rd-small-button proj_save" @click="saveProjection()"><span>Save Projection</span></button>
                <button class="rd-small-button proj_manage" @click="manageProjections()"><span>Manage Projections</span></button>
              </div>
            </template>

            <template v-if="dropdowndata">
              <div class="table_switcher flex_child">
                <mydropdown
                  v-bind:options="dropdowndata.options"
                  v-bind:idx="dropdowndata.idx"
                  @update-table-dropdown="updatetabledropdowndata"
                />
              </div>
            </template>

            <template v-if="!hidesearch">
              <div class="table_search search flex_child">
                <input v-model="search.query" type="text" name="query" placeholder="Search This Table" @input="debounceSearch" @keyup="view(true)"><i class="glyphicon glyphicon-search" />
              </div>
            </template>

            <!-- date picker -->
            <template v-if="datepickerdata">
              <div class="table_datepicker flex_child">
                <mydatepicker v-bind:datepickerdata="datepickerdata" />
              </div>
            </template>

            <!-- period picker -->
            <template v-if="periodpickerdata">
              <div class="table_periodpicker flex_child">
                <myperiodpicker v-bind:periodpickerdata="periodpickerdata" />
              </div>
            </template>
          </div>
          <!-- end table menu flex-->

          <!-- List View -->
          <template v-if="table_mode==='VERTICAL'">
            <div class="table-vertical">
              <div v-if="loading">
                <div style="position:relative"><loading /></div>
              </div>
              <div v-if="!loading && rows.length === 0">
                {{ error }}
              </div>
              <div v-for="(row, index) in rows" v-bind:key="'row-' + index" class="card" @click="selectRow(row)">
                <ul>
                  <li v-for="key in columns" v-bind:key="key">
                    <template v-if="key === 'profile_style' || key ==='Favorites'">
                      <template v-if="key === 'profile_style'">
                        <div class="user-thumb" v-bind:style="{backgroundImage: row[key] }">
                          {{ row[key] ? '': (row['PrimaryFirstName'] ? row['PrimaryFirstName'][0] : row['EmployeeFirstName'][0]) }}
                          {{ row[key] ? '': (row['PrimaryLastName'] ? row['PrimaryLastName'][0] : row['EmployeeLastName'][0]) }}
                        </div>
                      </template>
                      <template v-if="key ==='Favorites'">
                        <div v-if="row[key] === true" v-bind:key="'fv-' + index" align="center">
                          <span v-bind:key="'fvs1-' + index" style="color:#FFFFFF; font-size:20px">&#9733;</span>
                        </div>
                        <div v-else v-bind:key="'fve-' + index" align="center">
                          <span v-bind:key="'fvs2-' + index" style="color:##4E2A84; font-size:20px">&#9733;</span>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <span class="key">{{ formatLabel(key, selection) }}:</span>
                      <span v-bind:class="formatClass(key, row)" class="value">{{ formatValue(key, row) }}</span>
                    </template>
                  </li>
                </ul>
              </div>
            </div>
          </template> <!--end list view-->

          <!-- Table View -->
          <template v-if="table_mode==='HORIZONTAL'">
            <!-- fixed header -->
            <div id="skrollr-body" data-97-top="top:98px" data-98-top="top:-200px" data-anchor-target="tbody" class="table_content">
              <div class="container">
                <div class="table_head_wrap">
                  <div class="table_menu_flex">
                    <!-- render the back button -->
                    <!-- NOTE: the callback is defined on the parent element -->
                    <template v-if="back">
                      <div class="table_nav flex_child">
                        <button class="arr back" @click="$parent.goBack()">{{ back.text }}</button>
                      </div>
                    </template>

                    <!-- render the title for the table -->
                    <template v-if="title">
                      <div class="table_title flex_child">
                        <h2>{{ title }} ({{ store.length }})</h2>
                      </div>
                    </template>

                    <!-- Drop down selector -->
                    <template v-if="dropdowndata">
                      <div class="table_switcher flex_child">
                        <mydropdown
                          v-bind:options="dropdowndata.options"
                          v-bind:idx="dropdowndata.idx"
                          @update-table-dropdown="updatetabledropdowndata"
                        />
                      </div>
                    </template>

                    <!-- show the search input -->
                    <template v-if="!hidesearch">
                      <div class="table_search search flex_child">
                        <input v-model="search.query" type="text" name="query" placeholder="Search This Table" @input="debounceSearch" @keyup="view(true)"><i class="glyphicon glyphicon-search" />
                      </div>
                    </template>

                    <!-- date picker -->
                    <template v-if="datepickerdata">
                      <div class="table_datepicker flex_child">
                        <mydatepicker v-bind:datepickerdata="datepickerdata" />
                      </div>
                    </template>

                    <!-- period picker -->
                    <template v-if="periodpickerdata">
                      <div class="table_periodpicker flex_child">
                        <myperiodpicker v-bind:periodpickerdata="periodpickerdata" />
                      </div>
                    </template>
                  </div> <!--end table menu flex-->

                  <!-- Sticky Header -->
                  <table style="width:100%">
                    <thead>
                      <tr>
                        <th
                          v-for="key in columns"
                          v-bind:key="key"
                          v-bind:title="formatTitle(key)"
                          v-bind:class="formatClass(key, {})"
                          @click="sortBy(key)"
                        >
                          {{ formatLabel(key) }}
                          <template v-if="!hidesort">
                            <span
                              class="arrow"
                              v-bind:class="sortOrders[key] > 0 ? 'asc' : 'dsc'"
                            />
                          </template>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div> <!--end table head wrap-->
              </div> <!--end container-->
            </div> <!--end fixed header-->

            <div id="table_content" class="table_content">
              <table style="width:100%">
                <thead>
                  <tr>
                    <th
                      v-for="key in columns"
                      v-bind:key="key"
                      v-bind:title="formatTitle(key, selection)"
                      v-bind:class="formatClass(key, {})"
                      @click="sortBy(key)"
                    >
                      {{ formatLabel(key, selection) }}
                      <template v-if="!hidesort">
                        <span
                          class="arrow"
                          v-bind:class="sortOrders[key] > 0 ? 'asc' : 'dsc'"
                        />
                      </template>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-if="loading">
                    <td colspan="columns.length" style="height:50px !important">
                      <div style="position:relative"><loading /></div>
                    </td>
                  </tr>

                  <tr v-for="(entry, index) in rows" v-bind:key="'entry-' + index" v-bind:class="entry['_STATUS']" @click="selectRow(entry)">
                    <td v-for="key in columns" v-bind:key="key" v-bind:title="entry[key]" v-bind:class="formatClass(key, entry)" @click.stop="delegateClick(entry, key, $event)">
                      <!-- <transition> -->
                      <!-- <transition-group name="expand"> -->
                      <template v-if="key === 'profile_style' || key ==='Favorites'">
                        <template v-if="key === 'profile_style'">
                          <div v-bind:key="'ps-' + index" class="user-thumb profile_style" v-bind:style="{backgroundImage: entry['profile_style']}">
                            {{ entry['profile_style'] ? '': (entry['PrimaryFirstName'] ? entry['PrimaryFirstName'][0] : entry['EmployeeFirstName'][0]) }}
                            {{ entry['profile_style'] ? '': (entry['PrimaryLastName'] ? entry['PrimaryLastName'][0] : entry['EmployeeLastName'][0]) }}
                          </div>
                        </template>
                        <template v-if="key ==='Favorites'">
                          <div v-if="entry[key] === true" v-bind:key="'fv-' + index" align="center">
                            <span v-bind:key="'fvs1-' + index" style="color:#4E2A84; font-size:20px">&#9733;</span>
                          </div>
                          <div v-else v-bind:key="'fve-' + index" align="center">
                            <span v-bind:key="'fvs2-' + index" style="color:#FFFFFF; font-size:20px">&#9733;</span>
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <template v-if="(key === '_PROJECTED')">
                          <div v-show="entry.editable" v-bind:key="'pj-' + index" class="edit-mode">
                            <span v-bind:key="'pjs1-' + index" class="dollar">$</span>
                            <input v-model="entry[key]" tabindex="0" type="text" style="text-align:right" @keyup.enter="accept(entry, $event)" @blur="accept(entry, $event)">
                            <i v-if="entry.editable !== null" class="ion-checkmark-circled" @click.stop="toggleEdit(entry, $event)" />
                          </div>

                          <div v-show="!entry.editable" v-bind:key="'sh-' + index" class="non-edit-mode">
                            <span v-bind:key="'shs1-' + index" @click.stop="toggleEdit(entry, $event)">
                              <span v-if="entry['_clickable']" v-bind:key="'shs2-' + index" class="dollar">$</span>

                              <ampsvuecountup
                                v-if="isCurrency(key, entry) "
                                class="myCounter"
                                v-bind:decimal="2"
                                v-bind:end="parseFloat(entry[key]) || 0"
                                v-bind:duration="1"
                                v-bind:options="{useEasing : true,
                                                 useGrouping : true,
                                                 separator : ',',
                                                 decimal : '.',
                                                 prefix : '',
                                                 suffix : ''}"
                              />

                              <span v-if="!isCurrency(key, entry)" v-bind:key="'shs3-' + index">{{ formatValue(key, entry) }}</span>

                            </span>
                            <i v-if="entry.editable !== null" class="ion-edit" @click.stop="toggleEdit(entry, $event)" />
                          </div>
                        </template> <!-- '_PROJECTED' -->

                        <template v-else>
                          <ampsvuecountup
                            v-if="isCurrency(key, entry) "
                            class="myCounter"
                            v-bind:decimal="2"
                            v-bind:end="parseFloat(entry[key])"
                            v-bind:duration="1"
                            v-bind:options="{useEasing : true,
                                             useGrouping : true,
                                             separator : ',',
                                             decimal : '.',
                                             prefix : '',
                                             suffix : ''}"
                          />
                          <span v-if="!isCurrency(key, entry)" v-bind:key="'shs4-' + index">{{ formatValue(key, entry) }}</span>
                        </template>
                      </template>
                      <!-- </transition-group> -->
                      <!-- </transition> -->
                    </td>
                  </tr>

                  <!-- Footer Rows -->
                  <tr v-for="(entry, index) in footer_rows" v-bind:key="'footentry-' + index" class="footer-row">
                    <td v-for="key in columns" v-bind:key="key" v-bind:title="entry[key]" v-bind:class="formatClass(key, entry)" @click.stop="delegateClick(entry, key, $event)">
                      <transition-group name="expand">
                        <template v-if="key === 'profile_style'">
                          <div v-bind:key="'ft1-' + index" class="user-thumb" v-bind:style="{background: entry[key]}">
                            {{ entry[key] ? '' : entry['PrimaryFirstName'][0] }}
                            {{ entry[key] ? '' : entry['PrimaryLastName'][0] }}
                          </div>
                        </template>
                        <template v-else>
                          <template v-if="(key === '_PROJECTED')">
                            <div v-show="entry.editable" v-bind:key="'ft2-' + index" class="edit-mode">
                              <span v-bind:key="'ft2s1-' + index" class="dollar">$</span><input v-model="entry[key]" pattern="[0-9]" tabindex="0" type="text" style="text-align:right" @keyup.enter="accept(entry, $event)" @blur="accept(entry, $event)">
                              <i v-if="entry.editable !== null" class="ion-checkmark-circled" @click.stop="toggleEdit(entry, $event)" />
                            </div>

                            <div v-show="!entry.editable" v-bind:key="'ft3-' + index" class="non-edit-mode">
                              <span v-bind:key="'ft3s1-' + index" @click.stop="toggleEdit(entry, $event)"><span class="dollar">$</span>{{ formatValue(key, entry) }}</span>
                              <i v-if="entry.editable !== null" class="ion-edit" @click.stop="toggleEdit(entry, $event)" />
                            </div>
                          </template> <!-- '_PROJECTED' -->

                          <template v-else>
                            <span v-bind:key="'ft4s-' + index">{{ formatValue(key, entry) }}</span>
                          </template>
                        </template>
                      </transition-group>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p v-if="error" class="error">{{ error }}</p>
              <p v-if="searchnoresult" class="error">{{ searchnoresult }}</p>

              <template v-if="limit < filtered_result_count">
                <a class="rd-load-more" @click="loadMore()">Load More</a>
              </template>
              <small class="loading-time">{{ performance }}</small>
            </div>
          </template> <!-- end table view-->
        </div> <!-- close table wrap-->
      </template>
    </div>
  </keep-alive>
</template> <!--end keep alive-->

<script>
import Vue from 'vue'
import AMPSVueCountUp from '../utils/amps-vue-countup/AMPSVueCountUp.vue'
import loading from './viz/loading.vue'
import {Dictionary} from '../utils/'
import mydropdown from './_mydropdown.vue'
import mydatepicker from './_mydatepicker.vue'
import myperiodpicker from './_myperiodpicker.vue'
import mytoggle from './_mytoggle.vue'
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {onClickaway: onClickaway},
  components: {
    loading: loading,
    mydropdown: mydropdown,
    mydatepicker: mydatepicker,
    myperiodpicker: myperiodpicker,
    mytoggle: mytoggle,
    ampsvuecountup: AMPSVueCountUp
  },
  props: {
    back: {type: Object, default: null},
    buttons: {type: Array, default: null},
    datepickerdata: {type: Object, default: null},
    periodpickerdata: {type: Object, default: null},
    toggledata: {type: Object, default: null},
    dropdowndata: {type: Object, default: null},
    hidesearch: {type: Boolean, default: null},
    hidesort: {type: Boolean, default: null},
    title: {type: String, default: null},
    columns: {type: Array, default: null},
    columnshidden: {type: Object, default: null},
    store: {type: Array, default: null},
    error: {type: String, default: null},
    labels: {type: Object, default: null},
    loading: {type: Boolean, default: null},
    performance: {type: String, default: null},
    projectiontooldata: {type: Object, default: null},
    // eslint-disable-next-line vue/prop-name-casing
    double_header: {type: Boolean, default: null},
    // eslint-disable-next-line vue/prop-name-casing
    footer_rows: {type: Array, default: null},
    sortkey: {type: String, default: null},
    sortdir: {type: Number, default: null},
    sortorders: {type: Object, default: null},
    selection: {type: String, default: null}
  },
  data () {
    return {
      searchnoresult: null,
      projection_tool: {modal_expanded: false},
      table_mode: 'HORIZONTAL',
      rows: [],
      modal_expanded: false,
      selectedRow: {},
      limit: process.env.VUE_APP_LIMIT,
      pagesize: process.env.VUE_APP_PAGESIZE,
      filtered_result_count: '-',
      sortKey: null,
      sortDir: 1,
      sortOrders: {},
      search: {query: ''},
      cache: {},
      last_loading_time: '',
      height: window.innerHeight / 2,
      NO_ACCESS: false,
      debounce: null,
      netid: ''
    }
  },
  watch: {
    // We don't set headers dynamically. Otherwise, use the next line.
    // that.data.columns = data.headers;
    // We also don't set the rows directly. They are stored in JS
    // and filtered before being added to the DOM. Search and Sort is applied.
    // that.data.rows = data.rows;
    // Store the rows as 'raw' JS in the store.
    // '$parent.$data': {
    store: {
      handler: function (newVal, oldVal) {
        this.view()
        this.initSkrollr()
      },
      deep: true
    },
    '$parent.selectdata': {
      handler: function (newVal, oldVal) {
        this.selectdata = newVal
        this.view()
        this.initSkrollr()
      },
      deep: true
    },
    '$parent.blankresult': {
      handler: function (newVal, oldVal) {
        this.view()
        this.initSkrollr()
      },
      deep: true
    }
  },
  mounted () {
    var userString = localStorage.getItem('user')
    var user = JSON.parse(userString)
    this.netid = user.data.uid
    // console.log('this netid: ', this.netid)

    // Note -- we are waiting for an async Web Service call results as a 'prop'.
    // Add behaviors under the 'watch:{}' handler for this view.
    if (!this.limit) { this.limit = process.env.VUE_APP_LIMIT }
    if (!this.pagesize) { this.pagesize = process.env.VUE_APP_PAGESIZE }

    // default values:
    this.sortKey = this.sortkey || null
    this.sortDir = this.sortdir || 1
    this.sortOrders = this.sortorders || {}

    // Add Event Listener for window resizing.
    // var that = this
    window.addEventListener('resize', () => {
      this.resize()
    })
    this.resize()
  },
  methods: {
    isCurrency (key, entry) {
      if (this.$parent.useCountUp && this.$parent.useCountUp()) {
        return Dictionary.isCurrency(key, entry)
      }
    },
    buttonClick (button_value) {
      if (this.$parent.buttonClick) {
        this.$parent.buttonClick(button_value)
      }
    },
    formatTitle (key, selection) {
      return this.$parent.formatTitle ? this.$parent.formatTitle(key, selection) : key
    },
    newProjection () {
      console.log('create')
      this.$parent.newProjection()
    },
    saveProjection () {
      console.log('save')
      this.$parent.saveProjection()
    },
    manageProjections () {
      console.log('manage')
      this.projection_tool.modal_expanded = !(this.projection_tool.modal_expanded)
    },
    deleteProjection (row) {
      this.projection_tool.modal_expanded = false
      this.$parent.deleteProjection(row)
      this.projection_tool.modal_expanded = true
    },
    applyProjection (projection) {
      this.projection_tool.modal_expanded = false
      this.$parent.applyProjection(projection)
    },
    foo () {
      this.modal_expanded = false
    },
    mark (row, state) {
      if ('mark' in this.$parent) {
        this.$parent.mark(row, state)
        this.foo()
      }
    },
    accept (row, $event) {
      row.editable = false
      if (this.$parent.accept) {
        this.$parent.accept(row)
      }
    },
    toggleEdit (row, $event) {
      if ('editable' in row && row.editable !== null) {
        row.editable = !row.editable
        console.log('R', row.editable)
        // auto focus input

        // find the input element
        var $input = $event.target.parentElement.parentElement.parentElement.querySelector('input')
        Vue.nextTick(() => {
          if ('value' in $input) {
            if ($input.value === 0) {
              $input.value = ''
            }
            $input.focus()
          }
        })
      }
    },
    delegateClick (row, key, $event) {
      if ('_clickable' in row && row['_clickable']) {
        if (this.$parent.delegateClick) {
          return this.$parent.delegateClick(row, key, $event)
        }
      }
      var is_projected_td = $event.target.className.match(/_PROJECTED/) ||
        $event.target.parentElement.className.match(/_PROJECTED/) ||
        $event.target.parentElement.parentElement.className.match(/_PROJECTED/)
      var is_editable = 'editable' in row
      if (!is_projected_td || !is_editable) {
        this.selectRow(row)
        return
      }
      $event.stopPropagation()
      $event.preventDefault()
      console.debug('cancel the click')
    },
    selectDate (yyyy, mm) {
      if (this.$parent.selectDate) {
        this.$parent.selectDate(yyyy, mm)
      }
    },
    selectPeriod (new_period) {
      if (this.$parent.selectPeriod) {
        this.$parent.selectPeriod(new_period)
      }
    },
    view (reset_limit) {
      // reset the limit, optional. Default: false
      if (reset_limit) {
        this.limit = process.env.VUE_APP_LIMIT
      }
      // Trigger the Table DOM update by updating its bounded key ('rows');
      var filtered_rows = this.store.filter(row => this.tableFilter(row))
      this.filtered_result_count = filtered_rows.length
      // sort based on user input
      var that = this

      var currency_keys = Dictionary.getCurrencyKeys()

      var cmp = function (a, b) {
        if (currency_keys.indexOf(that.sortKey) >= 0) {
          return that.sortDir * (parseFloat(a[that.sortKey]) < parseFloat(b[that.sortKey]) ? -1 : 1)
        } else {
          return that.sortDir * (('' + a[that.sortKey]).toLocaleLowerCase() < ('' + b[that.sortKey]).toLocaleLowerCase() ? -1 : 1)
        }
      }
      var rowsSorted = filtered_rows.sort(cmp)
      var rowsSliced = rowsSorted.slice(0, this.limit)
      this.rows = rowsSliced
      if ((!that.error) && (!that.loading) && filtered_rows.length < 1) {
        this.searchnoresult = 'Searched data not available'
      } else {
        this.searchnoresult = null
      }
    },
    initSkrollr () {
      // skip the skrollr (double header), unless explicitly requested via prop
      if (!this.double_header) {
        console.log('Skipping skroller')
      }
      // only run this IF we are in non-mobile mode.
      // eslint-disable-next-line no-unused-vars
      // var is_touch_available
      // try {
      //   is_touch_available = 'ontouchstart' in document.documentElement
      // } catch (e) {
      //   is_touch_available = false
      // }

      // if (this.table_mode === 'HORIZONTAL' && !is_touch_available){
      //   if ('s' in window && window.s){
      //     window.s.destroy();
      //   }
      //   window.s = skrollr.init({
      //     forceHeight: false
      //   });
      // }
    },
    loadMore () {
      this.limit += this.pagesize
      this.view()
    },
    tableFilter (row) {
      if (this.search.query === '') {
        return true
      }
      var tokens = (this.search.query || '').split(' ').map(it => it.toLocaleLowerCase())
      var values_str = this.columns
        .filter(val => val !== 'null')
        .map(key => ('' + row[key]).toLocaleLowerCase())
        .join('\t')
      var visible = tokens.filter(token => !values_str.match(token)).length === 0
      return visible
    },
    sortBy (key) {
      if (key === '_PROJECTED') {
        console.debug('Ignore sort on _PROJECTIED')
        return
      }
      var newDir = this.sortOrders[key] > 0 ? -1 : 1
      this.sortOrders[key] = newDir
      this.sortDir = newDir
      this.sortKey = key
      this.view()
    },
    selectRow (row) {
      if (this.title === 'Pending') {
        this.modal_expanded = true
        this.selectedRow = row
      } else {
        if ('selectRow' in this.$parent) {
          this.$parent.selectRow(row)
        }
      }
      // destroy the init skrollr. Prevents it from messing up other pages.
      // window.s.destroy();
    },
    formatLabel (key, selection) {
      if ('formatLabel' in this.$parent) {
        return this.$parent.formatLabel(key, selection)
      } else {
        return Dictionary.formatLabel(key, selection)
      }
    },
    formatClass (key, row) {
      if (this.$parent.ignored_keys) {
        if (this.$parent.ignored_keys.indexOf(key) >= 0) {
          return Dictionary.formatClass(key, row, false)
        }
      }

      return Dictionary.formatClass(key, row, true)
    },
    formatValue (key, row) {
      return Dictionary.formatValue(key, row)
    },
    isNegative (number) {
      return parseFloat(number) < 0
    },
    resize () {
      var WIDTH_TRESH = 751
      if (window.innerWidth < WIDTH_TRESH) {
        this.table_mode = 'VERTICAL'
      } else {
        this.table_mode = 'HORIZONTAL'
      }
    },
    debounceSearch (event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {}, 300)
    },
    updatetabledropdowndata (emitValue) {
      this.$emit('update-selectdata', emitValue)
    },
    updatetabletoggledata (emitValue) {
      this.$emit('update-selectdata', emitValue)
    }
  }
}
</script>
<style lang="scss">
  tr.footer-row:first-child {
  border-top: 1px solid #ccc;
  }
  tr.footer-row td{
    background : #eee;
    font-weight : bold;
  }
  .projection.disabled {
    opacity : 0.5;
  }
  .no-currency {
    &::before {
      content:'' !important;
      margin:0 !important;
    }
  }
  .edit-mode {
    input {
      width : 90%;
      padding:0;
      border:0;
    }
  }
</style>
