<template>
  <!-- keep-alive="false" -->
  <div>
    <button class="dir-btn prev page-top" @click="backToPortfolio()">Back to {{ $route.params.netid }}'s protocols</button>
    <div class="metadata-block">
      <!-- <h1>{{data.rows['protocol_name']}}</h1> -->
      <template v-if="data.loading">
        <div style="position:relative;width:100%;height:40px">
          <loading />
        </div>
      </template>

      <div class="metadata-flex-wrap">
        <div class="sponsored-project-metadata">
          <ul>
            <li v-bind:title="formatTitle('protocol_id')">
              <div class="key">{{ formatLabel('protocol_id') }}</div>
              <div class="value">{{ data.rows['protocol_id'] }}</div>
            </li>
            <li v-bind:title="formatTitle('pi_last_name')">
              <div class="key">{{ formatLabel('pi_last_name') }}</div>
              <div class="value">{{ data.rows['pi_last_name'] }}</div>
            </li>
            <li v-bind:title="formatTitle('pi_first_name')">
              <div class="key">{{ formatLabel('pi_first_name') }}</div>
              <div class="value">{{ data.rows['pi_first_name'] }}</div>
            </li>
            <li v-bind:title="formatTitle('protocol_status')">
              <div class="key">{{ formatLabel('protocol_status') }}</div>
              <div class="value">{{ data.rows['protocol_status'] }}</div>
            </li>
            <li v-bind:title="formatTitle('expiration_date')">
              <div class="key">{{ formatLabel('expiration_date') }}</div>
              <div class="value">{{ formatValue("expiration_date", data.rows) }}</div>
            </li>

            <li v-bind:title="formatTitle('dept_chart_actt')">
              <div class="key">{{ formatLabel('dept_chart_actt') }}</div>
              <div class="value">{{ formatValue("dept_chart_actt", data.rows) }}</div>
            </li>
            <li v-bind:title="formatTitle('grant_number')">
              <div class="key">{{ formatLabel('grant_number') }}</div>
              <div class="value">{{ formatValue("grant_number", data.rows) }}</div>
            </li>
            <li v-bind:title="formatTitle('funding_information')">
              <div class="key">{{ formatLabel('funding_information') }}</div>
              <div class="value">{{ formatValue("funding_information", data.rows) }}</div>
            </li>
            <li v-bind:title="formatTitle('funding_other')">
              <div class="key">{{ formatLabel('funding_other') }}</div>
              <div class="value">{{ formatValue("funding_other", data.rows) }}</div>
            </li>
            <li v-bind:title="formatTitle('initial_approval_date')">
              <div class="key">{{ formatLabel('initial_approval_date') }}</div>
              <div class="value">{{ formatValue("initial_approval_date", data.rows) }}</div>
            </li>
            <li v-bind:title="formatTitle('last_approval_date')">
              <div class="key">{{ formatLabel('last_approval_date') }}</div>
              <div class="value">{{ formatValue("last_approval_date", data.rows) }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Dictionary, Timer} from '../../../utils/'
import loading from '../../viz/loading.vue'
import Wildcat from '../Wildcat.json'
import {ReportService} from '../../../services/'
import AuthService from '../../../auth/AuthService.js'

export default {
  components: {
    loading: loading
  },
  data () {
    return {
      prjid: '',
      data: {
        rows: [],
        loading: true
      }
    }

  },
  mounted () {
    this.load()
  },
  methods: {
    formatTitle (key) {
      return Dictionary.formatTitle(key, 'ORITIACUC_GetProtocolByID')
    },
    formatLabel (key) {
      return Dictionary.formatLabel(key, 'ORITIACUC_GetProtocolByID')
    },
    backToPortfolio () {
      var path = '/projects/' + this.$route.params.netid + '/protocols'
      this.$router.push({path: path})
    },
    formatValue (key, row) {
      return Dictionary.formatValue(key, row)
    },
    load () {
      if (this.$route.params.netid === 'wildcat') {
        this.prjid = Wildcat.protocol.IACUCprj
      } else {
        this.prjid = this.$route.params.pid
      }
      var timer = new Timer()
      this.data.loading = true
      var that = this
      if (!AuthService.user.data) {
        AuthService.logout()
        return
      }
      var opts = {
        protocol_id: this.prjid,
        service: 'IACUCProID',
        path: 'protocols'
      }
      if (AuthService.user.data.RD014_ROLE.role === 'NONE' || (AuthService.user.data.RD014_ROLE.role === 'RESEARCHER' && AuthService.user.data.uid.toUpperCase() !== that.$route.params.netid)) {
        that.data.loading = false
        that.data.error = 'Not Authorized'
        return
      }
      ReportService.get(opts, this.$store).then((data) => {
        that.data.loading = false
        if ('error' in data) {
          that.data.error = data.error
        } else {
          if (data.indexOf('Internal Server Error') >= 0) {
            that.error = 'ORIT is doing daily refresh, please check back later. Thanks.'
            return
          }
          that.data.rows = data[0]
          if (that.$route.params.netid === 'wildcat') {
            that.data.rows['protocol_id'] = that.$route.params.pid
          }
        }
        timer.tick('loaded in ')
        that.data.performance = timer.toString()
        that.data.loading = false
      }).catch(err => {
        that.data.loading = false
        that.error = err
        console.log('Unable to parse the results', err)
      })
    }
  }
}
</script>
