import {DateUtils} from '../utils/'

// IBM data dictionary
var dict = {}
// eslint-disable-next-line no-unused-vars
var is_loading = false

// callback(err, success);
function load (callback) {
  is_loading = true
  var url = process.env.VUE_APP_API_URL + '/dictionary'
  fetch(url).then(function (response) {
    is_loading = false
    return response.json()
  }).then(function (data) {
    is_loading = false
    dict = JSON.parse(data)
    callback(null, true)
    console.log('Loaded IBM data dictionary')
  }).catch(function (err) {
    is_loading = false
    console.log('Error loading dictionary', err)
    callback(err)
  })
}

function isCurrency (key, entry) {
  var clickable = '_clickable' in entry ? entry['_clickable'] : false
  if (key === 'BALANCE') {
    return true
  } else if (key === '_PROJECTED' && !clickable) {
    return true
  } else {
    return false
  }
}

function getCurrencyKeys () {
  return [
    'Amount',
    'Balance',
    'Budget',
    'Encumbered',
    '_PROJECTED',
    'Actual',
    'ActualExpense',
    'FYTDActualExpense',
    'FYTDPreEncEncExpense',
    'CurrentExpenseBudget',
    'CurrentRevenueBudget',
    'BudgetCarryforward',
    'FYTDActualsAndEncExpense',
    'NetBudget',
    'NetBalance',
    'RevenueLessActual',
    'ExpenseBudgetBalance',
    'RevenueLessExpense',
    '_SUM',
    'PaymentAmount',
    'InvoiceAmount',
    'SubContractAmount',
    'AmountPaid',
    'BalanceRemaining',
    'POAmount',
    'Total',
    'ClinicalTrailBalance'
  ]
}

/**
* Returns a list of CSS classes to be added
*/
function formatClass (key, row, clickable_col) {
  var currency_keys = getCurrencyKeys()
  var key_without_spaces = key.replace(/ /g, '_')
  var classes = [key_without_spaces]
  if (key.match(/Period/)) {
    return 'BUDGET_PERIOD currency'
  }
  // is currency?
  // ALSO, in the month-by-month, the columns will be '2016 - 03'.
  if (currency_keys.indexOf(key) >= 0 || key.match(/^\d{4} - \d{2}$/)) {
    classes.push('currency')
    if (key === '_PROJECTED' && 'editable' in row && row.editable) {
      classes.push('no-currency')
    }
    if (row.SalaryRestricted) {
      classes.push('no-currency')
    }

    var value = parseFloat(row[key])
    // is negative?
    if (value < 0.0) {
      classes.push('negative')
    }
    // is zero?
    if (value === 0.0) {
      classes.push('zero')
    }
  }

  // is willie?
  if (key === 'profile_style') {
    if ('ResearcherNetID' in row && row['ResearcherNetID'] === 'wildcat') {
      classes.push('wildcat')
    }
  }

  // Used for tables where clicking on an individual CELL is required.
  if ('_clickable' in row && row['_clickable']) {
    // we don't want to highlight cells that are the first column in a row.
    var ignore_column_names = ['AccountCategoryDescription']
    if (ignore_column_names.indexOf(key) < 0 && clickable_col) {
      classes.push('clickable')
    }
  }

  return classes.join(' ')
}

function _formatCurrency (value) {
  var USE_THE_INLINE_DOLLAR_SIGN = false
  if (USE_THE_INLINE_DOLLAR_SIGN) {
    // eslint-disable-next-line no-undef
    return '$ ' + accounting.formatNumber(value, 2)
  } else {
    // eslint-disable-next-line no-undef
    return accounting.formatNumber(value, 2)
  }
}

function _formatCurrencyPercent (d, p) {
  // eslint-disable-next-line no-undef
  return accounting.formatNumber(d, 2) + ' (' + p
}

/**
* Supports computed values.
*/
function formatValue (key, row) {
  if (key === 'DATE' && 'length' in row && row.length === 0) {
    return 'loading...'
  }
  // set default value for row to empty object.
  row = row || {}
  // short-circuit for date (yyyy-mm)
  if (key.match(/^\d{4} - \d{2}$/) || key.match(/Period/) || key.match(/Total/)) {
    var value = row[key]
    if (value && (value.toString().includes('%') || value.toString().includes('N/A'))) {
      var dollar = parseFloat(value)
      var percent = value.split('(')[1]
      return _formatCurrencyPercent(dollar, percent)
    } else if (value === 'SalaryRestricted') {
      return ''
    } else {
      var n = parseFloat(value)
      return _formatCurrency(n)
    }

  }
  switch (key) {
    case 'profile_style':
      return
    case 'DATE': // computed value
      var dt_start = this.formatValue('ProjectStartDate', row)
      var dt_end = this.formatValue('ProjectEndDate', row)
      return dt_start + ' to ' + dt_end
    case 'Amount': // deliberate fall-through
    case 'Actual': // deliberate fall-through
    case 'ActualExpense': // deliberate fall-through
    case 'Balance': // deliberate fall-through
    case 'Budget': // deliberate fall-through
    case 'CurrentExpenseBudget': // deliberate fall-through
    case 'Encumbered': // deliberate fall-through
    case 'ExpenseBudgetBalance': // deliberate fall-through
    case 'FYTDActualExpense': // deliberate fall-through
    case 'FYTDPreEncEncExpense': // deliberate fall-through
    case 'FYTDActualRevenue': // deliberate fall-through
    case 'NetBudget': // deliberate fall-through
    case 'NetBalance': // deliberate fall-through
    case 'RevenueLessExpense': // deliberate fall-through
    case 'RevenueLessActual': // deliberate fall-through
    case '_PROJECTED': // deliberate fall-through
    case '_SUM': // deliberate fall-through
    case 'TotalPaymentsApplied': // deliberate fall-through
    case 'CurrentRevenueBudget': // deliberate fall-through
    case 'FYTDActualsAndEncExpense': // deliberate fall-through
    case 'PaymentAmount': // deliberate fall-through
    case 'SubContractAmount': // deliberate fall-through
    case 'AmountPaid': // deliberate fall-through
    case 'POAmount': // deliberate fall-through
    case 'InvoiceAmount': // deliberate fall-through
    case 'BudgetCarryforward': // deliberate fall-through
    case 'ClinicalTrailBalance': // deliberate fall-through
    case 'TotalExpenseBudget': // deliberate fall-through
    case 'BalanceRemaining':
      // eslint-disable-next-line no-redeclare
      var value = row[key]
      if (value && (value.toString().includes('%') || value.toString().includes('N/A'))) {
        // eslint-disable-next-line no-redeclare
        var dollar = parseFloat(value)
        // eslint-disable-next-line no-redeclare
        var percent = value.split('(')[1]
        return _formatCurrencyPercent(dollar, percent)
      } else {
        // eslint-disable-next-line no-redeclare
        var n = parseFloat(value)
        return _formatCurrency(n)
      }
    case 'Date': // deliberate fall-through
    case 'DepositDate': // deliberate fall-through
    case 'PostedDate': // deliberate fall-through
    case 'ProjectStartDate': // deliberate fall-through
    case 'ProjectEndDate': // deliberate fall-through
    case 'DateSubmitted': // deliberate fall-through
    case 'ProjectRequestStartDate': // deliberate fall-through
    case 'ProjectRequestEndDate': // deliberate fall-through
    case 'FYStartDate': // deliberate fall-through
    case 'FYEndDate': // deliberate fall-through
    case 'DateSent': // deliberate fall-through
    case 'AccountingDate': // deliberate fall-through
    case 'InvoiceDate': // deliberate fall-through
    case 'expiration_date':
    case 'initial_approval_date':
    case 'last_approval_date':
    case 'CheckDate':// deliberate fall-through

      var cleanupDate = function (str) {
        if (str === undefined || str === '-') {
          return 'n/a'
        } else {
          return str.replace(/T.*$/, '')
        }
      }
      // input: yyyy-mm-dd
      // output: Apr 24, 2015
      var formatDate = function (str) {
        if (str === 'n/a' || str.indexOf('2099-01-01') >= 0 || str.indexOf('1900-01-01') >= 0) {
          return '-'
        }
        var suffix = 'T12:00:00'
        var dt = new Date(str + suffix)
        var day = dt.getDate()
        var year = dt.getFullYear()
        var month = DateUtils.MONTHS[dt.getMonth()]
        return month + ' ' + day + ', ' + year
      }

      var clean_date = cleanupDate(row[key])
      var formatted_date = formatDate(clean_date)
      return formatted_date
      // return this.$options.filters.moment(row[key], dateFmt);
    default:
      // eslint-disable-next-line no-redeclare
      var value = row[key]
      var NOT_IDENTIFIED = '[Not identified]'
      if (value === 'null') {
        return NOT_IDENTIFIED
      } else {
        return value
      }
  }
}

function formatTitle (label, selection) {
  if (selection && selection in dict && label in dict[selection]) {
    // console.log("IBM found definition for:", label + "," + selection);
    // fallback to the human-readable-label, if there is no long_description,
    // or if the long_description is an empty string.
    var long = dict[selection][label].long_description
    if (long) {
      return long
    }

  }
  if ((selection === 'PayrollByMonthForEmployee' || selection === 'PayrollByMonthForProject') && label.match(/\d{4} - \d{2}$/)) {
    return 'Total pay for the month and percent of base salary'
  }
  return formatLabel(label, selection)
}

function formatLabel (label, selection) {
  // special instance where date label need to be changed
  if (selection === 'PeoplePaidFromProjects' && label === 'YYYYMM') {
    var short = dict[selection][label].short_description
    return short
  };

  // check if the label is a date!
  if (label.match(/\d{4} - \d{2}$/)) {
    var yyyy = parseInt(label.match(/^\d{4}/).pop(), 10)
    var m = parseInt(label.match(/\d{2}$/).pop(), 10)
    var n_months_to_add = 0
    if (selection === 'SponsoredProjectFinancialsMonthly') {
      n_months_to_add = -4
    }
    var d = DateUtils.addMonths(yyyy, m, n_months_to_add)
    var month_name_idx = d[1] - 1
    // var out = key + " | " + this.months[month_name_idx] + " " + d[0];
    // var out = this.months[month_name_idx] + " " + d[0];
    var out = DateUtils.MONTHS[month_name_idx] + ' ' + d[0]
    return out
  };

  if (selection && selection in dict && label in dict[selection]) {
    // eslint-disable-next-line no-redeclare
    var short = dict[selection][label].short_description
    return short
  }

  var labels = {
    '_CHARTSTRING': 'Chart String',
    'CHARTSTRING': 'Chart String',
    'NetBudget': 'Net Budget',
    'NetBalance': 'Net Balance',
    'profile_style': '',
    'AccountDescription': 'Account Description'
  }
  return label in labels ? labels[label] : label
}

// Dictionary of all terms
var terms = {}
var NOT_FOUND = null
// set a definition. The data is a string.
function set (term, data) {
  var key = makeKey(term)
  terms[key] = data
}
// Make sure we can encode the TERM as lowercase encoded URI
function makeKey (term) {
  return encodeURIComponent(term.toLocaleLowerCase())
}
// get a definition of a term.
function get (term) {
  var key = makeKey(term)
  return key in terms ? terms[key] : NOT_FOUND
}
// list all the terms in the dictionary.
function list () {
  return Object.keys(terms)
}

export default {
  formatValue: formatValue,
  formatLabel: formatLabel,
  formatClass: formatClass,
  formatTitle: formatTitle,
  isCurrency: isCurrency,
  load: load,
  getCurrencyKeys: getCurrencyKeys,
  set: set,
  list: list,
  get: get
}
