<template>
  <mytable
    title=""
    v-bind:back="{text:'Overview'}"
    class="expense-details"
    v-bind:columns="data.columns"
    v-bind:store="data.rows"
    v-bind:labels="data.labels"
    v-bind:loading="data.loading"
    v-bind:performance="data.performance"
    v-bind:error="data.error"
  />
</template>

<script>
import mytable from '../../_mytable.vue'
import {ReportService} from '../../../services/'
import {Timer, Dictionary} from '../../../utils/'
import Wildcat from '../Wildcat.json'

export default {
  components: {
    mytable: mytable
  },
  data () {
    return {
      selectdata: {
        options: [],
        idx: 0
      },
      data: {
        // columns: ["Transaction Type", "Account Description", "Account Code", "Description", "Amount", "Date", "Transaction ID"],
        columns: ['AccountDescription', 'Description', 'Amount', 'Date', 'TransactionID'],
        rows: [],
        labels: {
          'Account Description': 'Account',
          'Date': 'Budget Check Date',
          'Transaction ID': 'Reference'
        },
        loading: false
      }
    }
  },
  watch: {
    '$parent.$data.details.selectdata': {
      handler: function (newVal, oldVal) {
        this.selectdata = newVal
        console.debug('GOT SELECTION', newVal)
        this.load()
      },
      deep: true
    }
  },
  mounted () {
    if (this.$route.params.netid === 'wildcat') {
      this.project_id = Wildcat.sponsored.PROJECT_ID
    } else {
      this.project_id = this.$route.params.id
    }
  },
  methods: {
    formatLabel (key) {
      var selection = 'SponsoredITDTransactionDetail'
      return Dictionary.formatLabel(key, selection)
    },
    formatTitle (key) {
      var selection = 'SponsoredITDTransactionDetail'
      return Dictionary.formatTitle(key, selection)
    },
    goBack () {
      this.$parent.goBack()
    },
    selectOpt (value) {
      this.load(value)
    },
    selectRow (row) {
      console.log('nothing to fetch yet')
    },
    load (account_code) {
      var timer = new Timer()
      this.data.loading = true
      var that = this

      // Check the default account code from call arg, otherwise,
      // get the value from the select object.
      if (account_code === undefined) {
        account_code = this.selectdata.options[this.selectdata.idx].value
      }

      // YYYYMM
      // @TODO: Add the date translation
      var d = new Date()
      var yyyy = d.getFullYear()
      var mm = (d.getMonth() + 1)
      mm = mm < 10 ? '0' + mm : mm
      var end_dt = '' + yyyy + mm
      var start_dt = '' + (yyyy - 5) + mm

      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD010',
        p_PrjID: this.$route.params.id,
        p_Account: account_code,
        p_FromYYYYMM: start_dt,
        p_ToYYYYMM: end_dt,
        rowLimit: 50
      }

      this.data.error = ''
      ReportService.get(report_opts, this.$store).then(data => {
        that.data.loading = false
        if ('error' in data) {
          that.data.error = data.error
        } else {
          // that.data.columns = out.headers;
          that.data.rows = data.rows
          console.debug('DATA', data)
        }
        timer.tick('loaded in ')
        that.data.performance = timer.toString()
      }).catch(err => {
        that.data.loading = false
        console.error('Unable to parse the results', err)
      })
    }
  }
}
</script>
<style lang="scss">
.expense-details {
  th.Amount,
  td.Amount {
    text-align: right;
  }
}
</style>
