var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-md-6 .col-md-offset-3" }, [
    _c("div", [
      _vm.AuthService.authenticated
        ? _c("div", [
            _c("img", {
              staticClass: "user-profile-picture",
              staticStyle: { float: "left", "margin-right": "10px" },
              attrs: {
                src:
                  "data:image/jpeg;base64," +
                  _vm.AuthService.getUser().data.jpegPhoto,
              },
            }),
            _c(
              "div",
              { staticStyle: { display: "inline-block", float: "left" } },
              [
                _c("h3", { staticStyle: { "margin-top": "0" } }, [
                  _vm._v(_vm._s(_vm.AuthService.getUser().data.displayName[0])),
                ]),
                _c("p", [_vm._v(_vm._s(_vm.AuthService.getUser().data.mail))]),
                _c(
                  "button",
                  {
                    staticClass: "rd-small-button user_logout",
                    on: {
                      click: function ($event) {
                        return _vm.AuthService.logout()
                      },
                    },
                  },
                  [_vm._v("Sign off")]
                ),
              ]
            ),
          ])
        : _vm._e(),
      !_vm.AuthService.authenticated
        ? _c("div", [
            _c("h2", [_vm._v("Please login with your NETID")]),
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _c("p", [_vm._v(_vm._s(_vm.error))]),
                ])
              : _vm._e(),
            !_vm.AuthService.isAuthenticating
              ? _c("div", [
                  !_vm.AuthService.authenticated
                    ? _c("div", [
                        _c(
                          "a",
                          {
                            staticClass: "gm-button netid_login",
                            on: {
                              click: function ($event) {
                                return _vm.AuthService.login()
                              },
                            },
                          },
                          [_vm._v("Login With Your NetID")]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.AuthService.isAuthenticating
              ? _c(
                  "div",
                  [_c("p", [_vm._v("Authenticating....")]), _c("loading")],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }