var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.tryagain
        ? _c(
            "div",
            { staticClass: "modal-mask download-modal" },
            [
              _c("transition", { attrs: { name: "modal" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "on-clickaway",
                        rawName: "v-on-clickaway",
                        value: _vm.hideTryagain(),
                        expression: "hideTryagain()",
                      },
                    ],
                    staticClass: "modal-container",
                  },
                  [
                    _c("div", { staticClass: "modal-header" }, [
                      _c(
                        "div",
                        {
                          staticClass: "modal-close",
                          on: {
                            click: function ($event) {
                              return _vm.hideTryagain()
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                    ]),
                    _c("div", { staticClass: "modal-body transaction" }, [
                      _c("p", [
                        _vm._v("Data load timed out. Please, refresh page."),
                      ]),
                    ]),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "rd-small-button feed_close",
                          on: {
                            click: function ($event) {
                              return _vm.hideTryagain()
                            },
                          },
                        },
                        [_vm._v("Ok")]
                      ),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "button",
        {
          ref: "backBtn",
          staticClass: "dir-btn prev page-top",
          on: {
            click: function ($event) {
              return _vm.backToPortfolio()
            },
          },
        },
        [_vm._v("Back to " + _vm._s(_vm.$route.params.netid) + "'s Portfolio")]
      ),
      _c(
        "div",
        { staticClass: "metadata-block" },
        [
          _c("h1", [
            _vm._v(
              _vm._s(_vm.data.rows["ProjectName"]) +
                " " +
                _vm._s(
                  "(Project ID: " + (_vm.data.rows["ProjectID"] || "") + ")"
                )
            ),
          ]),
          _vm.data.loading
            ? [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "relative",
                      width: "100%",
                      height: "40px",
                    },
                  },
                  [_c("loading")],
                  1
                ),
              ]
            : _vm._e(),
          _c("div", { staticClass: "metadata-flex-wrap" }, [
            _c("div", { staticClass: "sponsored-project-metadata" }, [
              _c("ul", [
                _c(
                  "li",
                  {
                    attrs: {
                      title: _vm.formatTitle(
                        "ProjectSponsor",
                        "SponsoredProjectHeader"
                      ),
                    },
                  },
                  [
                    _c("div", { staticClass: "key" }, [
                      _vm._v("Direct Sponsor"),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.rows["ProjectSponsor"])),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  {
                    attrs: {
                      title: _vm.formatTitle(
                        "OriginatingSponsor",
                        "SponsoredProjectHeader"
                      ),
                    },
                  },
                  [
                    _c("div", { staticClass: "key" }, [
                      _vm._v("Originating Sponsor"),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.rows["OriginatingSponsor"])),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  {
                    attrs: {
                      title: _vm.formatTitle(
                        "ASRSPGCFA",
                        "SponsoredProjectHeader"
                      ),
                    },
                  },
                  [
                    _c("div", { staticClass: "key" }, [_vm._v("ASRSP GCFA")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.rows["ASRSPGCFA"])),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  {
                    attrs: {
                      title: _vm.formatTitle(
                        "PrimaryProjectPIName",
                        "SponsoredProjectHeader"
                      ),
                    },
                  },
                  [
                    _c("div", { staticClass: "key" }, [_vm._v("Primary PI")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.rows["PrimaryProjectPIName"])),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  {
                    attrs: {
                      title: _vm.formatTitle(
                        "ResearchAdministrator",
                        "SponsoredProjectHeader"
                      ),
                    },
                  },
                  [
                    _c("div", { staticClass: "key" }, [
                      _vm._v("Research Administrator"),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.rows["ResearchAdministrator"])),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  {
                    attrs: {
                      title: _vm.formatTitle(
                        "ManagingDepartmentDescription",
                        "SponsoredProjectHeader"
                      ),
                    },
                  },
                  [
                    _c("div", { staticClass: "key" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatLabel(
                            "ManagingDepartmentDescription",
                            "SponsoredProjectHeader"
                          )
                        )
                      ),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        _vm._s(_vm.data.rows["ManagingDepartmentDescription"])
                      ),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  {
                    attrs: {
                      title: _vm.formatTitle(
                        "AwardID",
                        "SponsoredProjectHeader"
                      ),
                    },
                  },
                  [
                    _c("div", { staticClass: "key" }, [_vm._v("Award ID")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.rows["AwardID"])),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  {
                    attrs: {
                      title: _vm.formatTitle(
                        "SponsorReferenceAwardNumber",
                        "SponsoredProjectHeader"
                      ),
                    },
                  },
                  [
                    _c("div", { staticClass: "key" }, [
                      _vm._v("Sponsor Reference Award #"),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        _vm._s(_vm.data.rows["SponsorReferenceAwardNumber"])
                      ),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  {
                    attrs: {
                      title: _vm.formatTitle("DATE", "SponsoredProjectHeader"),
                    },
                  },
                  [
                    _c("div", { staticClass: "key" }, [
                      _vm._v("Project Dates"),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.formatValue("DATE", _vm.data.rows))),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  {
                    attrs: {
                      title: _vm.formatTitle(
                        "TotalPaymentsApplied",
                        "SponsoredProjectHeader"
                      ),
                    },
                  },
                  [
                    _c("div", { staticClass: "key" }, [
                      _vm._v("Total Payments Applied"),
                    ]),
                    _c("div", { staticClass: "value special-currency" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatValue("TotalPaymentsApplied", _vm.data.rows)
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hasCT,
                        expression: "hasCT",
                      },
                    ],
                    attrs: {
                      title:
                        "formatTitle('ClinicalTrailBalance','SponsoredProjectHeader')",
                    },
                  },
                  [
                    _c("div", { staticClass: "key" }, [
                      _vm._v("Clinical Trial Balance"),
                    ]),
                    _c("div", { staticClass: "value special-currency" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatValue("ClinicalTrailBalance", _vm.data.rows)
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  {
                    attrs: {
                      title: _vm.formatTitle(
                        "FandARate",
                        "SponsoredProjectHeader"
                      ),
                    },
                  },
                  [
                    _c("div", { staticClass: "key" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatLabel("FandARate", "SponsoredProjectHeader")
                        )
                      ),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        _vm._s(_vm.formatValue("FandARate", _vm.data.rows))
                      ),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  {
                    attrs: {
                      title: _vm.formatTitle(
                        "_CHARTSTRING",
                        "SponsoredProjectHeader"
                      ),
                    },
                  },
                  [
                    _c("div", { staticClass: "key" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatLabel(
                            "_CHARTSTRING",
                            "SponsoredProjectHeader"
                          )
                        )
                      ),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        _vm._s(_vm.formatValue("_CHARTSTRING", _vm.data.rows))
                      ),
                    ]),
                  ]
                ),
                _vm._m(0),
                _c(
                  "li",
                  {
                    attrs: {
                      title: _vm.formatTitle(
                        "CarryForwardAllowed",
                        "SponsoredProjectHeader"
                      ),
                    },
                  },
                  [
                    _c("div", { staticClass: "key" }, [_vm._v("Carry Over")]),
                    _c("div", { staticClass: "value" }, [
                      _c("b", [
                        _vm._v(_vm._s(_vm.data.rows["CarryForwardAllowed"])),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  { attrs: { title: _vm.formatTitle("LastUpdateTime") } },
                  [
                    _c("div", { staticClass: "key" }, [
                      _vm._v("Last Update Time"),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _c("b", [_vm._v(_vm._s(_vm.ts))]),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "download-actions" },
              [
                _c(
                  "button",
                  {
                    staticClass: "rd-download excel",
                    class: { working: _vm.downloading.xlsx },
                    on: {
                      click: function ($event) {
                        return _vm.exportSponsoredProject("xlsx")
                      },
                    },
                  },
                  [_vm._v("DOWNLOAD PROJECT DATA")]
                ),
                _c("transactiondownload"),
              ],
              1
            ),
          ]),
          _vm.netid
            ? _c("div", { staticClass: "tabs" }, [
                _c(
                  "ul",
                  { staticClass: "nav nav-pills" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Project_Sponsored_InceptionToDate",
                            params: { netid: _vm.netid, id: _vm.id },
                          },
                          tag: "li",
                          role: "presentation",
                        },
                      },
                      [
                        _c("a", { staticClass: "finoverview tab" }, [
                          _vm._v("Financial Overview"),
                        ]),
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Project_Sponsored_People",
                            params: { netid: _vm.netid, id: _vm.id },
                          },
                          tag: "li",
                          role: "presentation",
                        },
                      },
                      [
                        _c("a", { staticClass: "people tab" }, [
                          _vm._v("Project Team"),
                        ]),
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Sponsored_CostShare",
                            params: { netid: _vm.netid, id: _vm.id },
                          },
                          tag: "li",
                          role: "presentation",
                        },
                      },
                      [
                        _c("a", { staticClass: "costshare tab" }, [
                          _vm._v("Cost Share Detail"),
                        ]),
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Sponsored_Payments",
                            params: { netid: _vm.netid, id: _vm.id },
                          },
                          tag: "li",
                          role: "presentation",
                        },
                      },
                      [
                        _c("a", { staticClass: "payments tab" }, [
                          _vm._v("Payments"),
                        ]),
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Sponsored_Subcontractor",
                            params: { netid: _vm.netid, id: _vm.id },
                          },
                          tag: "li",
                          role: "presentation",
                        },
                      },
                      [
                        _c("a", { staticClass: "subcontractor tab" }, [
                          _vm._v("Subcontracts"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("br")])
  },
]
render._withStripped = true

export { render, staticRenderFns }