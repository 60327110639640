<template>
  <div>
    <button class="dir-btn prev back-to-portfolio" @click="backToPortfolio()" ref="backBtn">Back to {{ $route.params.netid }}'s Portfolio</button>

    <div class="metadata-block">
      <h1>{{ data.rows['ProjectDescription'] }}</h1>

      <div v-show="data.loading">
        <div class="loader" style="position:relative;width:100%;height:40px">
          <loading />
        </div>
      </div>

      <div class="metadata-flex-wrap">
        <div class="non-sponsored-project-metadata">
          <ul>
            <li>
              <!-- Account Name -->
              <div class="key" v-bind:title="formatTitle('ProjectDescription')">{{ formatLabel("ProjectDescription") }}</div><div class="value">{{ data.rows['ProjectDescription'] }}</div>
            </li>
            <li>
              <div class="key" v-bind:title="formatTitle('Chartstring')">Chart String</div><div class="value">{{ cs }}</div>
            </li>
            <li>
              <!-- Fiscal Year -->
              <div class="key" v-bind:title="formatTitle('FYStartDate')">{{ formatLabel("FYStartDate") }}</div><div class="value">{{ formatValue('FYStartDate', data.rows) }} to {{ formatValue('FYEndDate', data.rows) }}</div>
            </li>
            <li><br></li>
            <li>
              <!-- Revenue -->
              <div class="key" v-bind:title="formatTitle('FYTDActualRevenue')">{{ formatLabel("FYTDActualRevenue") }}</div><div class="value">$ {{ formatValue("FYTDActualRevenue", data.rows) }} </div>
            </li>
            <li>
              <!-- Revenue budget -->
              <div class="key" v-bind:title="formatTitle('CurrentRevenueBudget')">{{ formatLabel("CurrentRevenueBudget") }}</div><div class="value">$ {{ formatValue("CurrentRevenueBudget", data.rows) }}</div>
            </li>
            <li>
              <div class="key" v-bind:title="formatTitle('BudgetCarryforward')">{{ formatLabel("BudgetCarryforward") }}</div><div class="value">$ {{ formatValue("BudgetCarryforward", data.rows) }}</div>
            </li>
            <li>
              <!-- Total Expense Budget -->
              <div class="key" v-bind:title="formatTitle('TotalExpenseBudget')">{{ formatLabel("TotalExpenseBudget") }}</div><div class="value">$ {{ formatValue("TotalExpenseBudget", data.rows) }}</div>
            </li>
            <li>
              <!-- Total Obligations -->
              <div class="key" v-bind:title="formatTitle('FYTDActualsAndEncExpense')">{{ formatLabel("FYTDActualsAndEncExpense") }}</div><div class="value">$ {{ formatValue("FYTDActualsAndEncExpense", data.rows) }}</div>
            </li>
            <li><br></li>
            <li>
              <!-- Refresh timestamp -->
              <div class="key" v-bind:title="formatTitle('LastUpdateTime')">Last Update Time</div><div class="value">{{ ts }}</div>
            </li>
          </ul>
        </div>

        <div class="download-actions">
          <button v-bind:class="{'working':downloading.xlsx }" class="rd-download excel" @click="exportNonSponsoredProject('xlsx')">DOWNLOAD PROJECT DATA</button>
        <!-- <button @click="download('xlsx')" v-bind:class="{'working':downloading.excel }" class="rd-download word">Download .docx</button> -->
        <!-- <button @click="download('xlsx')" v-bind:class="{'working':downloading.excel }" class="rd-download pdf">Download .pdf</button> -->
        </div>
      </div>

      <div class="tabs">
        <ul class="nav nav-pills">
          <router-link v-bind:to="{ name: 'NonSponsored_FiscalYearToDate', params: { netid: this.$route.params.netid, cs: this.$route.params.cs }}" tag="li" role="presentation">
            <a class="finoverview tab">Financial Overview</a>
          </router-link>
          <router-link v-bind:to="{ name: 'Sponsored_People', params: { netid: this.$route.params.netid, cs: this.$route.params.cs }}" tag="li" role="presentation">
            <a class="people tab">Project Team</a>
          </router-link>
        </ul>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import {ReportService, DataExportService} from '../../../services/'
import {Dictionary, Timer} from '../../../utils/'
import loading from '../../viz/loading.vue'
import Wildcat from '../Wildcat.json'
export default {
  components: {
    loading: loading
  },
  data () {
    return {
      netid: '',
      id: 0,
      cs: '',
      export_cs: '',
      ts: '',
      project: {
        title: 'Foo'
      },
      data: {
        loading: false,
        rows: [],
        columns: []
      },
      downloading: {
        'xlsx': false,
        'docx': false,
        'pdf': false
      },
      wasDemo: false
    }
  },
  mounted () {
    this.wasDemo = localStorage.getItem('prevWasWildcat')
    if (this.wasDemo === 'true') {
      this.$refs.backBtn.innerText = 'Back to Wildcat\'s Portfolio'
      this.netid = Wildcat.nonsponsored.NETID
      this.export_cs = Wildcat.nonsponsored.PROJECT_ID
      this.cs = '123-1234567-12345678'
    } else {
      this.$refs.backBtn.innerText = 'Back to ' + this.$route.params.netid + '\'s Portfolio'
      this.cs = this.$route.params.cs // @TODO: sanitize
      this.netid = this.$route.params.netid // @TODO: sanitize
    }
    this.load()
  },
  methods: {
    formatTitle (key) {
      var selection = 'NonSponsoredProjectHeader'
      return Dictionary.formatTitle(key, selection)
    },
    backToPortfolio () {
      var path = '/projects/' + (this.wasDemo === 'true' ? 'wildcat' : this.$route.params.netid) + '/non-sponsored'
      localStorage.setItem('prevWasWildcat', false)
      this.$router.push({path: path})
    },
    exportNonSponsoredProject (format) {
      var parts = this.wasDemo === 'true' ? this.export_cs.split('-') : this.cs.split('-')
      if (parts.length !== 3) {
        console.warn('Unable to start download! Missing project chart string.')
        return
      }
      var p_FundCode = parts[0]
      var p_DeptID = parts[1]
      var p_PrjID = parts[2]

      var opts = {
        report_name: 'era-rp-main',
        project_type: 'non-sponsored',
        p_FundCode: p_FundCode,
        p_DeptID: p_DeptID,
        p_PrjID: p_PrjID
      }
      var that = this
      this.downloading[format] = true
      DataExportService.get(opts).then(data => {
        that.downloading[format] = false
        // @TODO: CHeck browser support for this.
        if (typeof data !== 'string') {
          console.log('Error generating the download', data)
        } else if (typeof data === 'string' && data.match(/^https/)) {
          window.location.href = data
        } else {
          console.log('Invalid Download URL', data)
        }
        // Download(data);
        // trigger download
      }).catch(err => {
        console.log('Unable to download', err)
        that.downloading[format] = false
      })
    },
    formatLabel (key) {
      var selection = 'NonSponsoredProjectHeader'
      return Dictionary.formatLabel(key, selection)
    },
    isNegative (number) {
      return parseFloat(number) < 0
    },
    formatValue (key, row) {
      return Dictionary.formatValue(key, row)
    },
    load () {
      var timer = new Timer()
      this.data.loading = true
      var that = this
      var lut_opts = {
        path: 'etlstatus'
      }
      ReportService.get(lut_opts, this.$store).then((data) => {
        that.ts = new Date(data.rows[0].LastUpdateTime)
        that.ts = that.ts + ' (' + data.rows[0].BuildIndicatorDescription + ')'
      }).catch(err => {
        that.data.loading = false
        that.error = err
      })
      var parts = ('' + (this.wasDemo === 'true' ? this.export_cs : this.cs)).split('-')
      // Non Sponsored Project Header
      var report_opts = {
        report_name: 'era-rp-main',
        p_Service: 'RD004',
        p_PrjID: parts[2] || '<NONE>',
        p_FundCode: parts[0] || '<NONE>',
        p_DeptID: parts[1] || '<NONE>'
      }
      ReportService.get(report_opts, this.$store).then(data => {
        that.data.loading = false
        that.data.loading = false
        if ('error' in data) {
          that.data.error = data.error
        } else {
          that.data.columns = data.headers
          that.data.rows = data.rows.pop()
        }
        timer.tick('loaded in ')
        that.data.performance = timer.toString()
        // override data for wildcat demo user
        if (this.wasDemo === 'true') {
          that.data.rows['FYTDActualsAndEncExpense'] = 1500.42 + Math.round(Math.random() * 1000)
          that.data.rows['ProjectDescription'] = 'PI Wildcat Discretionary'
          this.cs = '123-1234567-12345678'
          that.data.rows['BudgetCarryforward'] = 1500.42 + Math.round(Math.random() * 1000)
          that.data.rows['RevenueLessActual'] = 1500.42 + Math.round(Math.random() * 1000)
          that.data.rows['NetBudget'] = 1500.42 + Math.round(Math.random() * 1000)
          that.data.rows['NetBalance'] = that.data.rows['RevenueLessActual'] - that.data.rows['NetBudget']
        }
      }).catch(err => {
        that.data.loading = false
        console.log('Unable to parse the results', err)
      })
    }
  }
}
</script>
<style lang="scss" />
