var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "actions" }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.hideTable,
            expression: "!hideTable",
          },
        ],
      },
      [
        _c("mytable", {
          staticClass: "payments",
          attrs: {
            title: "Payments",
            columns: _vm.data.columns,
            store: _vm.data.rows,
            labels: _vm.data.labels,
            loading: _vm.data.loading,
            performance: _vm.data.performance,
            error: _vm.data.error,
          },
        }),
        _c("mytable", {
          staticClass: "outstanding-invoices",
          attrs: {
            title: "Outstanding Invoices",
            columns: _vm.outstandingInvoicesData.columns,
            store: _vm.outstandingInvoicesData.rows,
            labels: _vm.outstandingInvoicesData.labels,
            loading: _vm.outstandingInvoicesData.loading,
            performance: _vm.outstandingInvoicesData.performance,
            error: _vm.outstandingInvoicesData.error,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hideTable,
            expression: "hideTable",
          },
        ],
      },
      [
        _c("costsharedetails", {
          staticClass: "cost-share-details",
          attrs: { title: "", back: { text: "Cost Share" } },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }