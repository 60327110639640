var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "actions" }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.hideTable,
            expression: "!hideTable",
          },
        ],
      },
      [
        _c("mytable", {
          staticClass: "cost-share",
          attrs: {
            title: "Cost Share Detail",
            columns: _vm.data.columns,
            dropdowndata: _vm.selectdata,
            store: _vm.data.rows,
            labels: _vm.data.labels,
            loading: _vm.data.loading,
            performance: _vm.data.performance,
            error: _vm.data.error,
          },
          on: { "update-selectdata": _vm.selectOpt },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hideTable,
            expression: "hideTable",
          },
        ],
      },
      [
        _c("costsharedetails", {
          staticClass: "cost-share-details",
          attrs: { title: "", back: { text: "Cost Share" } },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }