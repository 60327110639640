<template>
  <div class="usercard">
    <template v-if="loaded">
      <img class="user-profile-picture" v-bind:src="user.data.picture">
      <div class="usercard-info">
        <template v-if="error">
          <h1>NetID: {{ netid }}</h1>
          <p>{{ error }}</p>
        </template>
        <template v-if="user.data.displayName[0]">
          <div class="user-name-email">
            <h1 class="user-name"><span v-html=user.data.displayName[0]></span></h1>
            <p class="email">
              <a v-bind:href="`mailto:${user.data.mail}`">{{ user.data.mail }}</a>
              <small><a @click="togglePosition()">[more]</a></small>
            </p>
            <div v-if="showPosition" class="user-position">
              <transition name="expando">
                <span class="value" v-html="user.data.position"></span>
              </transition>
            </div>
            <br>
            <div v-if="fstatus =='true'">
              <p><strong><a @click="removeFavorite(netid)">Remove as favorite</a></strong></p>
            </div>
            <div v-else>
              <p><strong><a @click="addFavorite(netid)">Add as favorite</a></strong></p>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>

import UserService from '../services/UserService.js'
import FavoriteService from '../services/FavoriteService.js'

export default {
  props: {
    netid: {type: String, default: null}
  },
  data () {
    return {
      fstatus: '',
      loaded: false,
      showPosition: false,
      user: {
        data: {}
      },
      error: ''
    }
  },
  mounted () {
    var netid = this.netid.toLocaleLowerCase()
    var that = this

    that.fstatus = localStorage.getItem('favstatus')

    UserService.getProfile(netid).then((response) => {
      if ('errorMessage' in response) {
        that.error = 'Unable to find NetID attributes'
      }
      that.user.data = this.formatResponse(response)
      that.loaded = true
    }).catch((err) => {
      console.log(err)
    })
  },
  methods: {
    togglePosition () {
      this.showPosition = !this.showPosition
    },
    formatField (field, value) {
      switch (field) {
        case 'nuPosition1':
          return 'AAA' + value
        default:
          return value
      }
    },
    formatResponse (response) {
      // prepare image and fallback
      if (response.picture) {
        // use the img URL provided
      } else if (response.jpegPhoto) {
        response.picture = 'data:image/jpeg;base64,' + response.jpegPhoto
      } else {
        response.picture = 'https://km.solar-fuels.org/sites/sofi/files/styles/thumbnail/public/default_images/default_person_0.png'
      }
      // prepare position

      response.position = (response.nuPosition1 || '')
        .replace(/\$\$/g, '<br/>')
        .replace(/\$/g, ' ')

      return response
    },
    addFavorite (netID) {
      FavoriteService.set(netID).then(console.log('adding Fav: ', netID))
      this.fstatus = 'true'
      localStorage.setItem('favstatus', 'true')
    },
    removeFavorite (netID) {
      FavoriteService.delete(netID).then(console.log('removing Fav: ', netID))
      this.fstatus = 'false'
      localStorage.setItem('favstatus', 'false')
    }
  }
}
</script>
<style lang='scss'>
  .usercard {
    margin-bottom : 0px;
    .usercard-info{
      padding-top : 0px;
      margin-right : 10px;
    }
    .user-position {
      float: left;
      font-size: 0.9em;
    }
    min-height: 40px;
    small a {
      cursor: pointer;
    }
  }

  .expando-enter-active, .expando-leave-active {
    transition: all 1s ease;
    max-height: 200px;
    overflow: hidden;
    opacity: 1;
  }

  .expando-enter {
    max-height: 0;
    opacity: 0;
  }

  </style>
